.simplyTermsC {
   width: 100%;
   display: flex;
   align-items: center;
   gap: 12px;
   .leftInputTermsC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         cursor: pointer;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(41%) sepia(10%) saturate(528%) hue-rotate(190deg)
               brightness(99%) contrast(93%);
         }
      }
      .input-select {
         width: 100%;
      }
   }
   .rightInputTermsC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .input-select {
         width: 100%;
         > label {
            font-size: 20px;
            > div {
               height: 48px;
            }
         }
      }
      .img-icon {
         cursor: pointer;
         > img {
            filter: brightness(0) saturate(100%) invert(41%) sepia(10%) saturate(528%) hue-rotate(190deg)
               brightness(99%) contrast(93%);
         }
         &.delete {
            > img {
               filter: brightness(0) saturate(100%) invert(35%) sepia(61%) saturate(3754%) hue-rotate(331deg)
                  brightness(101%) contrast(90%);
            }
         }
      }
   }
}
