.flowEditorC {
   flex-shrink: 0;

   height: calc(88px + 252px);
   display: flex;
   flex-direction: column;
   .feTopC {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--primary-50, #e2ebf3);
      .feTopLC {
         display: flex;
         align-items: center;
         gap: 12px;
         flex: 1 0 0;
         .input-serach-flow {
            width: 480px;
         }
         .gearBtnImg {
            height: 32px;
            width: 32px;
         }
      }
      .feTopRC {
         display: flex;
         gap: 24px;
         .sosBtn {
            padding: 12px 48px;
         }
      }
   }
   .flowFetching {
      display: flex;
      gap: 12px;
      justify-content: center;
      align-items: center;
      height: 100%;
   }
   .noFlowSelected {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      > span {
         color: var(--neutral-500, #8e8f8f);
         text-align: center;
         font-size: 18px;
         font-style: normal;
         font-weight: 700;
      }
   }
}
.settingModalC {
   width: 480px;
   background-color: #fff;
   padding: 32px;
   border-radius: 24px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:first-child {
         display: flex;
         align-items: center;
         gap: 12px;
         > h1 {
            font-size: 20px;
            font-weight: 700;
            line-height: 128%;
         }
      }
   }
}
.previewC {
   height: min(100%, 800px);
}
.flowItemsC {
   display: flex;
   flex-grow: 1;
   padding: 8px 24px;
   overflow-x: auto;
   gap: 24px;
   background-color: #fff;
   &.legalDrop {
      background-color: var(--positive-100, #d0f5e1);
   }
}
.flowItemC {
   display: flex;
   flex-direction: column;
   padding: 6px 12px;
   gap: 12px;
   &.isUnitSet {
      background: var(--neutral-50, #f7f8f8);
      border-radius: 12px;
   }
   &.isDragging {
      opacity: 0.4;
   }
   .itemHandle {
      display: flex;
      gap: 8px;
      align-items: center;
      > img {
         height: 18px;
         cursor: pointer;
      }
      > span {
         font-size: 20px;
         font-weight: 700;
      }
      .itemHandleBtn {
         padding: 0;
         font-size: 14px;
      }
   }
}
.subItemSetC {
   display: flex;
   gap: 12px;
   &.legalDrop {
      background-color: var(--primary-100, #c2d5e5);
   }
}
.subItemC {
   display: flex;
   &.isDragging {
      opacity: 0.4;
   }
   > img {
      height: 18px;
      cursor: pointer;
   }
}
