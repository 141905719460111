.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .submissionC {
      width: 100%;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .infoC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         align-items: flex-start;
         gap: 24px;
         > div {
            width: 298px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            > div {
               > div {
                  width: 100%;
               }
            }
         }
      }
      .divider {
         height: 100%;
         border-left: 1px solid #c0c0c0;
         width: 100%;
      }
      .propertiesC {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;

         .no-properties {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
         }
      }
   }
   .directedC {
      width: 100%;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .infoC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         align-items: flex-start;
         gap: 24px;
         > div {
            width: 298px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
         }
      }
   }
}

.loading {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
}
