.contentC {
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
}
.toolBoxWrapper {
   position: absolute;
   bottom: 2rem;
   right: 2rem;
}

.top {
   background: var(--primary-50, #e2ebf3);
   padding: 8px 24px 0 24px;
   position: relative;
   .topFilter {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      align-items: center;
      .topLeft {
         max-width: 400px;
         .input_search {
            display: flex;
            align-items: center;
            gap: 12px;
         }
      }
      .topRight {
         display: flex;
         justify-content: flex-end;
         gap: 8px;
         align-items: center;
         .divider-container {
            display: flex;
            align-items: center;
            height: 100%;
            .divider {
               border: none;
               border-left: 1px solid #c0c0c0;
               height: 48px;
               transform: rotate(180deg);
               margin: 0;
            }
         }
         .quickPickBtn {
            min-width: 205px;
            > button {
               width: 100%;
            }
         }
         .btnFilter {
            min-width: 180px;
            > button {
               width: 100%;
               display: flex;
               justify-content: center;
               align-items: center;
               gap: 4px;
               > img {
                  filter: brightness(0) saturate(100%) invert(22%) sepia(7%) saturate(249%) hue-rotate(155deg)
                     brightness(91%) contrast(80%);
               }
            }
         }
      }
   }
   .topTab {
      height: 65px;
      display: flex;
      align-items: flex-end;
      padding-top: 20px;
      gap: 24px;
      .tabs {
         flex-grow: 1;
      }
      .tabsResponsive {
         display: none;
      }
      .addnewPropertyBtn {
         display: flex;
         gap: 12px;
         padding-bottom: 13px;
         .btn {
            display: flex;
            height: 40px;
            padding: 0px 32px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            &.see {
               color: var(--info-500, #295bff);
               border: 1px solid var(--info-500, #295bff);
            }
            &.add {
               background-color: white;
               color: var(--primary-500, #1a2d3d);
            }
         }
      }
   }
}

.divider-container {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 30px;
      transform: rotate(180deg);
      margin: 0;
   }
}

.filterList {
   display: flex;
   gap: 16px;
   align-items: center;
   display: none;
}

.filterListResponsive {
   display: none;
}

.filter-dropdown {
   width: 125px;
   outline: transparent !important;
   > div {
      background-color: transparent !important;
      gap: 4px;
   }
}

.view {
   display: flex;
   align-items: center;
   gap: 4px;
   .img {
      width: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(14%) sepia(15%) saturate(1661%) hue-rotate(165deg) brightness(98%)
            contrast(93%);
      }
   }
}

.body {
   display: flex;
   align-items: flex-start;
   align-content: flex-start;
   gap: 24px;
   flex-grow: 1;
   flex-wrap: wrap;
   overflow: auto;
   .spinner {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

@media screen and (max-width: 845px) {
   .top {
      .topFilter {
         padding-bottom: 8px;
      }
      .topTab {
         display: flex;
         padding: 8px 0 16px 0;
         justify-content: space-between;
         align-items: center;
         .tabs {
            display: none;
         }
         .tabsResponsive {
            min-width: 227px;
            display: block;
         }
         .addnewPropertyBtn {
            padding: 0;
         }
      }
   }
   .filterList {
      display: none;
   }
   .filterListResponsive {
      // display: flex;
      gap: 16px;
      align-items: center;
      padding-top: 6px;
      .divider-container {
         display: block;
      }
   }
   .divider-container {
      display: none;
   }
}
