.threadCardC {
   width: 100%;
   display: flex;
   padding: 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 16px;
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
   position: relative;
   &.bgWhite {
      background: white;
   }
   .personC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .avt {
         width: 40px;
         height: 40px;
         border: none;
         > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
         }
      }
      .nameC {
         flex-grow: 1;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 2px;
         font-size: 14px;
         .role {
            color: var(--neutral-600, #68696a);
         }
      }
      .time {
         color: var(--neutral-600, #68696a);
         font-size: 14px;
      }
      .btn {
         color: var(--info-500, #295bff);
         text-align: center;
         font-size: 14px;
         font-weight: 700;
         width: 100px;
      }
      .img-icon {
         cursor: pointer;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
      .dropdownC {
         position: absolute;
         right: 2rem;
         top: 0;
         border-radius: 12px;
         display: flex;
         flex-direction: column;
         gap: 8px;
         display: none;
         background-color: white;
         transition: opacity 0.3s ease, transform 0.3s ease;
         &.dropdownVisible {
            display: block;
         }
         .dropdown-menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 5px 10px;
            .dropdown-item {
               display: flex;
               gap: 4px;
               font-size: 16px;
               align-items: center;
               justify-content: flex-start;
               padding: 8px;
               background-color: white;
               .loading {
                  width: 100%;
                  display: flex;
                  justify-content: center;
               }
               > span {
                  font-weight: normal;
               }
               > img {
                  filter: brightness(0) saturate(100%) invert(42%) sepia(9%) saturate(58%) hue-rotate(168deg)
                     brightness(94%) contrast(90%);
               }
               &:hover {
                  background-color: #e6e6e6;
               }
               &.delete {
                  width: 100%;
                  > span {
                     color: #f2415a;
                  }
                  > img {
                     filter: brightness(0) saturate(100%) invert(39%) sepia(85%) saturate(2870%) hue-rotate(327deg)
                        brightness(96%) contrast(97%);
                  }
                  &:hover {
                     background-color: #f2415a;
                     > span {
                        color: white;
                     }
                     > img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(13%) hue-rotate(191deg)
                           brightness(105%) contrast(100%);
                     }
                  }
               }
            }
         }
      }
   }
   .contentC {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      cursor: pointer;
      .title {
         font-size: 18px;
         font-weight: 700;
      }
      .keywordC {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 4px;
         .img-icon {
            width: 20px;
            height: 20px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg)
                  brightness(87%) contrast(90%);
            }
         }
         .keywordGroupC {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 4px;
            .keyword {
               display: flex;
               padding: 2px 6px;
               justify-content: center;
               align-items: center;
               gap: 4px;
               border-radius: 4px;
               background: var(--neutral-100, #e6e6e6);
               font-size: 14px;
            }
         }
      }
   }
   .commentC {
      display: flex;
      align-items: center;
      gap: 12px;
      .numberComs {
         color: var(--neutral-600, #68696a);
         font-size: 14px;
      }
      .dot {
         width: 6px;
         height: 6px;
         border-radius: 50%;
         background-color: var(--neutral-500, #8e8f8f);
      }
      .postCom {
         color: var(--info-500, #295bff);
         font-size: 14px;
         cursor: pointer;
      }
   }
}
