.contentC {
   display: flex;
   flex-direction: column;
   gap: 8px;
   padding-left: 1px;
   padding-right: 1px;
   width: 100%;
}
.select {
   box-sizing: border-box;
   outline: 1px solid var(--neutral-500, #8e8f8f);
   border-radius: 12px;

   .control {
      border-radius: 12px;
      border: none;
      background: var(--base-white, #fff);
      padding: 12px;
      * {
         padding: 0;
         margin: 0;
      }
      &:hover {
         box-shadow: none;
      }
   }

   .valueContainer {
      padding: 0;
      height: 24px;
      cursor: pointer;
      display: flex;
      gap: 8px;
      overflow: visible;
   }
   .input {
      height: 24px;
      margin: 0;
      padding: 0;
   }
   // .dropdownIndicator {
   // }
   .indicatorsContainer {
      // height: 24px;
      > div {
         height: 24px;
         padding: 0;
         > svg {
            height: 24px;
            width: 24px;
         }
      }
   }
   .loadingIndicator {
      display: flex;
      align-items: center;
      gap: 4px;
   }
   .menu {
      border-radius: 12px;
      // overflow: hidden;
      background-color: white;
      > div {
         > div {
            cursor: pointer;
         }
      }
   }
   .menuList {
      padding: 0;
      max-height: 160px;
      background-color: white;
      .customOption {
         cursor: pointer;
         -webkit-user-select: none;
         -moz-user-select: none;
         -ms-user-select: none;
         user-select: none;
         padding: 8px 12px;
         font-weight: 700;
         &:hover {
            background-color: #d6e0ff;
         }
      }
   }
}
.title {
   font-size: 20px;
   font-weight: 700;
}
.error {
   color: red;
   margin-top: 10px;
}

.style-question-editor {
   flex-shrink: 0;
   .select {
      outline: none;
      .control {
         min-height: 0;
         padding: 0px 12px;
         height: 32px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         border-radius: 6px;
      }
   }
}

.style-datapoint {
   .select {
      outline: none;
      .control {
         border-radius: 8px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         padding: 0 6px;
      }
   }
}

.style-primary {
   .select {
      outline: none;
      .control {
         height: 40px;
         padding: 0px 16px 0px 20px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         border-radius: 40px;
         background: var(--primary-500, #1a2d3d);
         box-shadow: none;
         div {
            color: white;
            font-weight: 700;
            svg {
               filter: brightness(0) saturate(100%) invert(85%) sepia(99%) saturate(0%) hue-rotate(26deg)
                  brightness(108%) contrast(100%);
            }
         }
      }
   }
}
