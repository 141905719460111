.tabsContainer {
   display: flex;
   align-items: center;
   background: var(--neutral-50, #f7f8f8);
   border-radius: 80px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   overflow-x: auto;
   overflow-y: hidden;
   &.padding {
      gap: 12px;
      padding: 0 20px 0px 0px;
   }
}

.tab {
   padding: 5px 20px;
   border-radius: 20px;
   cursor: pointer;
   color: #6e6e6e;
   transition: background-color 0.3s, color 0.3s;
   &.primary {
      color: var(--primary-500, #1a2d3d);
      text-align: center;
      font-size: 16px;
   }
   &.active {
      background: var(--primary-500, #1a2d3d);
      color: white;
      font-weight: bold;
   }
}

.img-icon {
   cursor: pointer;
   width: 24px;
   height: 24px;
   > img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(44%) sepia(6%) saturate(103%) hue-rotate(169deg) brightness(87%)
         contrast(82%);
   }
}
