.contentC {
   background-color: #fff;
   padding: 32px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   overflow-y: auto;
   height: 100%;
   min-width: 560px;
   position: absolute;
   top: 0;
   right: 0;
}

.topC {
   display: flex;
   align-items: center;
   gap: 16px;
   width: 100%;
   justify-content: space-between;
   .topLeft {
      display: flex;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(24%) sepia(3%) saturate(419%) hue-rotate(156deg) brightness(99%)
               contrast(89%);
         }
      }
      .headerC {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 4px;
         > span {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
         }
         > p {
            color: var(--neutral-700, #404557);
            text-align: justify;
         }
      }
   }
}

.reasonsC {
   height: 100%;
   overflow-y: auto;
   flex-grow: 1;
   display: flex;
   padding: 16px 0px;
   flex-direction: column;
   gap: 8px;
   > label {
      background: var(--Primary-White, #fff);
   }
   .otherReasonC {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      > textarea {
         width: 100%;
         min-height: 200px;
         padding: 16px;
         display: flex;
         align-items: center;
         border-radius: 12px;
         border: 1px solid var(--neutral-300, #bfc1c7);
         background: var(--Primary-White, #fff);
         resize: none;
      }
   }
}

.btn {
   height: 56px;
   border-radius: 12px;
   font-size: 18px;
   width: 100%;
   &.reject {
      border-radius: 8px;
      background: var(--negative-100, #fcd9de);
      color: var(--negative-500, #f2415a);
   }
}

.loading {
   display: flex;
   gap: 8px;
   align-items: center;
   justify-content: center;
}
.wrapperC{
   > ul {
       display: flex;
       flex-direction: column;
       list-style: disc inside;
      gap: 4px;
      > li {
         > span {
            font-weight: 700;
         }
      }
   }
}