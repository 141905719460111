.container {
   width: 80%;
   height: 80%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   overflow: auto;
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
}
