.mediaC {
   height: 320px;
   max-width: calc(100% - 520px - 16px);
   display: flex;
   flex: 1 0;
   align-items: flex-start;
   background-color: white;
   position: relative;
}
.imageSizeCover {
   width: 480px;
   height: 320px;
   > img {
      background-size: cover;
      width: 100%;
      height: 100%;
   }
}

.addPhotosC {
   position: absolute;
   bottom: 8px;
   right: 8px;
   transform: translateX(-5%);
   .btnAdd {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: var(--base-white, #fff);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      .img-icon {
         width: 16px;
         height: 16px;
         > img {
            width: 16px;
            height: 16px;
            filter: brightness(0) saturate(100%) invert(13%) sepia(12%) saturate(2173%) hue-rotate(166deg)
               brightness(98%) contrast(91%);
         }
      }
      > span {
         text-align: center;
         font-size: 14px;
         font-weight: 700;
      }
   }
}
