.style-text {
   display: flex;
   flex-direction: column;
   gap: 8px;
   width: 100%;
   > div {
      border: 1px solid var(--neutral-500, #8c8f9a);
      background: var(--base-white, #fff);
      width: 100%;
      border-radius: 12px;
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      &.error {
         border-color: var(--error-500, #ff4d4f);
      }
      > input {
         height: 100%;
         flex-grow: 1;
      }
      > div {
         height: 24px;
         display: flex;
         align-items: center;
         justify-content: center;
         > img {
            height: 32px;
            width: 32px;
         }
      }
   }
   > span.error {
      color: var(--error-500, #ff4d4f);
      font-size: 12px;
      font-weight: 500;
   }
}
