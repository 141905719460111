.wrapper {
   width: 100%;
   background-color: white;
   display: flex;
   flex-direction: column;
   padding: 8px;
   border-top: 1px solid #d4d5d5;
   position: relative;
   &.selected {
      background-color: var(--primary-50, #e2ebf3);
      border-radius: 12px;
   }
   &:hover {
      border-radius: 12px;
      background-color: var(--primary-50, #e2ebf3);
      cursor: pointer;
   }
   .top {
      width: 100%;
      display: flex;
      position: relative;
      .topLeft {
         display: flex;
         gap: 32px;
         flex-grow: 1;
         .zipC {
            display: flex;
            flex-direction: column;
            gap: 4px;
         }
         .locationC {
            display: flex;
            flex-direction: column;
            gap: 4px;
         }
         .tittle {
            font-size: 20px;
            font-weight: 700;
         }
      }
      .img-icon {
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
      .dropdownC {
         position: absolute;
         right: 2rem;
         top: 0;
         // transform: translateX(100%);
         border: 1px solid red;
         border-radius: 12px;
         display: flex;
         flex-direction: column;
         gap: 8px;
         display: none;
         // padding: 5px 12px;
         transition: opacity 0.3s ease, transform 0.3s ease;
         &.dropdownVisible {
            display: block;
            // transform: translateY(0);
         }
         .dropdown-menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .dropdown-item {
               width: 210px;
               display: flex;
               gap: 4px;
               font-size: 16px;
               align-items: center;
               justify-content: flex-start;
               padding: 8px;
               background-color: white;
               border-radius: 12px;
               border: 1px solid #d4d5d5;
               .loading {
                  width: 100%;
                  display: flex;
                  justify-content: center;
               }
               > span {
                  color: #ec2220;
                  font-weight: normal;
               }
               > img {
                  filter: brightness(0) saturate(100%) invert(15%) sepia(97%) saturate(4073%) hue-rotate(355deg)
                     brightness(99%) contrast(87%);
               }
               &:hover {
                  background-color: #ec2220;
                  > span {
                     color: white;
                  }
                  > img {
                     filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(13%) hue-rotate(191deg)
                        brightness(105%) contrast(100%);
                  }
               }
            }
         }
      }
   }
   .bot {
      width: 100px;
      text-align: center;
      background-color: #68696a;
      border-radius: 5px;
      color: white;
      font-weight: 700;
   }
}
