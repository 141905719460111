.container {
   display: flex;
   flex-direction: column;
   height: 100%;
   border-left: 1px solid #d6d7d7;
   .topC {
      background-color: var(--base-white, #fff);
      display: flex;
      height: calc(100% - 88px - 252px);
   }
}
