.contentC {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   align-items: center;
   .hidden {
      display: none;
   }
}

.testTable {
   height: 100%;
   width: 100%;
}
.header {
   display: flex;
   width: 320px;
   height: 60px;
   padding: 0px 12px;
   align-items: center;
   gap: 12px;
   font-size: 18px;
   font-weight: 700;
   border-right: 1px solid #bbb;
}
