.sourcesC {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   &.borderC {
      border: 1px solid var(--neutral-500, #8e8f8f);
      border-radius: 12px;
      padding: 12px;
   }
   .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .checkboxAndSourceC {
         width: 100%;
         display: flex;
         gap: 24px;
         > label {
            width: 0;
            background-color: transparent;
            padding: 0;
         }
         .sourceC {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: flex-start;
            gap: 12px;
            .property-img {
               width: 128px;
               height: 128px;
               > img {
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
                  background: var(--neutral-100, #e6e6e6);
               }
            }
            .inforC {
               flex-grow: 1;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               padding: 0 5px;
               .addressC {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 8px;
                  .address {
                     display: flex;
                     align-items: center;
                     gap: 8px;
                     flex-grow: 1;
                     > span {
                        display: inline-block;
                        max-width: 300px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: bottom;
                     }
                     .img-icon {
                        display: flex;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        > img {
                           width: 100%;
                           height: 100%;
                           filter: brightness(0) saturate(100%) invert(25%) sepia(7%) saturate(200%) hue-rotate(155deg)
                              brightness(93%) contrast(88%);
                        }
                     }
                  }
               }
               .numbersC {
                  display: flex;
                  align-items: flex-start;
                  gap: 12px;
                  width: 100%;
                  justify-content: space-between;
                  .price {
                     display: flex;
                     flex-direction: column;
                     justify-content: center;
                     align-items: flex-start;
                     gap: 2px;
                     > span {
                        color: var(--neutral-700, #424445);
                        font-size: 14px;
                     }
                     .number-price {
                        font-size: 24px;
                        font-weight: 700;
                     }
                  }
               }
               .unitInforC {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  gap: 4px;
                  align-items: flex-end;
                  .unit {
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     .icon {
                        display: flex;
                        align-items: flex-end;
                        width: 20px;
                        height: 20px;
                        > img {
                           width: 100%;
                           height: 100%;
                           filter: brightness(0) saturate(100%) invert(25%) sepia(7%) saturate(200%) hue-rotate(155deg)
                              brightness(93%) contrast(88%);
                        }
                     }
                  }
               }
            }
         }
      }
      .contactListC {
         width: 100%;
         padding: 8px 12px;
         justify-content: space-between;
         align-items: center;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
         .contactC {
            width: 100%;
            display: flex;
            padding: 8px 12px;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
            .contactInfo {
               display: flex;
               align-items: center;
               gap: 4px;
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(25%) sepia(6%) saturate(242%) hue-rotate(156deg)
                        brightness(93%) contrast(87%);
                  }
               }
            }
         }
         .noContact {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--neutral-500, #8e8f8f);
         }
      }
      > label {
         width: 0;
         background-color: transparent;
      }
      .input_search {
         display: flex;
         align-items: center;
         gap: 12px;
         &.disabled {
            > label {
               > div {
                  background-color: var(--neutral-50, #f7f8f8);
               }
            }
         }
      }
   }
}
