.housingAuthorityC {
   width: 100%;
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   border-radius: 12px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   background: var(--base-white, #fff);
   .infoC {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      align-items: flex-start;
      gap: 12px;
      .dataC {
         width: 380px;
         display: flex;
         padding: 8px;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         > div {
            min-width: 150px;
         }
         &.bg {
            height: 188px;
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
         }
         a {
            color: var(--info-500, #295bff);
            text-decoration: none;
            font-size: 14px;
            font-weight: 700;
            &:hover {
               text-decoration: underline;
            }
         }
      }
      .divider-container {
         display: flex;
         align-items: center;
         height: 100%;
         .divider {
            border: none;
            border-left: 1px solid #c0c0c0;
            height: 190px;
            transform: rotate(180deg);
            margin: 0;
         }
      }
   }
   .tableExpensesC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      .resetBtn {
         display: flex;
         width: 170px;
         height: 32px;
         padding: 0px 16px 0px 12px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         font-size: 14px;
         color: var(--info-500, #295bff);
         .img-icon {
            width: 18px;
            height: 18px;
            img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(35%) sepia(93%) saturate(4700%) hue-rotate(223deg)
                  brightness(99%) contrast(105%);
            }
         }
      }
   }
}
.tableContainer {
   width: 100%;
   border-radius: 12px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   overflow: hidden;
}
.unitContainer {
   width: 100%;
   display: flex;
   padding: 12px;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   padding-top: 24px;
   border-top: 1px solid var(--neutral-300, #c0c1c1);
   .optionC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;
      .divider-container {
         display: flex;
         align-items: center;
         height: 100%;
         .divider {
            border: none;
            border-left: 1px solid #c0c0c0;
            height: 50px;
            transform: rotate(180deg);
            margin: 0;
         }
      }
      .valuesC {
         width: 200px;
         height: 50px;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 4px;
         > div {
            width: 100%;
         }
      }
      .unitDeduction {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 4px;
      }
   }
   .utilityC {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      .nameDocC {
         display: flex;
         height: 32px;
         align-items: center;
         gap: 8px;
         flex: 1 0 0;
         > span {
            overflow: hidden;
            color: var(--info-500, #295bff);
            text-overflow: ellipsis;
         }
      }
      .btn {
         display: flex;
         height: 40px;
         padding: 0px 32px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: var(--info-500, #295bff);
         text-align: center;
         font-size: 16px;
         font-weight: 700;
         border: 1px solid var(--info-500, #295bff);
      }
   }
}
.divider {
   height: 100%;
   border-left: 1px solid #c0c0c0;
   width: 100%;
}

.btnExpenseC {
   display: flex;
   align-items: center;
   gap: 8px;
}
