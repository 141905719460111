.container {
   display: flex;
   background: var(--base-white, #fff);
   height: 100%;
   width: 100%;
   .bg-left {
      width: 34%;
      height: 100%;
      > img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .bodyC {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      position: relative;
      .logoC {
         position: absolute;
         left: 50%;
         top: 64px;
         transform: translateX(-50%);
         display: flex;
         justify-content: center;
      }
      .body {
         flex-grow: 1;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         gap: 32px;
         line-height: 1;
         .bodyTop {
            width: 40%;
            > img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
         .bodyMid {
            display: flex;
            flex-direction: column;
            gap: 16px;
            text-align: center;
            > b {
               font-size: 56px;
            }
            > p {
               font-size: 18px;
               color: var(--neutral-600, #666a79);
            }
         }
         .bodyBot {
            > button {
               padding-left: 48px;
               padding-right: 48px;
            }
         }
      }
   }
   .oldrightC {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
      gap: 300px;
      .top {
         display: flex;
         align-items: center;
         gap: 12px;
         > span {
            font-size: 30px;
            font-weight: 700;
         }
      }
      .center {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         gap: 50px;
         .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
         }
         .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            text-align: center;
            .title {
               > span {
                  color: var(--primary-500, #1a2d3d);
                  font-size: 72px;
                  font-weight: 700;
               }
            }
            .paraphrap {
               > span {
                  color: var(--neutral-600, #666a79);
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 400;
               }
            }
            .btn-bottom {
               padding: 10px 20px;
            }
         }
      }
   }
}
