.container {
   width: 800px;
   height: 90%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .contentC {
      width: 100%;
      height: 84%;
      overflow-y: auto;
      display: flex;
      padding: 2px 32px 64px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .addMorePropertiesC {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 4px;
         > span {
            font-size: 18px;
            font-weight: 700;
         }
      }
      .input_search {
         display: flex;
         width: 100%;
         align-items: center;
         > label {
            gap: 0;
            > div {
               > input {
                  font-size: 16px;
               }
            }
         }
      }
      .sourceInfoC {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         align-items: flex-start;
         gap: 16px;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
         .sourceTypeC {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 24px;
         }
         .contactC {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .contactInfo {
               display: flex;
               align-items: center;
               gap: 4px;
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(25%) sepia(6%) saturate(242%) hue-rotate(156deg)
                        brightness(93%) contrast(87%);
                  }
               }
            }
         }
         .loading {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
         }
         .noContact {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--neutral-500, #8e8f8f);
         }
      }
      .propertiesC {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
         .loading {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            > span {
               margin-left: 10px;
            }
         }
      }
   }
   .btnSave {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 0 32px 32px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 24px 24px;
      background: rgba(255, 255, 255, 0.64);
      .btn {
         display: flex;
         align-items: center;
         gap: 8px;
         padding: 12px 48px;
         .img-icon {
            width: 18px;
            height: 18px;
            > img {
               width: 100%;
               height: 100%;
            }
         }
      }
   }
}
