.container {
   width: 100%;
}

.uploadFileBtn {
   width: 100%;
   border-radius: 8px;
   height: 40px;
   padding: 0px 32px;
   color: var(--info-500, #295bff);
   border-radius: 8px;
   background: var(--info-100, #d6e0ff) !important;
}

.uploadImgBtn {
   display: flex;
   width: 56px;
   height: 56px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   background: var(--neutral-100, #e6e6e6) !important;
   .img-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(23%) sepia(4%) saturate(347%) hue-rotate(155deg) brightness(97%)
            contrast(87%);
      }
   }
}

.linkC {
   display: flex;
   flex-direction: column;
   gap: 14px;
   margin-top: 14px;
   .linkUploadC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
         flex: 1 0 0;
      }
      .renameInput {
         max-width: 280px;
         flex-grow: 1;
      }
      .img-icon {
         cursor: pointer;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg) brightness(92%)
               contrast(96%);
         }
      }
      .linkIconsC {
         display: flex;
         align-items: center;
         gap: 4px;
         .img-icon {
            width: 20px;
            height: 20px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                  brightness(92%) contrast(96%);
            }
            &.clear {
               cursor: pointer;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(3368%) hue-rotate(328deg)
                     brightness(97%) contrast(95%);
               }
            }

            &.disabled {
               cursor: not-allowed;
            }

            > img.loading {
               opacity: 0.5;
            }
         }
      }
   }
}

.linkC-image {
   display: flex;
   justify-content: flex-end;
   flex-direction: row;
   .uploading-image {
      display: flex;
      position: relative;
      .images {
         opacity: 0.2;
         width: 56px;
         height: 56px;
         > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
         }
      }
      .spinner {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }
}
