.loading {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   span {
      margin-left: 10px;
   }
}

.tableC {
   width: 100%;
}

.footer {
   width: 100%;
   bottom: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   .load-more {
      cursor: pointer;
   }

   .load-more.hidden {
      display: none;
   }

   &.hidden {
      display: none;
   }
}
