.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .infoC {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 48px;
      > div {
         width: 250px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 8px;
      }
   }
}
