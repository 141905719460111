.container {
   width: 500px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   border-radius: 24px;
   background: var(--base-white, #fff);
   padding: 20px;
   .headingC {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
         font-size: 18px;
         font-weight: 600;
         color: var(--base-dark-grey, #333);
      }
      .closeBtn {
         cursor: pointer;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(10%) sepia(68%) saturate(542%) hue-rotate(166deg)
                  brightness(96%) contrast(87%);
            }
         }
      }
   }
   .modalBtns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      > button {
         width: 100px;
         height: 40px;
         cursor: pointer;
      }
   }
}
