.container {
   width: 100%;
   padding: 0 24px;
}
.status {
   padding: 2px 6px;
   border-radius: 4px;
   text-align: center;
   background: var(--neutral-50, #f7f8f8);
   &.colorSuccess {
      background: var(--positive-100, #d0f5e1);
   }
   &.colorProcess {
      background: var(--info-100, #d6e0ff);
   }
   &.colorProblem {
      background: var(--negative-100, #fcd9de);
   }
   &.colorCancel {
      background: var(--neutral-100, #e6e6e6);
   }
   &.colorPending {
      background: #f9d6ff;
   }
   &.colorPriority {
      background: var(--warning-200, #ffe59e);
   }
}
