.container {
   display: flex;
   width: 644px;
   flex-direction: column;
   align-items: flex-start;
   flex-shrink: 0;
   border-radius: 16px;
   background: var(--base-white, #fff);
   .personal-infor {
      width: 100%;
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      > h3 {
         text-align: center;
      }
      .avt {
         display: flex;
         width: 96px;
         height: 96px;
         justify-content: center;
         align-items: center;
         border-radius: 100px;
         //  background: var(--neutral-50, #f7f8f8);
         background-color: black;
         > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100px;
         }
      }
      .input {
         width: 100%;
         > label {
            width: 100%;
         }
      }
   }
   .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 24px;
      gap: 12px;
      font-size: 16px;
      > button {
         font-size: 16px;
         width: 176px;
      }
      > div {
         > button {
            font-size: 16px;
            width: 176px;
         }
      }
   }
}
