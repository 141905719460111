.loading {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 20px;
   span {
      margin-left: 10px;
   }
}
.input {
   width: 160px;
}
