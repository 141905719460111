.loadingC {
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 16px;
}
.containerC {
   height: 100%;
   width: 100%;
   overflow: hidden;
   position: relative;
   .mapC {
      height: inherit;
      width: inherit;
   }
   .searchC {
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      transition: transform 0.4s ease;
      transition-delay: 0.4s;
      width: 340px;

      height: 100%;
      .inputC {
         position: relative;
         transform: translateX(calc(-100% - 16px - 16px)); // 100% + padding + padding
         transition: transform 0.4s ease;
         transition-delay: 0.4s;
         margin: 16px 16px 0 16px;
      }
      > ul {
         padding: 16px;

         display: flex;
         flex-direction: column;
         gap: 32px;
         overflow-y: auto;
         height: 100%;
      }
      &.open {
         transform: translateX(0);
         .inputC {
            transform: translateX(0);
         }
      }
   }
}
.mapPropC {
   width: 12px;
   height: 12px;
   background-color: var(--Primary-Sun, #ffe39b);
   border-radius: 50%;
   outline: 3px solid var(--primary-500, #1a2d3d);
   position: relative;
   .mapPropMini {
      position: absolute;
      bottom: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      width: 52px;
      height: 59px;
      > img:first-child {
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
      > img:last-child {
         height: calc(52px - 10px);
         width: calc(52px - 10px);
         position: absolute;
         top: 5px;
         left: 50%;
         transform: translateX(-50%);
         border-radius: 8px;
      }
   }
   .mapPropInfo {
      z-index: 1;
      position: absolute;
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      padding: 12px;
      border-radius: 12px;
      display: flex;
      gap: 8px;
      min-width: 304px;
      font-size: 11px;
      font-weight: 400;
      align-items: center;
      .mapPropInfoL {
         height: 100%;
         width: 96px;
         > img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      .mapPropInfoR {
         display: flex;
         flex-direction: column;
         gap: 4px;
         > div:first-child {
            display: flex;
            gap: 4px;
            > img {
               height: 14px;
               width: 14px;
            }
            > button {
               > img {
                  height: 14px;
                  width: 14px;
               }
            }
         }
         > ul {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
            > li {
               display: flex;
               gap: 2px;
               align-items: center;
               > p {
                  width: 86px;
               }
            }
         }
         > div:last-child {
            display: flex;
            gap: 8px;
            align-items: center;
            > div {
               display: flex;
               align-items: center;
               gap: 4px;
            }
         }
      }
   }
}
.searchPropC {
   padding: 12px;
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
   display: flex;
   flex-direction: column;
   font-size: 12px;
   font-weight: 400;
   gap: 12px;
   &.selected {
      outline: 2px solid var(--info-500, #295bff);
   }
   .searchPropTop {
      display: flex;
      gap: 4px;
      align-items: center;
      > img {
         height: 16px;
         width: 16px;
      }
      > span {
         flex-grow: 1;
      }
      > button {
         > img {
            height: 16px;
            width: 16px;
         }
      }
   }
   .searchPropMid {
      display: flex;
      > div {
         flex-grow: 1;
         gap: 12px;
         > b {
            margin-top: 2px;
            font-size: 18px;
            font-weight: 700;
         }
      }
   }
   .searchPropBot {
      display: flex;
      gap: 8px;
      > div {
         display: flex;
         gap: 4px;
         align-items: center;
         > img {
            height: 16px;
            width: 16px;
         }
      }
   }
}
