.container {
   width: 520px;
   flex-shrink: 0;
   display: flex;
   flex-direction: column;
   gap: 12px;
   height: 100%;
   > ul {
      padding: 12px 12px 0 12px;
   }
   .conversationC {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding: 12px 12px 100px 12px;
      .btnNotifyAndSlack {
         display: flex;
         align-items: flex-start;
         gap: 12px;
         width: 100%;
         .btn {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            .img-icon {
               display: flex;
               align-items: center;
            }
            &.white {
               .img-icon {
                  img {
                     filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(224deg)
                        brightness(102%) contrast(105%);
                  }
               }
            }
            &.blue {
               border: 1px solid var(--info-500, #295bff);
               background: transparent;
               color: var(--info-500, #295bff);
               .img-icon {
                  img {
                     filter: brightness(0) saturate(100%) invert(21%) sepia(74%) saturate(2300%) hue-rotate(219deg)
                        brightness(123%) contrast(108%);
                  }
               }
            }
         }
      }
      .input_search {
         display: flex;
         width: 100%;
         align-items: center;
         gap: 12px;
         > label {
            > div {
               font-size: 16px;
            }
         }
      }
      .filterConversationC {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 8px;
         .filterC {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            .filterIconC {
               display: flex;
               align-items: center;
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg)
                        brightness(87%) contrast(90%);
                  }
               }
               .input-search {
                  display: flex;
                  align-items: center;
                  gap: 12px;
               }
            }
            .divider-container {
               display: flex;
               align-items: center;
               height: 100%;
               .divider {
                  border: none;
                  border-left: 1px solid #c0c0c0;
                  height: 30px;
                  transform: rotate(180deg);
                  margin: 0;
               }
            }
         }
      }
      .tagsC {
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         gap: 8px;
      }
      .threadsC {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 12px;
         height: 100%;
         overflow-y: auto;
         position: relative;
         .spinnerC {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            flex-direction: column;
            justify-content: center;
         }
         .noThread {
            text-align: center;
            width: 100%;
            color: var(--neutral-500, #8e8f8f);
         }
      }
   }
   .leadThreadC {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding: 12px;
      .input_search {
         display: flex;
         width: 100%;
         align-items: center;
         gap: 12px;
         > label {
            > div {
               font-size: 16px;
            }
         }
      }
      .filterConversationC {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 8px;
         .filterC {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            .filterIconC {
               display: flex;
               align-items: center;
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg)
                        brightness(87%) contrast(90%);
                  }
               }
               .input-search {
                  display: flex;
                  align-items: center;
                  gap: 12px;
               }
            }

            .divider-container {
               display: flex;
               align-items: center;
               height: 100%;
               .divider {
                  border: none;
                  border-left: 1px solid #c0c0c0;
                  height: 30px;
                  transform: rotate(180deg);
                  margin: 0;
               }
            }
         }
      }
      .tagsC {
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         gap: 8px;
      }
      .leadsC {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 24px;
         .leadC {
            background: var(--neutral-50, #f7f8f8);
            border-radius: 12px;
            padding: 8px 12px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .top {
               cursor: pointer;
               display: flex;
               align-items: flex-start;
               gap: 12px;
               .expandIcon {
                  width: 24px;
                  height: 24px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(28%) sepia(6%) saturate(193%) hue-rotate(155deg)
                        brightness(91%) contrast(95%);
                  }
               }
               .centerC {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  .leadNamesC {
                     display: flex;
                     flex-direction: column;
                     gap: 4px;
                     > span {
                        font-size: 18px;
                        font-weight: 700;
                     }
                  }
                  .leadOfferStatusC {
                     display: flex;
                     gap: 12px;
                     .statusC {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .status {
                           padding: 4px 8px;
                           background-color: red;
                           border-radius: 5px;
                        }
                     }
                     .divider-container {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        .divider {
                           border: none;
                           border-left: 1px solid #c0c0c0;
                           height: 30px;
                           transform: rotate(180deg);
                           margin: 0;
                        }
                     }
                  }
               }
               .count {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background: var(--neutral-200, #d4d5d5);
                  color: var(--neutral-700, #424445);
                  font-weight: 700;
                  font-size: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
               }
            }
            .bottom {
               display: flex;
               flex-direction: column;
               gap: 12px;
               position: relative;
               .btnNotifyAndSlack {
                  display: flex;
                  align-items: flex-start;
                  gap: 12px;
                  width: 100%;
                  .btn {
                     width: 100%;
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     .img-icon {
                        display: flex;
                        align-items: center;
                     }
                     &.white {
                        .img-icon {
                           img {
                              filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                                 hue-rotate(224deg) brightness(102%) contrast(105%);
                           }
                        }
                     }
                     &.blue {
                        border: 1px solid var(--info-500, #295bff);
                        background: transparent;
                        color: var(--info-500, #295bff);
                        .img-icon {
                           img {
                              filter: brightness(0) saturate(100%) invert(21%) sepia(74%) saturate(2300%)
                                 hue-rotate(219deg) brightness(123%) contrast(108%);
                           }
                        }
                     }
                  }
               }
               .threadsC {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                  height: 100%;
                  overflow-y: auto;
                  .spinnerC {
                     position: absolute;
                     left: 50%;
                     top: 120%;
                     transform: translateX(-50%);
                     width: 100%;
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     flex-direction: column;
                     justify-content: center;
                  }
                  .noThread {
                     text-align: center;
                     width: 100%;
                     color: var(--neutral-500, #8e8f8f);
                  }
               }
            }
         }
      }
   }
}

.divider-container {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 30px;
      transform: rotate(180deg);
      margin: 0;
   }
}

.filterTag {
   display: flex;
   padding: 2px 6px;
   justify-content: center;
   align-items: center;
   gap: 4px;
   border-radius: 4px;
   background: var(--neutral-100, #e6e6e6);
   > span {
      color: var(--primary-500, #1a2d3d);
      font-size: 14px;
   }
   .img-icon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14px;
      height: 14px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(161%) hue-rotate(155deg) brightness(97%)
            contrast(96%);
      }
   }
}
.dealC {
   width: 520px;
   height: 100%;
   overflow-y: auto;
   .dealDetailC {
      width: 100%;
      // height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding: 12px;
      .btnHubspot {
         display: flex;
         align-items: flex-start;
         gap: 12px;
         width: 100%;
         .btn {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            .img-icon {
               display: flex;
               align-items: center;
            }
            &.white {
               .img-icon {
                  img {
                     filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(224deg)
                        brightness(102%) contrast(105%);
                  }
               }
            }
            &.blue {
               border: 1px solid var(--info-500, #295bff);
               background: transparent;
               color: var(--info-500, #295bff);
               .img-icon {
                  img {
                     filter: brightness(0) saturate(100%) invert(21%) sepia(74%) saturate(2300%) hue-rotate(219deg)
                        brightness(123%) contrast(108%);
                  }
               }
            }
         }
      }
      .dealInfor {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         width: 100%;
      }
   }
}

.noDeal {
   width: 100%;
   text-align: center;
}
.loading {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
}

.dealPipeline {
   width: 100%;
   // height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.dealLink {
   width: 100%;
   // height: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   .address {
      color: var(--info-500, #295bff);
      font-size: 18px;
      padding-right: 15px;
   }
   .statusC {
      display: flex;
      align-items: center;
      gap: 4px;
      .icon {
         width: 8px;
         height: 8px;
         border-radius: 50%;
         &.active {
            background-color: var(--positive-500, #27be69);
         }
      }
   }
   .img-icon {
      width: 20px;
      height: 20px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg) brightness(87%)
            contrast(90%);
      }
   }
}
.otherC {
   display: flex;
   flex-direction: column;
   gap: 12px;
   padding: 24px 12px 12px;
   border-top: 1px solid var(--neutral-200, #d4d5d5);
   height: 100%;
   width: 100%;
   > span {
      font-size: 18px;
   }
   .dealOtherLst {
      display: flex;

      flex-direction: column;
      gap: 12px;
      .dealOther {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         width: 100%;
         border-radius: 8px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         padding: 12px;
      }
   }
}

.aboutDeal {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   width: 100%;
   .detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      width: 100%;
      max-height: 0px;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.5s ease, opacity 0.5s ease;
      &.open {
         opacity: 1;
         max-height: 300px;
      }
      .propertyC {
         width: 100%;
         overflow-x: auto;
         display: flex;
         gap: 12px;
         align-items: center;
         max-height: 0px;
         opacity: 0;
         overflow: hidden;
         transition: max-height 0.5s ease, opacity 0.5s ease;
         &.open {
            opacity: 1;
            max-height: 110px;
         }
         .property {
            display: flex;
            min-width: 320px;
            max-width: 320px;
            padding: 12px;
            align-items: flex-start;
            gap: 12px;
            border-radius: 8px;
            border: 1px solid var(--neutral-500, #8e8f8f);
            .propertyImg {
               width: 80px;
               height: 80px;
               // background-color: #8e8f8f;
               > img {
                  height: 100%;
                  width: 100%;
                  border-radius: 8px;
               }
            }
            .propertyDetail {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               flex: 1 0 0;
               .propertyLocation {
                  display: flex;
                  gap: 8px;
                  width: 100%;
                  .location {
                     font-size: 14px;
                  }
                  .img-icon {
                     min-width: 20px;
                     height: 20px;
                     > img {
                        width: 100%;
                        height: 100%;
                        filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(161%) hue-rotate(155deg)
                           brightness(97%) contrast(96%);
                     }
                     .locationImg {
                        filter: brightness(0) saturate(100%) invert(37%) sepia(46%) saturate(6034%) hue-rotate(333deg)
                           brightness(107%) contrast(90%);
                     }
                  }
               }
               .propertyOffer {
                  display: flex;
                  align-items: flex-start;
                  gap: 2px;
                  width: 100%;
                  .offer {
                     font-size: 14px;
                     flex: 1 0 0;
                     color: var(--neutral-700, #424445);
                  }
                  .price {
                     flex: 1 0 0;
                     font-size: 18px;
                     font-weight: 700;
                  }
               }
            }
         }
      }
   }
}
.contactC {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 12px;
   max-height: 0px;
   opacity: 0;
   overflow: hidden;
   transition: max-height 0.5s ease, opacity 0.5s ease;
   &.open {
      opacity: 1;
      max-height: 300px;
   }
   .contact {
      display: flex;
      gap: 12px;
      .avt {
         display: flex;
         width: 48px;
         height: 48px;
         padding: 8px;
         align-items: center;
         border-radius: 48px;
         background-color: #1a2d3d;
      }
      .aboutContact {
         width: 100%;
         display: flex;
         align-items: flex-start;
         flex: 1 0 0;
         gap: 4px;
         flex-direction: column;
         .contactInfor {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            .name {
               flex: 1 0;
               color: var(--info-500, #295bff);
            }
            .emailC {
               display: flex;
               align-items: flex-start;
               justify-content: center;
               gap: 4px;
               flex: 1 0 0;
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(161%) hue-rotate(155deg)
                        brightness(97%) contrast(96%);
                  }
               }
               .email {
                  color: var(--info-500, #295bff);
               }
            }
         }
         .position {
            color: var(--neutral-600, #68696a);
         }
      }
   }
}

.infor {
   display: flex;
   gap: 8px;
   align-items: center;
   .img-icon {
      width: 20px;
      height: 20px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg) brightness(87%)
            contrast(90%);
      }
   }
}
.dealTitle {
   color: var(--neutral-600, #68696a);
}
.deal {
   min-width: 90px;
   font-weight: 700;
   > div {
      > div {
         outline: none;
         > div {
            padding: 0 !important;
         }
      }
   }
}
.contact {
   display: flex;
   gap: 12px;
}
hr {
   width: 100%;
   height: 1px;
   background: var(--neutral-400, #aeafb0);
}
