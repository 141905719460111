.slidesC {
   height: 100%;
   width: 100%;
   -webkit-user-select: none;
   user-select: none;
   position: relative;
   .slides {
      height: 100%;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      gap: 8px;
      &::-webkit-scrollbar {
         display: none; /* Hide scrollbar in WebKit browsers */
      }
      &.source-property-card {
         gap: 80px;
      }
      > div {
         display: flex;
         gap: 8px;
         > div:first-child {
            flex-shrink: 0;
            width: 480px;
            height: 100%;
            > img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
      }
   }
}
.nav-prev,
.nav-next {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   width: 48px;
   height: 48px;
   background-color: #fff;
   border-radius: 50%;
}
.nav-prev {
   left: 8px;
}
.nav-next {
   right: 8px;
}

.slidePropertyCardC {
   display: flex;
}
.mapC {
   height: 100%;
   width: 480px;
}
.dividerContainer {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 204px;
      transform: rotate(180deg);
      margin: 0;
   }
}
