.questionCardC {
   -webkit-user-select: none;
   user-select: none;


   width: 432px;
   display: flex;
   padding: 16px;
   flex-direction: column;
   align-items: start;
   gap: 12px;
   border-radius: 12px;
   outline: 1px solid var(--neutral-500, #8e8f8f);
   background: var(--base-white, #fff);
   &.selected {
      outline: 3px solid var(--info-500, #295bff);

      background: #ebefff;
   }
   .top-card {
      display: flex;
      align-items: center;
      gap: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      span {
         font-size: 16px;
      }
   }
   .tittle {
      height: 56px;
      font-size: 20px;
      font-weight: 700;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }
   .bottom-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 12px;
      .trigger-left {
         display: flex;
         align-items: center;
         gap: 8px;
         height: 32px;
         > div {
            width: 24px;
            height: 24px;
            background-color: var(--warning-100, #fff3d1);
            > img {
               width: 24px;
               height: 24px;
               filter: brightness(0) saturate(100%) invert(76%) sepia(33%) saturate(1417%) hue-rotate(349deg)
                  brightness(103%) contrast(101%);
            }
         }
         > span {
            color: var(--neutral-600, #68696a);
            font-size: 16px;
         }
      }
      .deleteBtn {
         padding: 0;
         >img {
            height: 24px;
            width: 24px;

         }
      }
   }
}
