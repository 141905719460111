.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .insurance {
      display: flex;
      width: 100%;
      padding: 12px;
      gap: 12px;
      border-radius: 12px;
      background: var(--neutral-50, #f7f8f8);
      align-self: stretch;
      .img-icon {
         cursor: pointer;
         display: flex;
         align-items: center;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(50%) sepia(67%) saturate(6933%) hue-rotate(332deg)
               brightness(106%) contrast(90%);
         }
      }
      .insuranceStatus {
         width: 300px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         gap: 12px;
      }
      .documentC {
         display: flex;
         flex-direction: column;
         gap: 8px;
         flex: 1 0 0;
         border-radius: 8px;
         width: 400px;
         .uploadBtn {
            border-radius: 8px;
            height: 32px;
            background: var(--info-100, #d6e0ff);
            color: var(--info-500, #295bff);
         }
         .uploadFileBtn {
            width: 100%;
            border-radius: 8px;
            height: 40px;
            padding: 0px 32px;
            color: var(--info-500, #295bff);
            border-radius: 8px;
            background: var(--info-100, #d6e0ff) !important;
         }
      }
   }

   .itemC {
      display: flex;
      flex-wrap: wrap;
      min-height: 100%;
      width: 100%;
      padding: 12px;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      .itemDetail {
         width: 290px;
         &.isBackground {
            border-radius: 8px;
            background: var(--primary-50, #e2ebf3);
            padding: 8px;
         }
      }
   }
}

.btnAdd {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.noInfo {
   padding: 12px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--neutral-500, #8e8f8f);
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
}
