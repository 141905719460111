.firstCellC {
   display: flex;
   gap: 8px;
   align-items: center;
   > button {
      height: 24px;
      // filter: ;
      filter: brightness(0) saturate(100%) invert(39%) sepia(79%) saturate(2370%) hue-rotate(326deg) brightness(94%)
      contrast(102%);
   }
}

.cellC {
   width: 100%;
   display: flex;
   align-items: center;
   gap: 4px;
   font-size: 20px;
   display: flex;
   gap: 6px;
   .strike {
      text-decoration: line-through;
      white-space: nowrap;
   }
   .monthly {
      flex-grow: 1;
      font-size: 20px;
      &.overrideMon {
         font-size: 18px;
         color: var(--neutral-600, #68696a);
         text-decoration: line-through;
         > div {
            > div {
               > span {
                  color: var(--neutral-600, #68696a);
               }
            }
         }
      }
   }
   .monthlyOverride {
      width: 150px;
      font-size: 20px;
   }
}
