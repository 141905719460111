.container {
   width: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   gap: 24px;
   flex-wrap: wrap;
   border-top: 1px solid #aeafb0;
   padding-top: 12px;
   .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      .addInspectionC {
         display: flex;
         align-items: center;
         gap: 24px;
         > span {
            font-size: 18px;
            font-weight: 700;
         }
         .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #295bff;
            font-size: 14px;
            .img-icon {
               display: flex;
               align-items: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                     brightness(101%) contrast(102%);
               }
            }
         }
      }
      .inspectionC {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
         border-radius: 12px;
         background: var(--base-white, #fff);
         .inspection {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 24px;
            > div {
               width: 270px;
            }
            .otherReasonC {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               > textarea {
                  width: 100%;
                  min-height: 200px;
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  border-radius: 12px;
                  border: 1px solid var(--neutral-300, #bfc1c7);
                  background: var(--Primary-White, #fff);
                  resize: none;
               }
            }
         }
         .icon-link {
            display: flex;
            gap: 8px;
            > a {
               &:hover {
                  > div {
                     > div {
                        &:last-child {
                           text-decoration: underline;
                        }
                     }
                  }
               }
            }
         }
      }
      .noInspectionC {
         width: 100%;
         flex-grow: 1;
         display: flex;
         padding: 16px;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 8px;
         border-radius: 12px;
         background: var(--base-white, #fff);
         > span {
            color: var(--neutral-500, #8e8f8f);
         }
      }
   }

   .leadDocSC {
      display: flex;
      gap: 24px;
      width: 940px;
      .docsC {
         width: 100%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 24px;
         border-left: 1px solid #aeafb0;
         padding-left: 24px;
         .docC {
            display: flex;
            width: 100%;
            height: 272px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            > span {
               font-size: 18px;
               font-weight: 700;
            }
            .uploadBtn {
               width: 100%;
               border-radius: 8px;
               color: #295bff;
            }
            .filesC {
               width: 100%;
               display: flex;
               flex-direction: column;
               overflow-y: auto;
               .linkUploadC {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 12px;
                  background-color: white;
                  .img-icon {
                     display: flex;
                     align-items: center;
                     img {
                        filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                           brightness(92%) contrast(96%);
                     }
                  }
                  .linkIconsC {
                     display: flex;
                     align-items: center;
                     gap: 4px;
                     .img-icon {
                        display: flex;
                        align-items: center;
                        img {
                           filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                              brightness(92%) contrast(96%);
                        }
                     }
                  }
                  > span {
                     max-width: 310px;
                  }
               }
            }
         }
      }
   }
}

.inspectionCostC {
   display: flex;
   height: 68px;
   padding: 8px;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 4px;
   border-radius: 8px;
   background: var(--positive-100, #d0f5e1);
}
.spinnerC {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
}
