.container {
   cursor: pointer;
   .documentC {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px;
      width: 100%;
      .inputUploadC {
         width: 100%;
         > input {
            width: 100%;
            position: absolute;
            opacity: 0;
         }
      }
   }
}
.uploadBtnC {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 8px;
   border-radius: 8px;
   color: #000;
   font-size: 14px;
   font-weight: 500;
   cursor: pointer;
   transition: all 0.3s;
   &.isLoading {
      opacity: 0.7;
   }
   .spinner {
      position: absolute;
      margin-right: 8px;
   }
}
