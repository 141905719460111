.container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   border-top: 1px solid #aeafb0;
   width: 100%;
   .addOfferC {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      > span {
         font-size: 18px;
         font-weight: 700;
      }
      .btn {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: #295bff;
         font-size: 14px;
         .img-icon {
            display: flex;
            align-items: center;
            width: 16px;
            height: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                  brightness(101%) contrast(102%);
            }
         }
      }
   }
   .offerCardsC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .spinnerC {
         display: flex;
         flex-direction: column;
         gap: 8px;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 100%;
      }
      .noOffer {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 50px;
         color: var(--neutral-500, #8c8f9a);
         font-size: 20px;
         border-radius: 12px;
         background: var(--base-white, #fff);
      }
   }
}
