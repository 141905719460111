.contentC {
   background-color: #fff;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 24px;
   padding: 24px;
   padding-top: 12px;
   min-width: 600px;
}
.formC {
   flex: 1;
   overflow-y: auto;
}
.baseForm {
   display: flex;
   flex-direction: column;
   gap: 24px;
}
.optionsForm {
   display: flex;
   flex-direction: column;
   gap: 24px;
   > ul {
      display: flex;
      flex-direction: column;
      gap: 24px;
      > li {
         .optionHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > b {
               font-size: 20px;
               font-weight: 700;
            }
         }
         display: flex;
         flex-direction: column;
         gap: 12px;
         padding-bottom: 24px;
         border-bottom: 1px solid var(--neutral-500, #8e8f8f);
      }
   }
}
.fuForm,
.rejForm {
   > ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--neutral-500, #8e8f8f);
      margin-bottom: 24px;
   }
}

.conditionC {
   display: flex;
   gap: 12px;
   align-items: flex-start;
   > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 12px;
      border-radius: 12px;
      border: 1px solid var(--neutral-500, #8e8f8f);
      > ul:first-child {
         flex-grow: 1;
         display: flex;
         flex-direction: column;

         gap: 12px;
         > li {
            display: flex;
            gap: 8px;
            align-items: center;
            .fuOperator {
               text-transform: capitalize;
               text-align: right;
               width: 60px;
               flex-shrink: 0;
            }
         }
      }
      .divider {
         width: 100%;
         border-bottom: 1px solid var(--neutral-300, #c0c1c1);
      }
      > ul:last-child {
         display: flex;
         flex-direction: column;
         gap: 12px;
         > li {
            width: 100%;
            display: flex;
            gap: 8px;
            align-items: center;
            > span {
               width: 60px;
               text-align: right;
            }
         }
      }
   }
}

.cond_OP {
   width: 60px;
}
.cond_Q {
   width: 180px;
}
.cond_O {
   width: 50px;
}
.cond_addQ {
   flex-grow: 1;
}
.cond_TitleInput {
   flex-grow: 1;
   > div {
      padding: 4px 12px;
      height: 32px;
      border: 1px solid var(--neutral-500, #8e8f8f);
      border-radius: 6px;
      > input {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
}
.addButton {
   display: flex;
   gap: 8px;
   align-items: center;
   width: -moz-fit-content;
   width: fit-content;
   > img {
      filter: brightness(0) saturate(100%) invert(28%) sepia(44%) saturate(3807%) hue-rotate(221deg) brightness(101%)
         contrast(113%);
      height: 16px;
      width: 16px;
   }
   > span {
      font-size: 14px;
      font-weight: 700;
      line-height: 128%;
      color: var(--info-500, #295bff);
   }
}

.xButton {
   display: flex;
   gap: 8px;
   align-items: center;
   width: -moz-fit-content;
   width: fit-content;
   > img {
      height: 16px;
      width: 16px;
   }
}
