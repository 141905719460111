.contentC {
   display: flex;
   width: 315px;
   height: 68px;
   padding: 8px;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 4px;
   border-radius: 8px;
   background-color: white;

   .titleC {
      display: flex;
      align-items: center;
      padding-left: 8px;
      gap: 4px;
   }
   .valueC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      padding: 3px 8px;
      .value {
         width: 100%;
      }
   }
}
.isMocked {
   background-color: rgba(255, 0, 0, 0.1);
}

.type-bold-title {
   .titleC {
      font-weight: 700;
   }
}
.type-bold-value {
   .valueC {
      font-size: 20px;
      font-weight: 700;
   }
}
.type-only-value {
   .titleC {
      display: none;
   }
}

.img-icon {
   width: 20px;
   height: 20px;
   > img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(184%) hue-rotate(155deg) brightness(87%)
         contrast(89%);
   }
}
