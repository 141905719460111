.addressC {
   display: flex;
   align-items: center;
   gap: 4px;
   flex: 1 0 0;
   > span {
      font-size: 18px;
      font-weight: 700;
   }
}

.img-icon {
   width: 24px;
   height: 24px;
   cursor: pointer;
   > img {
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
      filter: brightness(0) saturate(100%) invert(14%) sepia(15%) saturate(1743%) hue-rotate(165deg) brightness(95%)
         contrast(92%);
   }
}

.zillow-btn {
   display: flex;
   align-items: center;
   gap: 8px;
   padding: 2px 12px;
}
.zillow-icon {
   width: 24px;
   height: 24px;
   cursor: pointer;
}
