.contentC {
   display: flex;
   height: 100%;
   .imgC {
      height: 100%;
      width: 30%;
      background: var(--Primary-Sun, #ffe39b);
      position: relative;
      overflow: hidden;
      > img {
         position: relative;
         height: 100%;
         width: 100%;
         object-fit: cover;
      }
      > div {
         background: var(--primary-500, #1a2d3d);
         position: absolute;
         top: 100%;
         left: 80px;
         transform: translateY(-50%);
         width: 1000px;
         height: 1000px;
         border-radius: 50%;
      }
   }
   .loginC {
      margin-top: 60px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .top {
         display: flex;
         align-items: center;
         gap: 12px;
         > span {
            font-size: 30px;
            font-weight: 700;
         }
      }
      .center {
         max-width: 443px;
         text-align: center;
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 32px;
         > h1 {
            font-size: 40px;
         }
         > p {
            color: var(--neutral-600, #666a79);
            font-size: 20px;
         }
      }
   }
}
