html {
   height: calc(100vh - 1px);
   width: calc(100vw);
}
#root {
   height: 100%;
}
body {
   height: 100%;
   width: 100%;
   overflow: hidden;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   line-height: 140%;
   font-family: "GT Walsheim";
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
}
a {
   text-decoration: none;
}

.col-center {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width: 1750px) {
   html {
      transform: scale(0.8);
      transform-origin: 0 0;
      height: 125%;
      width: 125%;
   }
}
@media (max-width: 1400px) {
   html {
      transform: scale(0.6);
      height: 166.7%;
      width: 166.7%;
   }
}
@media (max-width: 750px) {
   html {
      transform: scale(1);
      height: 100%;
      width: 100%;
   }
}
