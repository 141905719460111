.container {
   background-color: #fff;
   padding: 32px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   width: 100%;
   position: relative;
   .topC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
}

.tableTitleC {
   overflow-y: auto;
   width: 100%;
   max-height: calc(100% - 400px);
   display: flex;
   flex-direction: column;
   gap: 8px;
   .title {
      font-weight: 700;
   }
   .tableC {
      border-radius: 12px;
      border: 1px solid var(--neutral-500, #8e8f8f);
      > div {
         > div {
            display: block;
         }
      }
   }
}

.loading {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   gap: 16px;
}

.footer {
   position: absolute;
   width: 90%;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   .load-more {
      cursor: pointer;
   }
   .load-more.hidden {
      display: none;
   }

   &.hidden {
      display: none;
   }
}

.fieldInfoC {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 16px;
   .inforC {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      .title {
         font-weight: 700;
      }
      .value {
         font-size: 20px;
         font-weight: 700;
      }
      .formulaC {
         width: 100%;
         display: flex;
         padding: 12px;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         border-radius: 12px;
         background: var(--neutral-50, #f7f8f8);
      }
   }
}
.divider {
   width: 100%;
   border: none;
   border-left: 1px solid #c0c0c0;
}
