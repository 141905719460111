.settingsC {
   width: 100%;
   display: flex;
   height: 50px;
   padding: 0px 24px;
   align-items: center;
   justify-content: space-between;
   gap: 10px;
   border-bottom: 1px solid var(--neutral-400, #aeafb0);
   .settingC {
      display: flex;
      align-items: center;
      gap: 4px;
      .img-icon {
         width: 20px;
         height: 20px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(45%) sepia(10%) saturate(624%) hue-rotate(190deg)
               brightness(87%) contrast(82%);
         }
      }
      .title {
         font-size: 18px;
         font-weight: 700;
      }
   }
   .btn-edit {
      color: #295bff;
      background-color: #fff;
      font-size: 14px;
   }
}
