.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
}
.containerDetailC {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   width: 100%;
   .addressDetailC {
      width: 100%;
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      border-radius: 8px;
      flex-wrap: wrap;
      background: var(--neutral-50, #f7f8f8);
   }
   .assetDetailC {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 16px;
      gap: 48px;
      > div {
         min-width: 200px;
         > div {
            width: 100%;
            > div {
               width: 100%;
            }
         }
      }
   }
   .characteristicsDetailC {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      padding: 16px;
      > div {
         width: 150px;
         > div {
            display: flex;
            gap: 8px;
            width: 100%;
            > div {
               width: 100%;
            }
         }
      }
   }
   .featureDetailC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;
      padding: 16px;
      > div {
         min-width: 190px;
      }
   }
}
.heading-title {
   font-size: 18px;
   font-weight: 700;
}

.divide-hr {
   width: 100%;
   background: var(--neutral-300, #c0c1c1);
}
