.container {
   width: 100%;
   display: flex;
   padding: 16px 24px;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   background: var(--base-white, #fff);
   border-top: 1px solid var(--neutral-400, #aeafb0);
   cursor: pointer;
   &:hover {
      background: var(--info-50, #ebefff);
   }
   &.selected {
      background: var(--info-50, #ebefff);
   }
   .nameC {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      .img-icon {
         cursor: pointer;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(42%) sepia(96%) saturate(3519%) hue-rotate(328deg)
               brightness(97%) contrast(95%);
         }
      }
   }
   .propertiesCountC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      > span {
         width: 100%;
      }
   }
   .statusesC {
      width: 100%;
      display: flex;
      gap: 12px;
      .statusC {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 2px;
         .title {
            padding: 0.2rem 0.5rem;
            width: fit-content;
            border-radius: 1rem;
         }
         > h1 {
            font-size: 18px;
            font-weight: 700;
         }
      }
   }
   .timeC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
   }
}

.title {
   font-size: 18px;
   font-weight: 700;
}
