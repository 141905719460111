.toolbox {
   max-height: 50px;
   max-width: 50px;
   padding: 0 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 10px;
   border-radius: 36px;
   opacity: 0.5;
   overflow: hidden;
   transition: 0.4s ease all;
   background: var(--primary-500, #1a2d3d);
   > button {
      border-radius: 27px;
      transition: 0.4s ease all;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      min-width: 50px;
      display: flex;
      gap: 8px;
      .icon {
         height: 20px;
         width: 20px;
         > img {
            filter: brightness(0) saturate(100%) invert(76%) sepia(6%) saturate(48%) hue-rotate(169deg) brightness(91%)
               contrast(92%);
            height: 100%;
            width: 100%;
         }
      }
      > span {
         color: var(--bgc-1);
         font-size: 12px;
         font-weight: 700;
         // display: none;
      }
      &:hover {
         background-color: var(--bgc-1);
         > img {
            filter: var(--text-1-filter);
         }
         > span {
            color: var(--text-1);
         }
      }
   }
   &:hover {
      opacity: 1;
      max-height: 70vh;
      max-width: 70vw;
      background: var(--primary-500, #1a2d3d);
      padding: 10px;
      > button {
         width: 100%;
      }
      > button:first-child {
         height: 50px;
         width: 50px;
         background-color: white;
         .icon {
            width: 20px;
            height: 20px;
            > img {
               height: 100%;
               width: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(8%) saturate(162%) hue-rotate(155deg)
                  brightness(94%) contrast(91%);
            }
         }
      }
      > button:not(:first-child):hover {
         justify-content: flex-start;
         background: white;
         .icon {
            width: 20px;
            height: 20px;
            > img {
               height: 100%;
               width: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(8%) saturate(162%) hue-rotate(155deg)
                  brightness(94%) contrast(91%);
            }
         }
         > span {
            display: inline;
            color: var(--primary-500, #1a2d3d);
         }
      }
   }
}
