.container {
   display: flex;
   padding: 32px;
   flex-direction: column;
   align-items: flex-start;
   gap: 32px;
   background: var(--Primary-White, #fff);
   box-shadow: -8px 0px 16px 0px rgba(0, 0, 0, 0.12);
   .heading {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
         flex-grow: 1;
         font-size: 20px;
         font-weight: 700;
      }
      .img-icon {
         cursor: pointer;
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
      .btn {
         display: flex;
         height: 40px;
         padding: 0px 32px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         &.reset {
            color: var(--negative-500, #f2415a);
            .img-icon {
               width: 18px;
               height: 18px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(35%) sepia(39%) saturate(4140%) hue-rotate(330deg)
                     brightness(99%) contrast(91%);
               }
            }
         }
      }
   }
   .bodyC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 48px;
      .leftC {
         flex-grow: 1;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         border-radius: 12px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         .topTitleC {
            width: 100%;
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            border-radius: 12px 12px 0 0;
            background: var(--neutral-50, #f7f8f8);
         }
         .title {
            width: 100%;
            display: flex;
            padding: 12px;
            align-items: center;
            gap: 16px;
            background: var(--base-white, #fff);
            > span {
               flex: 1;
               &:last-child {
                  max-width: 160px;
               }
            }
         }
         .wrapper {
            width: 100%;
            .titleLine {
               width: 100%;
               display: flex;
               padding: 12px;
               align-items: center;
               gap: 16px;
               background: var(--neutral-50, #f7f8f8);
               font-weight: 700;
            }
            .value {
               width: 100%;
               display: flex;
               padding: 12px;
               align-items: center;
               gap: 16px;
               background: var(--base-white, #fff);
               > span {
                  flex: 1;
                  &:last-child {
                     max-width: 160px;
                  }
               }
               &.borderR {
                  border-radius: 0 0 12px 12px;
               }
            }
         }
      }
      .rightC {
         display: flex;
         width: 336px;
         height: 100%;
         padding: 12px;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
         border-radius: 12px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         .dataTotalC {
            width: 312px;
            > div {
               width: 100%;
               display: flex;
               padding: 8px;
               flex-direction: column;
               align-items: flex-start;
               gap: 4px;
               border-radius: 8px;
               background: var(--neutral-50, #f7f8f8);
            }
         }
         .tenantResponsibleC {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .header {
               display: flex;
               gap: 8px;
               // flex-direction: column;
               justify-content: flex-end;
               align-items: flex-end;
               font-weight: 700;
            }
            .dataCheckboxC {
               display: flex;
               border-bottom: 1px solid var(--neutral-200, #d4d5d5);
               > div {
                  width: 312px;
                  display: flex;
                  padding: 8px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
               }
               > label {
                  justify-content: center;
                  background-color: transparent;
               }
            }
         }
      }
   }
}
