.additionalTermsC {
   width: 100%;
   display: flex;
   align-items: flex-start;
   gap: 12px;
   > textarea {
      width: 100%;
      display: flex;
      height: 80px;
      padding: 12px;
      align-items: flex-start;
      gap: 12px;
      border-radius: 12px;
      border: 1px solid var(--neutral-500, #8c8f9a);
      background: var(--base-white, #fff);
      resize: none;
   }
   .img-icon {
      cursor: pointer;
      > img {
         filter: brightness(0) saturate(100%) invert(41%) sepia(10%) saturate(528%) hue-rotate(190deg) brightness(99%)
            contrast(93%);
      }
      &.delete {
         > img {
            filter: brightness(0) saturate(100%) invert(35%) sepia(61%) saturate(3754%) hue-rotate(331deg)
               brightness(101%) contrast(90%);
         }
      }
   }
}
.img-icon {
   cursor: pointer;
   width: 24px;
   height: 24px;
   > img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(41%) sepia(10%) saturate(528%) hue-rotate(190deg) brightness(99%)
         contrast(93%);
   }
}
