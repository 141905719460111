// Upload stage
.container {
   position: relative;
   width: 100%;
   height: 100%;
   .inner {
      width: 1440px;
      height: 810px;
      border-radius: 24px;
      border: 2px solid var(--neutral-100, #e6e6e6);
      background: var(--base-white, #fff);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: flex-start;
      .drag-and-drop-container {
         flex: 1;
         padding: 48px;
      }

      .drag-and-drop-titles {
         margin-bottom: 24px;
         h1 {
            font-size: 40px;
            font-weight: 700;
            line-height: 140%;
            margin-bottom: 12px;
         }
         span {
            display: block;
            line-height: 140%;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 8px;
         }
      }

      .drag-and-drop-file {
         margin-bottom: 24px;

         .file-uploader {
            width: 100%;
            height: 100%;

            .drag-and-drop-file-inner {
               width: 100%;
               height: 100%;
               display: flex;
               padding: 24px;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               gap: 24px;
               align-self: stretch;
               border-radius: 16px;
               border: 3px dashed var(--neutral-600, #68696a);
               background: var(--base-white, #fff);

               .drag-and-drop-icon {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
               }

               img {
                  width: 80px;
                  height: 80px;
                  filter: brightness(0) saturate(100%) invert(34%) sepia(86%) saturate(5894%) hue-rotate(226deg)
                     brightness(105%) contrast(101%);
               }

               span {
                  font-size: 20px;
               }
            }
         }
      }

      .drag-and-drop-file-name {
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 12px;
         flex: 1 0 0;
         align-self: stretch;
         height: 134px;
         width: 100%;
         border-radius: 12px;
         margin-bottom: 24px;

         span {
            display: block;
            font-size: 20px;
         }

         img {
            filter: brightness(0) saturate(100%) invert(32%) sepia(40%) saturate(3286%) hue-rotate(329deg)
               brightness(104%) contrast(90%);
         }
      }

      .drag-and-drop-file-status {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 12px;
         flex: 1 0 0;
         align-self: stretch;
         height: 134px;
         width: 100%;
         border-radius: 12px;
         background: var(--negative-50, #feecee);
         margin-bottom: 24px;
         padding: 48px;

         span {
            font-size: 20px;
            color: var(--negative-500, #f2415a);
         }

         img {
            filter: brightness(0) saturate(100%) invert(32%) sepia(40%) saturate(3286%) hue-rotate(329deg)
               brightness(104%) contrast(90%);
         }
      }

      .reading-file-button {
         width: 100%;
         height: 56px;
      }

      .bg-right {
         flex: 1;

         > img {
            background-color: var(--positive-50);
            width: 100%;
            height: 100%;
            border-radius: 0 24px 24px 0;
         }
      }
   }
}

// preview stage
.preview-stage-container {
   width: 100%;
   height: 100%;
}

.control-bar {
   padding: 12px 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: var(--primary-50, #e2ebf3);

   .actions {
      display: flex;
      align-items: center;
      gap: 16px;

      button {
         padding: 8px 32px;
         font-size: 16px;
      }
   }
}
.csv-notificate-container {
   position: absolute;
   top: calc(50% - 80px);
   left: calc(50% - 240px);
   .success-img {
      width: 96px;
      height: 96px;
      filter: brightness(0) saturate(100%) invert(63%) sepia(55%) saturate(674%) hue-rotate(93deg) brightness(89%)
         contrast(76%);
   }
   .error-img {
      width: 96px;
      height: 96px;
      filter: brightness(0) saturate(100%) invert(42%) sepia(54%) saturate(4529%) hue-rotate(327deg) brightness(96%)
         contrast(98%);
   }
}
