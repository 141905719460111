.barchart {
   position: relative;
   width: 100%;
   height: 82px;
   display: flex;
   .barchart-col {
      background-color: var(--primary-500);
      position: absolute;
      bottom: 0px;
   }
}

.dropdown-title {
   user-select: none;
   cursor: pointer;
   display: flex;
}

.drowdown-title-active {
   font-weight: bold;
}

.range-slider {
   position: relative;
   width: 100%;
}
.priceBtn{
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.range-slider__thumb {
   -webkit-appearance: none;
   appearance: none;
   position: absolute;
   pointer-events: none;
   width: 100%;
   height: 8px;
   background: transparent;
}

.range-slider__thumb--left {
   z-index: 2;
}

.range-slider__thumb--right {
   z-index: 3;
}

.range-slider__track {
   position: absolute;
   width: 100%;
   height: 8px;
   background: var(--primary-100);
   border-radius: 4px;
   pointer-events: none;
}

.range-slider__range {
   position: absolute;
   height: 8px;
   background: var(--primary-500);
   border-radius: 4px;
   pointer-events: none;
}

input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   appearance: none;
   width: 24px;
   height: 24px;
   background: #fff;
   border: 1.6px solid var(--neutral-500, #8e8f8f);
   border-radius: 50%;
   cursor: pointer;
   pointer-events: all;
}

input[type="range"]::-moz-range-thumb {
   width: 24px;
   height: 24px;
   background: #fff;
   border: 1.6px solid var(--neutral-500, #8e8f8f);
   border-radius: 50%;
   cursor: pointer;
   pointer-events: all;
}

.range-slider__values {
   margin-top: 32px;
   .range-slider-min {
      margin-bottom: 14px;
   }

   .input-label {
      color: var(--neutral-700, #424445);
      height: 20px;
   }
   .input {
      margin-top: 6px;
      padding: 8px;
      padding-left: 8px;
      width: 100%;
      height: 28px;
      border-radius: 8px;
      border: 1px solid var(--neutral-500, #8e8f8f);
      color: var(--primary-500, #1a2d3d);
      font-size: 20px;
      font-weight: 700;
      position: relative;

      .min-value {
         margin-bottom: 14px;
      }
   }
}

.notification {
   display: flex;
   align-items: center;
   gap: 4px;
   position: relative;
}

.menu-item {
   width: 320px;
   min-height: 306px;
   padding: 12px 16px 32px 16px;
   position: absolute;
   background-color: white;
   top: 100%;
   left: 0;
   display: flex;
   flex-direction: column;
   border-radius: 12px;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
   display: none;
   z-index: 2;
   transition: max-height 0.5s ease;
   transition: max-height 0.5s ease, opacity 0.5s ease;
   &.open {
      max-height: 224px;
      display: block;
      background-color: white;
      z-index: 2;
   }
}

.avt-notification {
   cursor: pointer;
   height: 30px;
   width: 30px;
   > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: brightness(0) saturate(100%) invert(24%) sepia(10%) saturate(137%) hue-rotate(156deg) brightness(92%)
         contrast(86%);
   }
}

