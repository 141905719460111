.container {
   width: 400px;
   height: 560px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   align-items: center;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   padding: 24px 32px;
   .headingC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .bodyC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .inputValueC {
         width: 100%;
      }
      .createModeC {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
      }
   }
   .btnSave {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      .btn {
         position: absolute;
         bottom: 24px;
         display: flex;
         align-items: center;
         gap: 8px;
      }
   }
}
