.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .financingC {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      > span {
         font-size: 18px;
         font-weight: 700;
      }
      .financing {
         display: flex;
         gap: 80px;
         min-height: 300px;
         .financingStatus {
            display: flex;
            width: 240px;
            flex-direction: column;
            gap: 4px;
         }
         .divide {
            width: 2px;
            height: 240px;
            transform: rotate(180deg);
            background: var(--neutral-200, #d4d5d5);
         }
         .documentC {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            .uploadFileBtn {
               width: 100%;
               border-radius: 8px;
               height: 40px;
               padding: 0px 32px;
               color: var(--info-500, #295bff);
               border-radius: 8px;
               background: var(--info-100, #d6e0ff) !important;
            }
         }
      }
   }
}
