.container {
   width: 480px;
   height: 800px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   align-items: center;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   padding: 24px 32px;
   .headingC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .bodyC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .inputSelect {
         width: 100%;
      }
      .input_search {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 12px;
         > label {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;
            > div {
               border: 1px solid var(--neutral-500, #8c8f9a);
               background: var(--base-white, #fff);
               width: 100%;
               border-radius: 12px;
               padding: 12px;
               > input {
                  width: 100%;
               }
            }
         }
      }
      .notesInput {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 8px;
         > textarea {
            width: 100%;
            height: 85px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px;
            gap: 12px;
            flex: 1 0 0;
            border: 1px solid var(--neutral-500, #8c8f9a);
            background: var(--base-white, #fff);
            border-radius: 12px;
         }
      }
   }
   .btnSave {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      .btn {
         position: absolute;
         bottom: 24px;
         display: flex;
         align-items: center;
         gap: 8px;
      }
   }
}
