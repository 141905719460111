.questionLibraryC {
   border-right: 1px solid #d6d7d7;
   min-width: calc(432px + 32px);
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 12px;
   padding: 24px 16px 0 16px;
   .qlHeaderC {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      > span {
         color: var(--primary-500, #1a2d3d);
         font-size: 18px;
         font-weight: 700;
      }
      .input_search {
         display: flex;
         width: 100%;
         padding: 12px;
         align-items: center;
         gap: 12px;
         border-radius: 12px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         background: var(--base-white, #fff);
         > input {
            width: 100%;
            &::placeholder {
               color: var(--neutral-500, #8e8f8f);
               font-size: 16px;
            }
         }
         & .image_icon {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
               width: 32px;
               height: 32px;
               filter: brightness(0) saturate(100%);
            }
         }
      }
   }
   .qlBodyC {
      padding: 3px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      > li {
         display: flex;
         &.isDragging {
            opacity: 0.4;
         }
         > img {
            height: 18px;
            cursor: pointer;
         }
      }
   }
}
