.container {
   display: flex;
   min-width: 550px;
   padding: 24px;
   align-items: flex-start;
   gap: 16px;
   border-radius: 16px;
   background: var(--base-white, #fff);
   .property-img {
      width: 117px;
      height: 156px;
      border-radius: 8px;
      background: var(--neutral-100, #e6e6e6);
      > img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         border-radius: 8px;
      }
   }
   .contentC {
      height: 156px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .addressC {
         height: 44px;
         display: flex;
         gap: 8px;
      }
      .numbersC {
         display: flex;
         align-items: flex-start;
         gap: 12px;
         width: 100%;
         justify-content: space-between;
         .price {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            > span {
               color: var(--neutral-700, #424445);
               font-size: 14px;
            }
            .number-price {
               font-size: 24px;
               font-weight: 700;
            }
         }
      }
      .inforC {
         display: flex;
         align-items: center;
         gap: 8px;
         width: 100%;
         justify-content: space-between;

         .unit {
            display: flex;
            align-items: center;
            gap: 4px;
         }
      }
   }
}
section {
   width: 100% !important;
   height: 100% !important;
}
