.container {
   position: relative;
}

.title {
   font-weight: normal;
   cursor: pointer;
}

.img-icon {
   width: 20px;
   height: 20px;
   &.cursor-pointer {
      cursor: pointer;
   }
   > img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
         contrast(90%);
   }
}

@keyframes fade-in-out {
   0% {
      opacity: 0;
   }
   10% {
      opacity: 1;
   }
   90% {
      opacity: 1;
   }
   100% {
      opacity: 0;
   }
}

.copy-alert {
   text-wrap: nowrap;
   position: absolute;
   font-size: 14px;
   font-weight: bold;
   color: green;
   animation: fade-in-out 2s;
}

.placement-right {
   top: 0;
   left: 25px;
}

.placement-top-left {
   top: -21px;
   left: -172px;
}

.placement-left {
   top: 0;
   right: 120px;
}
