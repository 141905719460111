.contentC {
   display: flex;
   gap: 8px;
   align-items: center;
   padding: 8px;
   width: 100%;
   -webkit-user-select: none;
   user-select: none;
   cursor: pointer;
   border-radius: 4px;
   background: var(--neutral-50, #f7f8f8);
   > input {
      height: 18px;
      width: 18px;
   }
   > span {
      font-weight: 400;
   }
}
