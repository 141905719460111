.toast-container {
   position: fixed;
   top: 64px;
   left: 50%;
   transform: translateX(-50%);
   z-index: 1000;
   padding: 16px;
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.toast {
   min-width: 370px;
   height: 78px;
   display: inline-flex;
   padding: 16px;
   align-items: flex-start;
   gap: 16px;
   border-radius: 12px;
   animation: toast-enter 0.5s;
   position: relative;
   &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      background-color: #007bff;
      width: 100%;
      animation: border-bottom-animation 5s linear forwards;
   }
   &.toast-exit {
      animation: toast-exit 0.5s ease-out;
   }
   &.toast-success::after {
      background-color: #28a745;
   }
   &.toast-error::after {
      background-color: #dc3545;
   }
   &.toast-informative::after {
      background-color: #17a2b8;
   }
   &.toast-warning::after {
      background-color: #ffc107;
   }
   .img-icon {
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
      }
   }
   .toast-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      .toast-message {
         font-size: 14px;
      }
   }
   .btn-action {
      font-size: 14px;
   }
}

.toast-success {
   border: 1px solid var(--positive-500, #27be69);
   background: var(--positive-50, #e6faee);
}

.toast-info {
   border: 1px solid var(--info-500, #295bff);
   background: var(--info-50, #ebefff);
}

.toast-warning {
   border: 1px solid var(--warning-500, #ffbf0f);
   background: var(--warning-50, #fff8e5);
}

.toast-error {
   border: 1px solid var(--negative-500, #f2415a);
   background: var(--negative-50, #feecee);
}

.btn-clear {
   padding: 0;
   .img-icon-clear {
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(43%) sepia(6%) saturate(94%) hue-rotate(169deg) brightness(91%)
            contrast(87%);
      }
   }
}

@keyframes border-bottom-animation {
   from {
      width: 100%;
   }
   to {
      width: 0;
   }
}

@keyframes toast-enter {
   0% {
      opacity: 0;
      transform: translateY(-100%);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}

@keyframes toast-exit {
   0% {
      opacity: 1;
      transform: translateY(0);
   }
   100% {
      opacity: 0;
      transform: translateY(100%);
   }
}
