.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   border-top: 1px solid #aeafb0;
   .addPropertyC {
      display: flex;
      align-items: center;
      gap: 24px;
      > span {
         font-size: 18px;
         font-weight: 700;
      }
      .btn {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: #295bff;
         font-size: 14px;
         .img-icon {
            display: flex;
            align-items: center;
            width: 16px;
            height: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                  brightness(101%) contrast(102%);
            }
         }
      }
   }
   .propertiesC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 24px;
      overflow-x: auto;
      padding: 12px 0;
      .spinnerC {
         display: flex;
         gap: 8px;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 100%;
      }
   }
}
