.assetC {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 8px;
   .assetDataC {
      display: flex;
      align-items: center;
      gap: 4px;
      .img-icon {
         width: 24px;
         height: 24px;
         > img {
            width: 24px;
            height: 24px;
            filter: brightness(0) saturate(100%) invert(25%) sepia(4%) saturate(294%) hue-rotate(156deg) brightness(95%)
               contrast(90%);
         }
      }
      &.default {
         background-color: rgba(255, 0, 0, 0.184);
      }
   }
}
