.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   flex-grow: 1;
}

.unitDetailC {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   width: 100%;

   .unitDataDetailC {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
   }
}

.heading-title {
   font-size: 18px;
   font-weight: 700;
   display: flex;
   align-items: center;
   gap: 12px;
   .img-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(39%) sepia(79%) saturate(2370%) hue-rotate(326deg) brightness(94%)
            contrast(102%);
      }
   }
}

.unitDataHeadingC {
   display: flex;
   align-items: center;
   gap: 12px;
   min-width: 192px;
}

.dot {
   width: 4px;
   height: 4px;
   border-radius: 50%;
   background-color: var(--neutral-500, #8e8f8f);
}

.unitDataC {
   width: 100%;
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
   display: flex;
   padding: 16px;
   align-items: flex-start;
   gap: 24px;
   flex-wrap: wrap;
   > div {
      min-width: 244px;
      padding: 0;
      > div {
         width: 100%;
         > div {
            width: 100%;
         }
      }
   }
}

.leasingC {
   width: 100%;
   display: flex;
   gap: 24px;
   justify-content: space-between;
   .leasingLeftC {
      display: flex;
      flex-grow: 1;
      padding: 16px;
      gap: 24px;
      flex-wrap: wrap;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      > div {
         min-width: 244px;
         padding: 0;
         > div {
            width: 100%;
            > div {
               width: 100%;
            }
         }
      }
   }
   .leasingRightC {
      width: 100%;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
   }
}

.inspectionAsset {
   display: flex;
   padding: 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
   .headingC {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      .img-icon {
         width: 24px;
         height: 24px;
         display: flex;
         align-items: center;
         img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(12%) sepia(6%) saturate(4861%) hue-rotate(166deg)
               brightness(94%) contrast(87%);
         }
      }
   }
   .linkC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
   }
}

.uploadBtn {
   width: 100%;
   border-radius: 8px;
   color: #295bff;
}

.uploadImgBtn {
   display: flex;
   width: 56px;
   height: 56px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   background: var(--neutral-100, #e6e6e6) !important;
   .img-icon {
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(23%) sepia(4%) saturate(347%) hue-rotate(155deg) brightness(97%)
            contrast(87%);
      }
   }
}

.linkUploadC {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 12px;
   background-color: rgba(255, 0, 0, 0.184);
   .img-icon {
      display: flex;
      align-items: center;
      img {
         filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg) brightness(92%)
            contrast(96%);
      }
   }
   .linkIconsC {
      display: flex;
      align-items: center;
      gap: 4px;
      .img-icon {
         display: flex;
         align-items: center;
         img {
            filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg) brightness(92%)
               contrast(96%);
         }
      }
   }
   > span {
      max-width: 310px;
   }
}

.unitDataRightC {
   width: 100%;
   display: flex;
   padding: 16px;
   align-items: flex-start;
   flex-wrap: wrap;
   justify-content: space-between;
   border-radius: 8px;
   gap: 24px;
   background: var(--neutral-50, #f7f8f8);
   .point {
      gap: 24px;
      display: flex;
      padding: 0;
      > div {
         width: 174px;
         padding: 0;
      }
   }
   .listPhotos {
      width: 248px;
      flex: 1;
   }

   .uploadImg {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      .img {
         width: 56px;
         height: 56px;
         border-radius: 8px;
         background: var(--neutral-100, #e6e6e6);
         > img {
            width: 100%;
            height: 100%;
         }
         &.addImg {
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
               width: 24px;
               height: 24px;
               filter: brightness(0) saturate(100%) invert(20%) sepia(6%) saturate(280%) hue-rotate(155deg)
                  brightness(99%) contrast(80%);
            }
         }
      }
   }
}

.addPhotosC {
   display: flex;
   gap: 8px;
   padding: 15px 0 10px 10px;
   .img-icon {
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(12%) sepia(12%) saturate(2500%) hue-rotate(166deg) brightness(97%)
            contrast(88%);
      }
   }
}

.divide-hr {
   margin-bottom: 10px;
   width: 100%;
   background: var(--neutral-300, #c0c1c1);
}
