.contentC {
   display: flex;
   gap: 16px;
   align-items: center;
   > img:first-child {
      height: 56px;
      width: 56px;
   }
   > img:last-child {
      height: 36px;
   }
}
