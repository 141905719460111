.container {
   width: 100%;
   padding: 0 8px;
   .list {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      flex-wrap: wrap;
      .card {
         display: flex;
         min-width: 400px;
         max-width: 420px;
         padding: 24px;
         flex-direction: column;
         gap: 12px;
         flex: 1 0 0;
         border-radius: 16px;
         background: var(--base-white, #fff);
         box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
         .name {
            display: flex;
            gap: 12px;
            > span {
               flex: 1 0 0;
               font-size: 18px;
               font-weight: 700;
            }
            .img-icon {
               cursor: pointer;
               height: 24px;
               width: 24px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(23%) sepia(98%) saturate(2371%) hue-rotate(224deg)
                     brightness(101%) contrast(101%);
               }
            }
         }
         .infor {
            display: flex;
            gap: 4px;
            .img-icon {
               height: 20px;
               width: 20px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(44%) sepia(0%) saturate(1200%) hue-rotate(51deg)
                     brightness(90%) contrast(88%);
               }
            }
            > span {
               font-size: 16px;
               min-width: 96px;
            }
            .value {
               font-weight: 700;
            }
            .valueStatus {
               padding: 2px 6px;
               border-radius: 4px;
            }
         }
      }
      .wrapper {
         .cardNoLead {
            opacity: 0.5;
            display: flex;
            min-width: 400px;
            max-width: 420px;
            padding: 24px;
            flex-direction: column;
            gap: 12px;
            flex: 1 0 0;
            border-radius: 16px;
            background: var(--base-white, #fff);
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
            .name {
               display: flex;
               gap: 12px;
               > span {
                  flex: 1 0 0;
                  font-size: 18px;
                  font-weight: 700;
               }
               .img-icon {
                  height: 24px;
                  width: 24px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(23%) sepia(98%) saturate(2371%) hue-rotate(224deg)
                        brightness(101%) contrast(101%);
                  }
               }
            }
            .infor {
               display: flex;
               gap: 4px;
               .img-icon {
                  height: 20px;
                  width: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(44%) sepia(0%) saturate(1200%) hue-rotate(51deg)
                        brightness(90%) contrast(88%);
                  }
               }
               > span {
                  font-size: 16px;
                  min-width: 96px;
               }
               .value {
                  font-weight: 700;
               }
               .valueStatus {
                  padding: 2px 6px;
                  border-radius: 4px;
               }
            }
         }
         .btnAdd {
            > button {
               padding: 10px 56px 10px 48px;
               display: flex;
               height: 56px;
               justify-content: center;
               align-items: center;
               gap: 8px;
               .img-icon {
                  width: 18px;
                  height: 18px;
                  > img {
                     width: 100%;
                     height: 100%;
                  }
               }
            }
         }
      }
   }
}

.loading {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
}

.noData {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--neutral-500, #8e8f8f);
}
