.contentC {
   height: 100%;
   width: 100%;
   overflow: hidden;
   position: relative;
}

.loading {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 8px;
}
