.container {
   display: flex;
   width: 800px;
   height: 640px;
   flex-direction: column;
   align-items: center;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .heading {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .userInfoC {
         display: flex;
         align-items: center;
         gap: 12px;
         flex: 1 0 0;
         .avt {
            width: 40px;
            height: 40px;
            border: none;
            > img {
               width: 100%;
               height: 100%;
               border-radius: 50%;
            }
         }
         .nameC {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            font-size: 14px;
            .role {
               color: var(--neutral-600, #68696a);
            }
         }
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .content {
      width: 100%;
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .input_search {
         display: flex;
         width: 100%;
         align-items: center;
         gap: 12px;
         > label {
            > div {
               > input {
                  font-size: 18px;
                  font-weight: 700;
               }
            }
         }
      }
      .message {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         // > textarea {
         //    font-size: 16px;
         //    padding: 12px 16px;
         //    width: 100%;
         //    height: 310px;
         //    border-radius: 12px;
         //    border: 1px solid var(--neutral-500, #8c8f9a);
         //    background: var(--base-white, #fff);
         // }
           .inputMentionC {
            font-size: 16px;
            padding: 12px 16px;
            width: 100%;
            height: 310px;

            font-size: 16px;
            border-radius: 12px;
            border: 1px solid var(--neutral-500, #8c8f9a);
            background: var(--base-white, #fff);
            padding: 2px 12px;
            overflow-y: auto;
            > div {
               height: 100%;
               > div {
                  border: 0 !important;
                  height: 100%;
                  > strong {
                     color: transparent;
                  }
               }
               > textarea {
                  overflow: auto !important;
                  line-height: 1.4;
                  padding: 2px 12px;
                  height: 100%;
               }
            }
            > div:nth-child(2) {
               overflow-y: auto;
               margin-top: 20px;
               width: 200px;
               border: 1px solid var(--neutral-500, #8c8f9a);
               padding: 5px;
            }
            .mentionC {
               background-color: #cee4e5;
               height: 100%;
            }
            > div {
               width: 100%;
            }
         }
      }
      .keywordC {
         width: 100%;
         display: flex;
         padding-left: 16px;
         align-items: center;
         gap: 12px;
         border-radius: 12px;
         border: 1px solid var(--neutral-500, #8c8f9a);
         background: var(--base-white, #fff);
         .img-icon {
            width: 20px;
            height: 20px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg)
                  brightness(87%) contrast(90%);
            }
         }
         .input-select {
            width: 100%;
            > div {
               > div {
                  outline: none;
               }
            }
         }
      }
   }
   .btnSave {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 0 32px 32px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      .btn {
         display: flex;
         padding: 12px 48px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         .img-icon {
            width: 18px;
            height: 18px;
            > img {
               width: 100%;
               height: 100%;
            }
         }
      }
   }
}
