.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .sourceC {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      > span {
         font-size: 18px;
         font-weight: 700;
      }
      .source {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         gap: 12px;
         .sourceDetail {
            padding: 8px;
            width: 290px;
         }
      }
   }
   .contactC {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .contactTitle {
         display: flex;
         align-items: center;
         gap: 24px;
         > span {
            font-size: 18px;
            font-weight: 700;
         }
         .addContact {
            display: flex;
            padding: 0px 16px 0px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            // flex: 1 0 0;
            .img-icon {
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(33%) sepia(59%) saturate(7497%) hue-rotate(227deg)
                     brightness(104%) contrast(102%);
               }
            }
            > span {
               color: var(--info-500, #295bff);
               text-align: center;
               font-size: 14px;
               font-weight: 700;
            }
         }
      }
      .contact {
         display: flex;
         padding: 8px;
         gap: 12px;
         .contactDetail {
            display: flex;
            flex-direction: column;
            width: 290px;
            gap: 4px;
         }
      }
   }
   .itemC {
      display: flex;
      width: 100%;
      padding: 16px;
      align-items: center;
      gap: 24px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      .itemDetail {
         width: 290px;
      }
   }
   .unitDetailC {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      width: 100%;
      min-width: 400px;

      .unitDataDetailC {
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         gap: 24px;
         width: 100%;
      }
   }

   .heading-title {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 24px;
         height: 24px;
         cursor: pointer;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(39%) sepia(79%) saturate(2370%) hue-rotate(326deg)
               brightness(94%) contrast(102%);
         }
      }
   }

   .unitDataHeadingC {
      display: flex;
      align-items: center;
      gap: 12px;
      min-width: 192px;
      width: 160px;
   }

   .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--neutral-500, #8e8f8f);
   }

   .unitDataC {
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      display: flex;
      padding: 16px;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      flex-wrap: wrap;
      min-width: 400px;
      > div {
         min-width: 200px;
         padding: 0;
         > div {
            width: 100%;
            > div {
               width: 100%;
            }
            > img {
               filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                  brightness(92%) contrast(96%);
            }
         }
      }
   }
   .leasingC {
      display: flex;
      gap: 24px;
      flex: 1 0 0;
      min-width: 400px;

      .leasing {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         gap: 24px;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
      }
      .documentC {
         display: flex;
         flex-direction: column;
         padding: 16px;
         gap: 8px;
         flex: 1 0 0;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
         .uploadBtn {
            border-radius: 8px;
            background: var(--info-100, #d6e0ff);
            color: var(--info-500, #295bff);
         }
         .linkC {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .linkUploadC {
               width: 100%;
               display: flex;
               align-items: center;
               gap: 12px;
               > span {
                  flex: 1 0 0;
               }
               .img-icon {
                  width: 24px;
                  height: 24px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                        brightness(92%) contrast(96%);
                  }
               }
               .linkIconsC {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  .img-icon {
                     width: 20px;
                     height: 20px;
                     > img {
                        width: 100%;
                        height: 100%;
                        filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                           brightness(92%) contrast(96%);
                     }
                     &.clear {
                        > img {
                           width: 100%;
                           height: 100%;
                           filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(3368%)
                              hue-rotate(328deg) brightness(97%) contrast(95%);
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
.divide {
   width: 100%;
}
