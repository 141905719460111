.contentC {
   background-color: #fff;
   padding: 16px;
   display: flex;
   flex-direction: column;
   gap: 8px;
   position: relative;
   width: 100%;
   background-color: white;
   max-width: 80px;
   transition: all 0.3s ease;
   &:hover {
      .expandBtnC {
         display: flex;
      }
   }
   > ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > li {
         > a {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 12px;
            border-radius: 8px;
            > img {
               height: 24px;
               width: 24px;
               filter: brightness(0) saturate(100%) invert(42%) sepia(15%) saturate(384%) hue-rotate(190deg)
                  brightness(93%) contrast(86%);
            }
            > span {
               display: none;
            }
         }
         .active {
            background-color: var(--Primary-Midnight-Blue, #1a2d3d);
            > img {
               filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7465%) hue-rotate(51deg)
                  brightness(115%) contrast(100%);
            }
         }
      }
   }
   .divider {
      border-bottom: 1px solid var(--neutral-base, #8c8f9a);
   }
   .expandBtnC {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.5);
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      border-radius: 50%;
      transition: all 0.3s ease;
      animation: fadeIn 0.3s ease;
      &.active {
         transform: translate(50%, -50%) rotate(180deg);
         transform-origin: center;
      }
   }
   &.expandedC {
      max-width: 272px;
      > ul {
         > li {
            > a {
               > span {
                  display: block;
                  font-weight: 700;
                  white-space: nowrap;
                  overflow: hidden;
               }
            }
            .active {
               > span {
                  color: var(--Primary-White, #fff);
               }
            }
         }
      }
   }
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@media screen and (max-width: 750px) {
   .contentC {
      max-width: 272px;
      position: absolute;
      height: 100%;
      z-index: 10;
      transform: translateX(-100%);
      // right: 100%;
      > ul {
         > li {
            > a {
               > span {
                  display: block;
                  font-weight: 700;
                  white-space: nowrap;
                  overflow: hidden;
               }
            }
            .active {
               > span {
                  color: var(--Primary-White, #fff);
               }
            }
         }
      }
      &:hover {
         .expandBtnC {
            display: none;
         }
      }
      &.expandedC {
         transform: translateX(0);
         // right: calc(100% - 272px);
      }
   }
}
