.container {
   min-width: 800px;
   height: 90%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .headingC {
      display: flex;
      padding: 24px 32px;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      .headingLeftC {
         display: flex;
         align-items: center;
         gap: 12px;
         flex-grow: 1;
         .img-icon {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg)
                  brightness(92%) contrast(90%);
            }
         }
         > span {
            white-space: nowrap;
            font-size: 20px;
            font-weight: 700;
         }
      }
      .headingRightC {
         display: flex;
         align-items: center;
         gap: 12px;
         justify-content: flex-end;
         .input-search {
            width: 400px;
         }
         .closeBtn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            > button {
               width: 32px;
               height: 32px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                     brightness(92%) contrast(89%);
               }
            }
         }
      }
   }
   .tabs {
      display: flex;
      justify-content: space-between;
      padding: 0px 32px;
      align-items: flex-start;
      gap: 24px;
      .btn {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: #295bff;
         font-size: 14px;
         .img-icon {
            display: flex;
            align-items: center;
            width: 16px;
            height: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                  brightness(101%) contrast(102%);
            }
         }
      }
   }
   .tearmsSetC {
      width: 100%;
      height: 72%;
      display: flex;
      padding: 24px 32px 64px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      overflow-y: auto;
      position: relative;
      .spinnerC {
         position: absolute;
         left: 0;
         top: 50%;
         width: 100%;
         display: flex;
         justify-content: center;
      }
      > div{
         width: 100%;
      }
   }
   .btnSave {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: rgba(255, 255, 255, 0.64);
      border-radius: 0 0 24px 24px;
   }
}

.noDataC {
   position: absolute;
   left: 0;
   top: 50%;
   width: 100%;
   display: flex;
   justify-content: center;
   color: var(--neutral-500, #8e8f8f);
   font-size: 25px;
}
