.react-calendar-timeline .rct-header-root {
   background: var(--primary-50, #e2ebf3);
   font-weight: 700;
}
.rct-calendar-header .rct-dateHeader {
   background: var(--primary-50, #e2ebf3);
}
.react-calendar-timeline .rct-header-root {
   height: 60px;
}
.react-calendar-timeline .rct-dateHeader-primary {
   border-right: none;
}
.react-calendar-timeline .rct-dateHeader {
   border-left: 1px solid #bbb;
   border-bottom: none;
}
.react-calendar-timeline .rct-calendar-header {
   border: none;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
   line-height: normal !important;
}
