.contentC {
   width: 100%;
}
.groupC {
   display: flex;
   width: 320px;
   height: 128px;
   padding: 12px 12px 24px 12px;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   background-color: white;
   > span {
      flex: 1;
      text-wrap: wrap;
   }
   .infoC {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      .info {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 2px;
      }
   }
}
