.container {
   width: 480px;
   height: 60%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .contentC {
      width: 100%;
      height: 84%;
      overflow-y: auto;
      display: flex;
      padding: 2px 32px 32px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .inputFieldC {
         width: 100%;
         > label {
            display: flex;
            flex-direction: column;
            gap: 8px;
            > div {
               width: 100%;
               display: flex;
               height: 48px;
               padding: 12px;
               align-items: center;
               gap: 12px;
               border-radius: 12px;
               border: 1px solid var(--neutral-500, #8c8f9a);
               background: var(--base-white, #fff);
               > input {
                  width: 100%;
               }
            }
         }
      }
      .divide {
         width: 100%;
      }
      .switchOptionC {
         display: flex;
         height: 48px;
         align-items: center;
         gap: 8px;
      }
   }
   .btnSave {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 0 32px 32px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 24px 24px;
      background: rgba(255, 255, 255, 0.64);
      .btn {
         display: flex;
         padding: 12px 48px;
         justify-content: center;
         align-items: center;
         gap: 8px;
      }
   }
}
