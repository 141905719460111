.contentC {
   display: flex;
   padding: 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   width: 340px;
   height: 255px;
   border-radius: 16px;
   background-color: var(--base-white, #fff);
   position: relative;
   .tittleC {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(43%) sepia(6%) saturate(99%) hue-rotate(169deg) brightness(89%)
               contrast(81%);
         }
      }

      > span {
         color: var(--Primary-Midnight-Blue, #1a2d3d);
         font-size: 16px;
         font-weight: 700;
         flex: 1 0 0;
      }
      .btn-setting {
         height: 100%;
         background-color: transparent;
         color: var(--primary-500, #1a2d3d);
         font-size: 14px;
         display: flex;
         padding: 0px 16px 0px 12px;
         gap: 4px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         border-radius: 8px;
         > img {
            width: 16px;
            height: 16px;
         }
      }
   }
   .description {
      color: var(--Primary-Midnight-Blue, #1a2d3d);
      font-size: 14px;
      font-weight: 400;
      height: 115px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      // &:hover {
      //    .describeBox {
      //       opacity: 1;
      //    }
      // }
   }

   .describeBox {
      width: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 2;
      padding: 10px;
      border-radius: 16px;
      background-color: var(--primary-500, #1a2d3d);
      color: white;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      &::after {
         content: "";
         position: absolute;
         top: 50%;
         left: -19px;
         transform: translateY(-50%);
         width: 0;
         height: 0;
         border: 10px solid transparent;
         border-right-color: var(--primary-500, #1a2d3d);
         z-index: 2;
      }
   }

   .btnC {
      display: flex;
      justify-content: space-between;
      align-items: end;
      width: 100%;
      gap: 8px;
      .btn-subcribe {
         > img {
            height: 18px;
            width: 18px;
         }
         font-size: 14px;
         line-height: 128%;
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         flex: 1 0 0;
         &.unsubcribe {
            border: 1px solid var(--negative-500, #f2415a);

            color: var(--negative-500, #f2415a);
            outline: none;
            > img {
               filter: brightness(0) saturate(100%) invert(46%) sepia(45%) saturate(6305%) hue-rotate(327deg)
                  brightness(96%) contrast(99%);
            }
         }
      }
      .img-new {
         cursor: pointer;
         width: 24px;
         height: 24px;
         filter: brightness(0) saturate(100%) invert(43%) sepia(6%) saturate(99%) hue-rotate(169deg) brightness(89%)
            contrast(81%);
      }
   }
}
