.container {
   display: flex;
   align-items: center;

   .img {
      width: 20px;
      height: auto;
      margin-right: 6px;

      &.invalid {
         filter: brightness(0) saturate(100%) invert(48%) sepia(55%) saturate(6684%) hue-rotate(329deg) brightness(98%)
            contrast(93%);
      }
      &.valid {
         filter: brightness(0) saturate(100%) invert(57%) sepia(75%) saturate(440%) hue-rotate(93deg) brightness(91%)
            contrast(89%);
      }
   }

   span {
      &.invalid {
         filter: brightness(0) saturate(100%) invert(48%) sepia(55%) saturate(6684%) hue-rotate(329deg) brightness(98%)
            contrast(93%);
      }

      &.valid {
         filter: brightness(0) saturate(100%) invert(57%) sepia(75%) saturate(440%) hue-rotate(93deg) brightness(91%)
            contrast(89%);
      }
   }
}
