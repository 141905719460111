.container {
   width: 480px;
   background-color: var(--Primary-White, #fff);
   border-radius: 24px;
   .context {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 32px;
      .icon {
         width: 96px;
         height: 96px;
      }
   }
}
.content-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 8px;
   .title {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
   }
   .description {
      color: var(--neutral-700, #424445);
      text-align: center;
      font-size: 20px;
   }
}
.btn-bottom {
   display: flex;
   padding: 32px;
   justify-content: center;
   align-items: center;
   gap: 10px;
}
