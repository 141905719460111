.contentC {
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   flex: 1 0 0;
}
.top {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 12px 24px;
   gap: 12px;
   .topLeft {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;
      .input_search {
         display: flex;
         min-width: 360px;
         align-items: center;
         gap: 12px;
         > label {
            gap: 0;
         }
      }
      .filterList {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 12px;
      }
   }
   .topRight {
      display: flex;
      align-items: center;
      gap: 12px;
   }
}

.filterAndTitleC {
   display: flex;
   align-items: center;
   gap: 8px;
   > span {
      text-wrap: nowrap;
   }
}
.filter-dropdown {
   > div {
      background-color: transparent;
      gap: 4px;
   }
}
.selectOptionC {
   width: 100%;
   > div {
      padding: 0;
      > div {
         outline: none;
         > div {
            width: max-content;
            border: none;
            background-color: transparent !important;
            > div {
               max-height: none;
            }
         }
      }
   }
}

.divider-container {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 30px;
      transform: rotate(180deg);
      margin: 0;
   }
}
