.contentC {
   background-color: #fff;
   height: 90%;
   width: 90%;
   padding: 24px 32px 20px 32px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   position: relative;
   .controlsC {
      display: flex;
      padding-top: 32px;
      padding-bottom: 32px;
      gap: 16px;
   }
   .toolBoxWrapper {
      position: absolute;
      bottom: 3rem;
      right: 4rem;
      z-index: 1;
   }
}

.topC {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.top1 {
   display: flex;
   gap: 16px;
   align-items: center;
   justify-content: space-between;
   .top1Right {
      display: flex;
      gap: 16px;
      align-items: center;
      .toggleCommentC {
         display: none;
      }
   }
   .close-btn-responsive {
      display: none;
   }
}

.top2 {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.new {
   font-size: 18px;
   font-weight: 700;
}

.close-btn {
   > button {
      width: 32px;
      height: 32px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg) brightness(92%)
            contrast(89%);
      }
   }
}

.loading {
   display: flex;
   gap: 8px;
   align-items: center;
   justify-content: center;
}

.airtable {
   gap: 8px;
   .img-icon {
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
      }
   }
   &.existed {
      display: flex;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--info-500, #295bff);
      color: var(--info-500, #295bff);
      font-weight: 700;
      .img-icon {
         display: flex;
         align-items: center;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(28%) sepia(98%) saturate(3551%) hue-rotate(224deg)
               brightness(100%) contrast(102%);
         }
      }
   }
}

.statusAndBtnC {
   display: flex;
   align-items: center;
   gap: 12px;
   .statusC {
      display: flex;
      align-items: center;
      gap: 8px;
      > span {
         color: var(--primary-500, #1a2d3d);
      }
      .status {
         color: var(--primary-500, #1a2d3d);
         font-weight: 700;
         font-size: 20px;
      }
   }
   .btnC {
      display: flex;
      align-items: center;
      gap: 8px;
      > span {
         text-wrap: nowrap;
         width: 100%;
         color: var(--primary-500, #1a2d3d);
      }
      .btn {
         width: 100%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         &.reject {
            border-radius: 8px;
            background: var(--negative-100, #fcd9de);
            color: var(--negative-500, #f2415a);
         }
         &.mark-as-verified {
            border-radius: 8px;
            background: var(--positive-100, #d0f5e1);
            color: var(--positive-500, #27be69);
         }
         &.validate {
            border-radius: 8px;
            background: var(--positive-500, #27be69);
            color: white;
         }
      }
   }
}

.divider-container {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 40px;
      transform: rotate(180deg);
      margin: 0;
   }
}

.topMapC {
   width: 100%;
   height: 320px;
   display: flex;
   align-items: flex-start;
   gap: 16px;
}

.bodyC {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 16px;
   position: relative;
   overflow-y: auto;
   flex-grow: 1;
}

.detailC {
   width: 100%;
   height: 100%;
   display: flex;
   gap: 32px;
   // .conversationC {
   //    &.hidden {
   //       transform: translateX(100%);
   //    }
   // }
}

.bottomC {
   background-color: white;
   padding: 20px;
   display: flex;
   align-items: flex-start;
   gap: 16px;
}

.btn {
   width: 100%;
   &.reject {
      border-radius: 10px;
      background: var(--negative-100, #fcd9de);
      color: var(--negative-500, #f2415a);
   }
   &.review-later {
      border-radius: 10px;
      background: var(--Primary-Peach, #ffb085);
      color: var(--primary-500, #1a2d3d);
   }
   &.review-now {
      border-radius: 10px;
      background: var(--Primary-Spring-Bud, #9bd0b0);
      color: var(--primary-500, #1a2d3d);
   }
}

.no-prop {
   .close-btn {
      display: flex;
      justify-content: flex-end;
      > button {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg) brightness(92%)
               contrast(89%);
         }
      }
   }
   > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--neutral-600, #68696a);
      font-size: 18px;
   }
}

@media screen and (max-width: 845px) {
   .topC {
      .top1 {
         align-items: flex-start;
         flex-direction: column;
         > div {
            width: 100%;
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: space-between;
         }
         .top1Right {
            .toggleCommentC {
               display: block;
            }
         }
         .close-btn {
            display: none;
         }
         .close-btn-responsive {
            display: block;
            width: 32px;
            height: 32px;
            > button {
               width: 32px;
               height: 32px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                     brightness(92%) contrast(89%);
               }
            }
         }
      }
   }
   .bodyC {
      .detailC {
         width: 100%;
         height: 100%;
         display: flex;
         gap: 32px;
         .conversationC {
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
            transition: all 0.3s ease;
            background: var(--base-white, #fff);
            border-left: 1px solid #aeafb0;
            &.hidden {
               transform: translateX(100%);
            }
         }
      }
   }
}
