.mapPropC {
   width: 12px;
   height: 12px;
   background-color: var(--Primary-Sun, #ffe39b);
   border-radius: 50%;
   outline: 3px solid var(--primary-500, #1a2d3d);
   position: relative;
   .mapPropMini {
      position: absolute;
      bottom: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      width: 52px;
      height: 59px;
      > img:first-child {
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
      > img:last-child {
         height: calc(52px - 10px);
         width: calc(52px - 10px);
         position: absolute;
         top: 5px;
         left: 50%;
         transform: translateX(-50%);
         border-radius: 8px;
      }
   }
   .mapPropInfo {
      z-index: 1;
      position: absolute;
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      padding: 12px;
      border-radius: 12px;
      display: flex;
      gap: 8px;
      min-width: 304px;
      font-size: 11px;
      font-weight: 400;
      align-items: center;
      .mapPropInfoL {
         height: 100%;
         width: 96px;
         > img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
      .mapPropInfoR {
         display: flex;
         flex-direction: column;
         gap: 4px;
         > div:first-child {
            display: flex;
            gap: 4px;
            > img {
               height: 14px;
               width: 14px;
            }
            > span {
               cursor: pointer;
               &:hover {
                  text-decoration: underline;
               }
            }
            > button {
               > img {
                  height: 14px;
                  width: 14px;
               }
            }
         }
         > ul {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
            > li {
               display: flex;
               gap: 2px;
               align-items: center;
               > p {
                  width: 86px;
               }
            }
         }
         > div:last-child {
            display: flex;
            gap: 8px;
            align-items: center;
            > div {
               display: flex;
               align-items: center;
               gap: 4px;
            }
         }
      }
   }
}
