.previousNextC {
   display: flex;
   align-items: center;
   gap: 4px;
   cursor: pointer;
   .img-icon {
      width: 24px;
      height: 24px;
      > img {
         display: flex;
         align-items: center;
         width: 24px;
         height: 24px;
         filter: brightness(0) saturate(100%) invert(14%) sepia(15%) saturate(1743%) hue-rotate(165deg) brightness(95%)
            contrast(92%);
      }
   }
}

.turningPageC {
   display: flex;
   align-items: center;
   gap: 16px;
   .img-icon {
      > img {
         filter: brightness(0) saturate(100%) invert(26%) sepia(11%) saturate(124%) hue-rotate(155deg) brightness(91%)
            contrast(90%);
      }
   }
}

.divider-container {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 30px;
      transform: rotate(180deg);
      margin: 0;
   }
}
