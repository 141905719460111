.menuList {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   > ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      > li {
         position: relative;
         > button {
            display: flex;
            padding: 0px 12px;
            align-items: center;
            gap: 8px;
            > span {
               color: var(--Primary-Midnight-Blue, #1a2d3d);
               font-size: 18px;
               font-weight: 400;
            }
            > img {
               width: 20px;
               height: 20px;
               filter: invert(15%) sepia(22%) saturate(1032%) hue-rotate(166deg) brightness(97%) contrast(96%);
            }
            &.active {
               > span {
                  font-weight: 700;
               }
               > img {
                  font-weight: bolder;
                  filter: brightness(0) saturate(100%) invert(26%) sepia(25%) saturate(6974%) hue-rotate(221deg)
                     brightness(109%) contrast(104%);
               }
               ::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  width: 4px;
                  background-color: #3366ff; /* Blue color */
                  border-radius: 4px; /* Optional, for rounded corners */
               }
            }
         }
         .active {
            > span {
               font-weight: 700;
            }
            > img {
               font-weight: bolder;
               filter: brightness(0) saturate(100%) invert(26%) sepia(25%) saturate(6974%) hue-rotate(221deg)
                  brightness(109%) contrast(104%);
            }
         }
      }
   }
}
