.container {
   display: flex;
   min-width: 480px;
   padding: 24px;
   align-items: flex-start;
   gap: 16px;
   border-radius: 16px;
   border: 3px solid transparent;
   background: var(--base-white, #fff);
   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
   cursor: pointer;
   &.selected {
      border-radius: 16px;
      border: 3px solid var(--positive-500, #27be69);
      background: var(--positive-50, #e6faee);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
   }
   .propertyImgC {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .image {
         width: 117px;
         height: 123px;
         background-color: #424445;
         border-radius: 8px;
         > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
         }
      }
      .btn {
         outline: none;
         display: flex;
         height: 32px;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: var(--info-500, #295bff);
         text-align: center;
         font-size: 14px;
         font-weight: 700;
         border-radius: 8px;
         border: 1px solid var(--info-500, #295bff);
      }
   }
   .infoC {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .addressC {
         height: 44px;
         display: flex;
         gap: 8px;
         cursor: pointer;
         .img-icon {
            width: 20px;
            height: 20px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(7%) saturate(187%) hue-rotate(155deg)
                  brightness(95%) contrast(90%);
            }
            &.delete {
               filter: brightness(0) saturate(100%) invert(35%) sepia(54%) saturate(3936%) hue-rotate(331deg)
                  brightness(101%) contrast(90%);
            }
         }
      }
      .numbersC {
         display: flex;
         align-items: flex-start;
         gap: 12px;
         width: 100%;
         .price {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            > span {
               color: var(--neutral-700, #424445);
               font-size: 14px;
            }
            .number-price {
               font-size: 24px;
               font-weight: 700;
            }
         }
      }
   }
}

.btn-delete {
   outline: none;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   padding: 0;
}
