.container {
   width: 480px;
   height: 640px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   align-items: center;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   padding: 32px;
   .headingC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .inputSearch {
      width: 100%;
   }
   .bodyC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      > span {
         font-size: 16px;
         font-weight: 700;
      }
      .inputValueC {
         width: 100%;
         > div {
            > div {
               > div {
                  padding: 12px;
                  gap: 12px;
                  border-radius: 12px;
                  border: 1px solid var(--neutral-500, #8c8f9a);
                  background: var(--base-white, #fff);
               }
            }
         }
      }
      .selectPeople {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         align-items: flex-start;
         align-content: flex-start;
         gap: 8px;
         .action {
            display: flex;
            padding: 2px 6px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            background: var(--info-100, #d6e0ff);
            > span {
               font-size: 16px;
               font-weight: 400;
            }
            .img-icon {
               cursor: pointer;
               display: flex;
               text-align: center;
               justify-content: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
               }
            }
         }
      }
   }
   .btnSave {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      .btn {
         position: absolute;
         bottom: 24px;
         display: flex;
         align-items: center;
         gap: 8px;
      }
   }
}
