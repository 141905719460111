.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   padding-bottom: 20px;
   .topC {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 24px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      > div {
         width: 234px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 8px;
      }
   }
   .marketC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-top: 12px;
      border-top: 1px solid var(--neutral-300, #c0c1c1);
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .infoTextC {
         width: 100%;
         flex-wrap: wrap;

         display: flex;
         padding: 8px 0px;
         align-items: center;
         gap: 24px;
         > div {
            width: 416px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
         }
      }
      .infoNumberC {
         width: 100%;
         flex-wrap: wrap;
         display: flex;
         align-items: center;
         gap: 24px;
         > div {
            width: 416px;
            display: flex;
            padding: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            border-radius: 8px;
            // background: var(--neutral-50, #f7f8f8);
         }
      }
   }
   .housingAuthorityC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-top: 12px;
      border-top: 1px solid var(--neutral-300, #c0c1c1);
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .infoC {
         flex-wrap: wrap;
         width: 100%;
         display: flex;
         align-items: center;
         gap: 24px;
         > div {
            width: 416px;
            display: flex;
            padding: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            > div {
               > div {
                  width: 100%;
               }
            }
         }
      }
   }
   .neighborhoodC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-top: 12px;
      border-top: 1px solid var(--neutral-300, #c0c1c1);
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .infoTextC {
         display: flex;
         flex-wrap: wrap;

         padding: 8px 0px;
         align-items: center;
         gap: 24px;
         > div {
            width: 416px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
         }
      }
      .infoNumberC {
         flex-wrap: wrap;
         display: flex;
         align-items: center;
         gap: 24px;
         > div {
            width: 416px;
            display: flex;
            padding: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            border-radius: 8px;
            // background: var(--neutral-50, #f7f8f8);
         }
      }
   }
}
