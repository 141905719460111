.contentC {
   display: flex;
   border-left: 1px solid var(--neutral-400, #aeafb0);
   flex-direction: column;
   height: 100%;
   background: var(--base-white, #fff);
}

.toolBoxWrapper {
   position: absolute;
   bottom: 2rem;
   right: 2rem;
   z-index: 100;
}

.bodyC {
   display: flex;
   height: calc(100% - 50px);
   z-index: 0;
   background: var(--base-white, #fff);
}
