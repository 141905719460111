.container {
   width: calc(100% - 520px - 32px);
   display: flex;
   gap: 24px;
   .leftC {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      position: relative;
      width: calc(100% - 520px - 24px);
      .tabAndHistoryC {
         width: 100%;
         display: flex;
         // flex-wrap: wrap;
         align-items: flex-start;
         justify-content: space-between;
         gap: 24px;
         .tabs {
            flex-grow: 1;
         }
         .tabsResponsive {
            display: none;
         }
         .btnGroupC {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            .btn {
               // width: 150px;
               display: flex;
               height: 32px;
               padding: 0px 16px 0px 12px;
               justify-content: center;
               align-items: center;
               gap: 8px;
               font-size: 14px;
               > img {
                  width: 18px;
                  height: 18px;
               }
               &.viewHistory {
                  > span {
                     color: var(--info-500, #295bff);
                  }
               }
               &.lock {
                  > img {
                     filter: brightness(0) saturate(100%) invert(35%) sepia(22%) saturate(3456%) hue-rotate(316deg)
                        brightness(105%) contrast(101%);
                  }
                  > span {
                     color: var(--negative-500, #f2415a);
                  }
                  &:hover {
                     > img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(246deg)
                           brightness(109%) contrast(106%);
                     }
                     > span {
                        color: white;
                     }
                     background-color: var(--negative-500, #f2415a);
                     .img-icon {
                        > img {
                           filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
                              hue-rotate(246deg) brightness(109%) contrast(106%);
                        }
                     }
                  }
               }
               &.lock.locked {
                  > img {
                     filter: brightness(0) saturate(100%) invert(26%) sepia(5%) saturate(248%) hue-rotate(155deg)
                        brightness(95%) contrast(92%);
                  }
                  > span {
                     color: var(--neutral-700, #424445);
                  }
                  background-color: var(--neutral-200, #d4d5d5);
                  &:hover {
                     background-color: var(--neutral-700, #424445);
                     > span {
                        color: white;
                     }
                     > img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(246deg)
                           brightness(109%) contrast(106%);
                     }
                  }
               }
               // &.unlock {
               //    color: var(--negative-500, #f2415a);
               //    font-weight: 700;
               //    .img-icon {
               //       width: 18px;
               //       height: 18px;
               //       > img {
               //          width: 100%;
               //          height: 100%;
               //          filter: brightness(0) saturate(100%) invert(35%) sepia(22%) saturate(3456%) hue-rotate(316deg)
               //             brightness(105%) contrast(101%);
               //       }
               //    }
               //    &:hover {
               //       color: white;
               //       background-color: var(--negative-500, #f2415a);
               //       .img-icon {
               //          > img {
               //             filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
               //                hue-rotate(246deg) brightness(109%) contrast(106%);
               //          }
               //       }
               //    }
               // }
               // &.locked {
               //    color: var(--neutral-700, #424445);
               //    background-color: var(--neutral-200, #d4d5d5);
               //    font-weight: 700;
               //    .img-icon {
               //       width: 18px;
               //       height: 18px;
               //       > img {
               //          width: 100%;
               //          height: 100%;
               //          filter: brightness(0) saturate(100%) invert(26%) sepia(5%) saturate(248%) hue-rotate(155deg)
               //             brightness(95%) contrast(92%);
               //       }
               //    }
               //    &:hover {
               //       background-color: var(--neutral-700, #424445);
               //       color: white;
               //       .img-icon {
               //          > img {
               //             filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
               //                hue-rotate(246deg) brightness(109%) contrast(106%);
               //          }
               //       }
               //    }
               // }
            }
            .btnHover {
               transition: width 0.3s;
               width: 40px;
               > :last-child {
                  display: none;
               }
               &:hover {
                  width: 150px;
                  > :last-child {
                     display: block;
                     white-space: nowrap;
                     overflow: hidden;
                  }
               }
            }
         }
      }
      .pd {
         width: 100%;
      }
   }
}

@media screen and (max-width: 1146px) {
   .container {
      .leftC {
         .tabAndHistoryC {
            .tabs {
               display: none;
            }
            .tabsResponsive {
               min-width: 200px;
               display: block;
            }
         }
      }
   }
}
