.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .purchaseDetailsC {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .infoC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         padding: 8px;
         align-items: flex-start;
         gap: 24px;
         .data {
            width: 320px;
            min-height: 110px;
            padding: 10px 0;
            gap: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
            > div {
               width: 100%;
            }
         }
      }
   }
   .costC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-top: 12px;
      border-top: 1px solid var(--neutral-300, #c0c1c1);
      .heading {
         font-size: 18px;
         font-weight: 700;
      }
      .divider {
         width: 100%;
         border: none;
         border-left: 1px solid #c0c0c0;
      }
      .infoC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         padding: 8px;
         align-items: flex-start;
         gap: 12px;
         .grayBg {
            > div {
               display: flex;
               width: 280px;
               padding: 8px;
               flex-direction: column;
               align-items: flex-start;
               gap: 4px;
               border-radius: 8px;
               background: var(--neutral-50, #f7f8f8);
            }
         }
         > div {
            display: flex;
            width: 280px;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            border-radius: 8px;
         }
      }
   }
   .rentAndHAC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .tabsAndRecalculateC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         gap: 12px;
         align-items: center;
         justify-content: space-between;
         .modelsBtn {
            display: flex;
            gap: 8px;
            .btn {
               min-width: 185px;
               display: flex;
               height: 32px;
               padding: 0px 16px 0px 12px;
               justify-content: center;
               align-items: center;
               gap: 8px;
               font-size: 14px;
               &.approve {
                  border: 1px solid var(--positive-500, #00b67a);
                  color: var(--positive-500, #00b67a);
               }
               &.recalc {
                  border: 1px solid var(--info-500, #295bff);
                  color: var(--info-500, #295bff);
                  .img-icon {
                     width: 18px;
                     height: 18px;
                     > img {
                        width: 100%;
                        height: 100%;
                        filter: brightness(0) saturate(100%) invert(26%) sepia(81%) saturate(3303%) hue-rotate(224deg)
                           brightness(101%) contrast(103%);
                     }
                  }
               }
               &.delete {
                  border: 1px solid var(--negative-500, #f2415a);
                  color: var(--negative-500, #f2415a);
                  .img-icon {
                     width: 18px;
                     height: 18px;
                     > img {
                        width: 100%;
                        height: 100%;
                        filter: brightness(0) saturate(100%) invert(35%) sepia(39%) saturate(4140%) hue-rotate(330deg)
                           brightness(99%) contrast(91%);
                     }
                  }
               }
            }
         }
      }
   }
   .applicableC {
      width: 100%;
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      border-radius: 8px;
      background: var(--info-50, #ebefff);
      > span {
         font-size: 18px;
         font-weight: 700;
      }
      .applicableLst {
         display: flex;
         gap: 24px;
         > label {
            background-color: transparent;
            padding: 0;
            width: auto;
         }
      }
      &.approve {
         .wrapper {
            display: flex;
            align-items: center;
            gap: 24px;
            .inforC {
               display: flex;
               flex-wrap: wrap;
               gap: 12px;
               .infor {
                  display: flex;
                  align-items: center;
                  gap: 4px;
               }
            }
         }
      }
   }
}
.approveBtn {
   width: 150px;
   display: flex;
   height: 32px;
   padding: 0px 32px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   font-size: 14px;
}
.loading {
   position: absolute;
   top: 400px;
   left: 50%;
   transform: translate(50%);
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 8px;
}
