.rightC {
   overflow: hidden;
   flex-grow: 1;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   background: var(--base-white, #fff);
   box-shadow: 8px 0px 16px 0px rgba(0, 0, 0, 0.12);
}
.rightTopC {
   width: 100%;
   display: flex;
   justify-content: space-between;

   padding: 10px 24px;
   align-items: center;
   gap: 10px;
   flex-wrap: wrap;
   background: var(--base-white, #fff);
   .leadNameC {
      display: flex;
      align-items: center;
      gap: 8px;
      min-width: 500px;
      // flex: 1 0 0;
      .leadName{
         min-width: 400px;
      }
      > span {
         color: var(--primary-500, #1a2d3d);
         font-size: 18px;
         font-weight: 700;
      }
      .img-icon {
         width: 20px;
         height: 20px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(184%) hue-rotate(155deg) brightness(87%)
               contrast(89%);
         }
      }
   }
   .btnsTopC {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      min-width: 450px;
      .statusSelect {
         width: 10rem;
      }
      .divider-container {
         display: flex;
         align-items: center;
         height: 100%;
         .divider {
            border: none;
            border-left: 1px solid #c0c0c0;
            height: 40px;
            transform: rotate(180deg);
            margin: 0;
         }
      }

      .btn {
         min-width: 120px;
      }

      .lead-status {
         color: var(--primary-500, #1a2d3d);
         font-weight: 700;
         font-size: 20px;
      }

      .lead-active-status {
         border-radius: 8px;
         background: var(--positive-100, #d0f5e1);
         color: var(--positive-500, #27be69);
      }

      .lead-rejected-status {
         border-radius: 8px;
         background: var(--negative-100, #fcd9de);
         color: var(--negative-500, #f2415a);
      }

      .lead-long-term-status {
         border-radius: 8px;
         background: var(--positive-500, #27be69);
         color: white;
      }
   }
}

.rightBodyC {
   display: flex;
   width: 100%;
   height: calc(100% - 50px);
   flex-grow: 1;
   background: #e6e6e6;
   .noLeadC {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--neutral-500, #8c8f9a);
      font-size: 24px;
   }
}
