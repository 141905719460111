.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .mainStatusC {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 12px;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      background: var(--neutral-50, #f7f8f8);
      .status {
         > div {
            min-width: 205px;
            display: flex;
            padding: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
         }
         &.grayBg {
            > div {
               border-radius: 8px;
               background: var(--primary-50, #e2ebf3);
            }
         }
      }
   }
   .heading {
      font-size: 18px;
      font-weight: 700;
   }
   .assetC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      .asset {
         > div {
            width: 291px;
            display: flex;
            padding: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
         }
         &.grayBg {
            > div {
               border-radius: 8px;
               background: var(--neutral-50, #f7f8f8);
            }
         }
      }
   }
   .rentC {
      width: 100%;
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-radius: 12px;
      background: var(--positive-50, #e6faee);
      .rentMainStatusC {
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         gap: 12px;
         .status {
            > div {
               width: 283px;
               display: flex;
               padding: 8px;
               flex-direction: column;
               justify-content: center;
               align-items: flex-start;
               gap: 4px;
               border-radius: 8px;
               background: var(--base-white, #fff);
            }
         }
      }
      .unitC {
         .unit {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 24px;
            padding-left: 12px;
            .listItem {
               display: flex;
               align-items: center;
               gap: 12px;
               .dot {
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  background-color: #222;
                  border-radius: 50%;
               }
            }

            .data {
               > div {
                  width: 169px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
               }
            }
         }
      }
   }
   .notesC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 24px;
      .SOWC {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         .uploadFileBtn {
            width: 100%;
            border-radius: 8px;
            height: 40px;
            padding: 0px 32px;
            color: var(--info-500, #295bff);
            border-radius: 8px;
            background: var(--info-100, #d6e0ff) !important;
         }
      }
      .noteC {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 8px;
         > textarea {
            width: 100%;
            height: 128px;
         }
      }
      .divider-container {
         display: flex;
         align-items: center;
         height: 100%;
         &.note {
            .divider {
               border: none;
               border-left: 1px solid #c0c0c0;
               height: 158px;
               transform: rotate(180deg);
               margin: 0;
            }
         }
         .divider {
            border: none;
            border-left: 1px solid #c0c0c0;
            height: 240px;
            transform: rotate(180deg);
            margin: 0;
         }
      }
   }
}
.divide {
   width: 100%;
}
.divider-container {
   display: flex;
   align-items: center;
   height: 100%;
   .divider {
      border: none;
      border-left: 1px solid #c0c0c0;
      height: 40px;
      transform: rotate(180deg);
      margin: 0;
   }
}
