.container {
   width: 800px;
   height: 90%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .contentC {
      width: 100%;
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      height: 80%;
      overflow-y: auto;
      .picksC {
         width: 100%;
         display: flex;
         align-items: flex-start;
         gap: 24px;
         .pickC {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            > div {
               width: 100%;
            }
            .expireDate {
               width: 100%;
               > label {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  > div {
                     width: 100%;
                     display: flex;
                     height: 48px;
                     padding: 12px;
                     align-items: center;
                     gap: 12px;
                     border-radius: 12px;
                     border: 1px solid var(--neutral-500, #8c8f9a);
                     background: var(--base-white, #fff);
                     > input {
                        width: 100%;
                     }
                  }
               }
            }
         }
      }
      .titleC {
         width: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;
         gap: 12px;
         border-top: 1px solid #aeafb0;
         padding-top: 15px;
         > span {
            font-size: 20px;
            font-weight: 700;
         }
         .btn {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--info-500, #295bff);
            .img-icon {
               width: 18px;
               height: 18px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(28%) sepia(91%) saturate(2600%) hue-rotate(223deg)
                     brightness(101%) contrast(122%);
               }
            }
         }
      }
      .souceWrapper {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 12px;
         padding-top: 12px;
         border-top: 1px solid #aeafb0;
         > span {
            font-size: 20px;
            font-weight: 700;
         }
         .sourceInfoC {
            width: 100%;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
            .sourceTypeC {
               width: 100%;
               display: flex;
               align-items: center;
               gap: 24px;
            }
            .contactC {
               width: 100%;
               display: flex;
               justify-content: space-between;
               align-items: center;
               .contactInfo {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  .img-icon {
                     width: 20px;
                     height: 20px;
                     > img {
                        width: 100%;
                        height: 100%;
                        filter: brightness(0) saturate(100%) invert(25%) sepia(6%) saturate(242%) hue-rotate(156deg)
                           brightness(93%) contrast(87%);
                     }
                  }
               }
            }
            .loading {
               width: 100%;
               height: 100%;
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               gap: 8px;
            }
            .noContact {
               width: 100%;
               display: flex;
               justify-content: center;
               align-items: center;
               color: var(--neutral-500, #8e8f8f);
            }
         }
      }

      .propertiesAndDocumentC {
         width: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;
         gap: 12px;
         border-top: 1px solid #aeafb0;
         > span {
            font-size: 20px;
            font-weight: 700;
         }
         .btn {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--info-500, #295bff);
            .img-icon {
               width: 18px;
               height: 18px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(28%) sepia(91%) saturate(2600%) hue-rotate(223deg)
                     brightness(101%) contrast(122%);
               }
            }
         }
      }
      .termsC {
         width: 100%;
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         padding-bottom: 40px;
         .simplyTermC {
            width: 100%;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            border-radius: 12px;
            background: var(--neutral-50, #f7f8f8);
            > div {
               width: 100%;
               > span {
                  color: var(--neutral-900, #1a2035);
                  font-weight: none;
                  font-size: 16px;
                  font-weight: 400;
               }
            }
         }
         .customTermsC {
            width: 100%;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            > span {
               flex: 1 0 0;
               font-size: 18px;
               font-weight: 700;
            }
            ul {
               width: 100%;
               border-radius: 12px;
               border: 1px solid var(--neutral-500, #8c8f9a);
               background: var(--base-white, #fff);
               display: flex;
               flex-direction: column;
               padding: 12px;
               align-items: flex-start;
               gap: 12px;
               flex: 1 0 0;
            }
         }
         .additionalTermsC {
            width: 100%;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            .titleAdditionalC {
               display: flex;
               align-items: center;
               gap: 4px;
               .img-icon {
                  display: flex;
                  align-items: center;
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(24%) sepia(3%) saturate(453%) hue-rotate(155deg)
                        brightness(95%) contrast(88%);
                  }
               }
            }
            .additionalTermC {
               background: var(--warning-100, #fff3d1);
               width: 100%;
               display: flex;
               display: flex;
               padding: 8px;
               align-items: flex-start;
               gap: 8px;
               border-radius: 8px;
               background: var(--warning-100, #fff3d1);
               > span {
                  flex-grow: 1;
               }
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(24%) sepia(3%) saturate(453%) hue-rotate(155deg)
                        brightness(95%) contrast(88%);
                  }
               }
            }
         }
      }
      .propertiesC {
         width: 100%;
         height: 100%;
         overflow-y: auto;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         padding: 0px 16px 40px 16px;
         .loading {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            > span {
               margin-left: 10px;
            }
         }
      }
   }
   .btnSave {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 32px;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.64);
      border-radius: 0 0 24px 24px;
      .btn {
         display: flex;
         align-items: center;
         gap: 8px;
         .img-icon {
            width: 18px;
            height: 18px;
            > img {
               width: 100%;
               height: 100%;
            }
         }
      }
   }
}
