.container {
   width: 90%;
   height: 90%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .loading {
         width: 100%;
         display: flex;
         gap: 8px;
         justify-content: center;
         align-items: center;
         height: 100%;
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .contentC {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      .mapC {
         height: 100%;
         flex-grow: 1;
      }
      .zipCodeListC {
         height: 100%;
         width: 500px;
         background: var(--base-white, #fff);
         padding: 16px;
         display: flex;
         flex-direction: column;
         gap: 12px;
         .input_search {
            display: flex;
            min-width: 360px;
            align-items: center;
            gap: 12px;
         }
         .filter {
            width: 170px;
            .loadingFilter {
               display: flex;
               gap: 4px;
            }
            > div {
               > div {
                  outline: none;
               }
            }
         }
         .list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            overflow-y: auto;
            .loading {
               width: 100%;
               height: 100%;
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               gap: 8px;
               > span {
                  margin-left: 10px;
               }
            }
            .noData {
               display: flex;
               justify-content: center;
               align-items: center;
               height: 100%;
               font-size: 20px;
               color: var(--neutral-500, #8e8f8f);
            }
         }
      }
   }
}
