.overviewC {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 24px;
   .statusC {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .detailC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         padding: 12px;
         align-items: center;
         gap: 12px;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
         .detail {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 4px;
            height: 46px;
            width: 280px;
            .title {
               font-weight: 700;
            }
            .data {
               font-size: 14px;
               color: var(--info-500, #295bff);
               &.colorSuccess {
                  background: var(--positive-100, #d0f5e1);
               }
               &.colorProcess {
                  background: var(--info-100, #d6e0ff);
               }
               &.colorProblem {
                  background: var(--negative-100, #fcd9de);
               }
               &.colorCancel {
                  background: var(--neutral-100, #e6e6e6);
               }
               &.colorPending {
                  background: #f9d6ff;
               }
               &.colorPriority {
                  background: var(--warning-200, #ffe59e);
               }
            }
         }
      }
   }
   .contractC {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .contractTitle {
         font-size: 18px;
         font-weight: 700;
      }
      .detailC {
         width: 100%;
         display: flex;
         flex-wrap: wrap;
         padding: 12px;
         align-items: center;
         gap: 12px;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
         .detail {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 4px;
            min-height: 46px;
            width: 450px;
            .title {
               font-weight: 700;
            }
         }
      }
   }

   .buyerC {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .buyerTitle {
         font-size: 18px;
         font-weight: 700;
      }
      .buyerDetailC {
         display: flex;
         flex-wrap: wrap;
         padding: 12px;
         align-items: center;
         gap: 12px;
         background: var(--neutral-50, #f7f8f8);
         .buyerDetail {
            height: 83px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 280px;
         }
      }
   }
   .agentC {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .agentTitle {
         font-size: 18px;
         font-weight: 700;
      }
      .agent {
         display: flex;
         flex-direction: column;
         padding: 12px;
         gap: 12px;
         background: var(--neutral-50, #f7f8f8);
         .agentDetailC {
            display: flex;
            flex-wrap: wrap;
            padding: 12px;
            gap: 12px;

            // align-items: center;
            .agentDetail {
               width: 280px;
               // height: 83px;
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
               // gap: 4px;
               // flex: 1 0 0;
               padding: 8px;
               &.isOnly {
                  border-radius: 8px;
                  background: var(--base-white, #fff);
               }
            }
         }
      }
   }

   .termsHeading {
      font-weight: 700;
   }
   .termsC {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0px;
      align-items: center;
      gap: 12px;
      border-radius: 8px;
      .terms {
         min-width: 280px;
         > div {
            padding: 8px;
         }
      }
      .isGreyBackground {
         min-width: 280px;
         > div {
            flex: 0;
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
            padding: 8px;
         }
      }
   }

   .customTermsC {
      width: 100%;
      display: flex;
      gap: 12px;
      flex-direction: column;
      > span {
         flex: 1 0 0;
         font-weight: 700;
      }
      ul {
         list-style: disc inside;
      }
      .additionalC {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 12px;
         > span {
            padding: 8px;
            border-radius: 8px;
            background: var(--warning-100, #fff3d1);
         }
      }
   }
   .container {
      width: 100%;
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-radius: 16px;
      border: 1px solid var(--neutral-200, #d4d5d5);
      background: var(--base-white, #fff);
      .addPropertyC {
         display: flex;
         align-items: center;
         width: 100%;
         gap: 24px;
         > span {
            flex-grow: 1;
            font-size: 18px;
            font-weight: 700;
         }
         .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #295bff;
            font-size: 14px;
            .img-icon {
               display: flex;
               align-items: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                     brightness(101%) contrast(102%);
               }
            }
         }
      }

      .tableContainer {
         width: 100%;
         border-radius: 12px;
         border: 1px solid var(--neutral-500, #8e8f8f);
         overflow: hidden;
      }
   }
}

.divide {
   width: 100%;
}

.noInfo {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--neutral-500, #8e8f8f);
}
