.container {
   width: 100%;
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   border-radius: 16px;
   background: var(--positive-50, #e6faee);
}
