.switch-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 12px;
   > span {
      flex-grow: 1;
   }
}
.switch {
   position: relative;
   display: inline-block;
   width: 60px;
   height: 34px;

   input {
      opacity: 0;
      width: 0;
      height: 0;
   }

   .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
         position: absolute;
         content: "";
         height: 26px;
         width: 26px;
         left: 4px;
         bottom: 4px;
         background-color: white;
         transition: 0.4s;
         border-radius: 50%;
      }
   }

   input:checked + .slider {
      background: var(--positive-500, #27be69);
   }

   input:checked + .slider:before {
      transform: translateX(26px);
   }
}
