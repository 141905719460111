.container {
   display: flex;
   width: 800px;
   height: 92%;
   flex-direction: column;
   align-items: center;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .userInfoC {
         display: flex;
         align-items: center;
         gap: 12px;
         flex: 1 0 0;
         .avt {
            width: 40px;
            height: 40px;
            border: none;
            > img {
               width: 100%;
               height: 100%;
               border-radius: 50%;
            }
         }
         .nameC {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            font-size: 14px;
            .role {
               color: var(--neutral-600, #68696a);
            }
         }
      }
      .time {
         color: var(--neutral-600, #68696a);
         font-size: 14px;
      }
      .editBtn {
         color: var(--info-500, #295bff);
         font-size: 14px;
         font-weight: 700;
         width: 80px;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 4px;
         .img-icon-edit {
            width: 16px;
            height: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(61%) saturate(3820%) hue-rotate(225deg)
                  brightness(102%) contrast(101%);
            }
         }
      }
      .btn {
         color: var(--info-500, #295bff);
         text-align: center;
         font-size: 14px;
         font-weight: 700;
         width: 100px;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .contentC {
      width: 100%;
      height: 86%;
      display: flex;
      padding: 0px 32px 100px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .postC {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         .post {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            .title {
               font-size: 18px;
               font-weight: 700;
            }
         }
         .keywordC {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            .img-icon {
               width: 20px;
               height: 20px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(29%) hue-rotate(131deg)
                     brightness(87%) contrast(90%);
               }
            }
            .keywordGroupC {
               width: 100%;
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               gap: 4px;
               .keyword {
                  display: flex;
                  padding: 2px 6px;
                  justify-content: center;
                  align-items: center;
                  gap: 4px;
                  border-radius: 4px;
                  background: var(--neutral-100, #e6e6e6);
                  font-size: 14px;
               }
            }
         }
         .divider {
            width: 100%;
            border: none;
            border-left: 1px solid #c0c0c0;
         }
         .numberComs {
            color: var(--neutral-600, #68696a);
            font-size: 14px;
         }
      }
      .commentGroupC {
         width: 100%;
         height: 100%;
         overflow-y: auto;
         gap: 24px;
         .commentList {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            .load-more {
               font-weight: 600;
               width: 100%;
               text-align: center;
               cursor: pointer;
            }

            .load-more.hidden {
               display: none;
            }

            &.hidden {
               display: none;
            }
         }
         .spinnerC {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            flex-direction: column;
            justify-content: center;
         }
         .noComment {
            text-align: center;
            width: 100%;

            color: var(--neutral-500, #8e8f8f);
         }
         .commentC {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            .avt {
               width: 40px;
               height: 40px;
               border: none;
               > img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
               }
            }
            .comment {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 8px;
               flex-grow: 1;
               width: 70%;
               .info {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 2px;
                  font-size: 14px;
                  > p {
                     color: var(--neutral-600, #68696a);
                  }
               }
               .reply {
                  color: var(--info-500, #295bff);
                  font-size: 14px;
                  cursor: pointer;
               }
            }
            .time {
               color: var(--neutral-600, #68696a);
               font-size: 14px;
            }
            .img-icon {
               width: 24px;
               height: 24px;
               > img {
                  width: 100%;
                  height: 100%;
               }
            }
         }
      }
   }
   .postCommentC {
      width: 100%;
      height: 150px;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      padding: 32px;
      align-items: flex-start;
      gap: 12px;
      border-radius: 0 0 24px 24px;
      background: rgba(255, 255, 255, 0.64);
      .avt {
         width: 48px;
         height: 48px;
         border: none;
         > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
         }
      }
      .input_search {
         width: 305px;

         flex-grow: 1;
         .inputMentionC {
            font-size: 16px;
            width: 100%;
            height: 100px;

            font-size: 16px;
            border-radius: 12px;
            border: 1px solid var(--neutral-500, #8c8f9a);
            background: var(--base-white, #fff);
            padding: 2px 12px;
            overflow-y: auto;
            > div {
               height: 100%;
               > div {
                  border: 0 !important;
                  height: 100%;
                  > strong {
                     color: transparent;
                  }
               }
               > textarea {
                  overflow: auto !important;
                  line-height: 1.4;
                  padding: 2px 12px;
                  height: 100%;
               }
            }
            > div:nth-child(2) {
               overflow-y: auto;
               margin-top: 20px;
               width: 200px;
               border: 1px solid var(--neutral-500, #8c8f9a);
               padding: 5px;
            }
            .mentionC {
               background-color: #cee4e5;
               height: 100%;
            }
            > div {
               width: 100%;
            }
         }
      }
      .btn {
         cursor: pointer;
         width: 50px;
         height: 50px;
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 10px;
         border-radius: 50%;
         .img-icon {
            width: 24px;
            height: 24px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(484%) hue-rotate(174deg)
                  brightness(115%) contrast(100%);
            }
         }
      }
   }
}
