.container {
   width: 100%;
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   border-radius: 16px;
   border: 1px solid var(--neutral-200, #d4d5d5);
   background: var(--base-white, #fff);
   .addPropertyC {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 24px;
      > span {
         flex-grow: 1;
         font-size: 18px;
         font-weight: 700;
      }
      .btn {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: #295bff;
         font-size: 14px;
         .img-icon {
            display: flex;
            align-items: center;
            width: 16px;
            height: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                  brightness(101%) contrast(102%);
            }
         }
      }
   }
}
.tableContainer {
   width: 100%;
   border-radius: 12px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   overflow: hidden;
}

.spinnerC {
   width: 100%;
   display: flex;
   justify-content: center;
   gap: 8px;
}
.noContact {
   margin-left: 260px;
   padding: 10px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--neutral-500, #8e8f8f);
}
