.cardC {
   width: 385px;
   height: 463px;
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   border-radius: 16px;
   border: 1px solid var(--neutral-100, #e6e6e9);
   background: var(--base-white, #fff);
   box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
   cursor: pointer;
}
.property-img {
   position: relative;
   border-radius: 12px;
   background: var(--neutral-100, #e6e6e6);
   height: 220px;
   width: 100%;
   .imgC {
      width: 100%;
      height: 100%;
      > img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .state {
      position: absolute;
      bottom: 12px;
      right: 12px;
   }
   .statusPill {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0px 12px;
      border-radius: 40px;
      background: var(--base-white, #fff);
   }
   .statusC {
      position: absolute;
      display: flex;
      bottom: 12px;
      left: 12px;
      height: 32px;
      &:has(> :nth-child(2)):not(:has(> :nth-child(3))) {
         .statusPill:first-child {
            border-radius: 0;
         }
         .statusPill:last-child {
            border-radius: 0 40px 40px 0;
         }
      }
   }
}

.detailC {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   width: 100%;
   .numbersC {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      width: 100%;
      justify-content: space-between;
   }
   .addressC {
      min-height: 55px;
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      > span {
         color: var(--primary-500, #1a2d3d);
         font-size: 16px;
      }
   }
   .inforC {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      justify-content: space-between;
   }
}

.price {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 2px;
   > span {
      color: var(--neutral-700, #424445);
      font-size: 14px;
   }
   .number-price {
      font-size: 24px;
      font-weight: 700;
   }
}

.icon {
   width: 20px;
   height: 20px;
   > img {
      width: 20px;
      height: 20px;
      filter: brightness(0) saturate(100%) invert(25%) sepia(8%) saturate(149%) hue-rotate(155deg) brightness(95%)
         contrast(91%);
   }
}

.unit {
   display: flex;
   align-items: center;
   gap: 4px;
}
