.topCenterC {
   flex-grow: 1;
   background-image: url("https://dyaj6jwzih5dm.cloudfront.net/sos/assets/bg_editor.png");
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   align-items: center;
   justify-content: center;
   overflow-x: auto;
   .widgetC {
      height: 80%;
      position: relative;
      z-index: 1;
   }
}
