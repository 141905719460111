.container {
   display: flex;
   padding: 16px 16px 80px 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   flex: 1 0 0;
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
}
