.default {
   width: 480px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   background-color: #fff;
   padding: 32px;
   border-radius: 24px;
   min-width: 500px;
   .defaultTopC {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
   }
   .defaultBotC {
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      .cancleBtn {
         width: 128px;
         display: flex;
         gap: 8px;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 8px;
         border-radius: 12px;
         border: 1px solid var(--negative-500, #f2415a);
         > button {
            width: 128px;
            outline: none;
            color: var(--negative-500, #f2415a);
            text-align: center;
            font-weight: 700;
            &:hover {
               background-color: var(--negative-500, #f2415a);
               color: #fff;
            }
         }
      }
      > button {
         padding: 0px 32px;
      }
   }
}
