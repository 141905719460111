.container {
   display: flex;
   padding: 24px 24px 48px 24px;
   align-items: flex-start;
   gap: 24px;
   .menuC {
      display: flex;
      min-width: 310px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
   }
   .content {
      width: 100%;
      display: flex;
      justify-content: center;
   }
}
