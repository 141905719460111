.container {
   display: flex;
   max-width: 320px;
   padding: 16px;
   align-items: center;
   gap: 16px;
   border-radius: 12px;
   border: 2px solid var(--neutral-50, #f7f8f8);
   background: var(--base-white, #fff);
   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
   &.selected {
      border-radius: 12px;
      border: 2px solid var(--positive-500, #27be69);
      background: var(--positive-50, #e6faee);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
   }
   .leftC {
      cursor: pointer;
      display: flex;
      gap: 16px;
      .addressImg {
         cursor: pointer;
         min-width: 80px;
         height: 80px;
         border-radius: 8px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
   }

   .img-icon {
      cursor: pointer;
      min-width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(27%) sepia(84%) saturate(3530%) hue-rotate(224deg) brightness(101%)
            contrast(102%);
      }
   }
}
