.leftC {
   display: flex;
   width: 600px;
   flex-direction: column;
   align-items: flex-start;
   position: relative;
   background: var(--base-white, #fff);
   box-shadow: 8px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.leftBodyC {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: calc(100% - 50px);
   background: var(--base-white, #fff);
   .filterTopC {
      display: flex;
      min-height: 50px;
      background: var(--base-white, #fff);
      border-bottom: 1px solid var(--neutral-400, #aeafb0);
      padding: 0px 24px;
      justify-content: space-between;
      align-items: center;
      .allLeadC {
         font-size: 18px;
      }
      .filterAssignee {
         > button {
            outline: none;
            gap: 4px;
         }
         .img-icon {
            width: 20px;
            height: 20px;
            > img {
               width: 100%;
               height: 100%;
            }
         }
      }
   }
}

.leadsListC {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   overflow-y: auto;
   .spinnerC {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
   }
   .noDataC {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 25%;
      color: var(--neutral-500, #8e8f8f);
      font-size: 20px;
   }
}

.statusGroup {
   width: 100%;
   border-bottom: 1px solid var(--neutral-400, #aeafb0);
   position: relative;
   flex-grow: 1;
   transition: all 0.6s ease;
   -webkit-user-select: none;
   user-select: none;
   max-height: 2rem;
   > div {
      position: sticky;
      top: 0px;
      display: flex;
      gap: 8px;
      align-items: center;
      height: 2rem;
      padding-left: 1rem;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.5);
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      > img {
         width: 1.2rem;
         height: 1.2rem;
         transform: rotate(-90deg);
         transition: all 0.3s ease;
      }
      > div {
         font-size: 0.8rem;
         > span:first-child {
            font-weight: 600;
            padding: 0.2rem 0.4rem;
            border-radius: 1rem;
         }
      }
   }
   &.isOpen {
      overflow-y: auto;
      max-height: 100%;
      > div {
         > img {
            transform: rotate(0deg);
         }
      }
   }
}
