.container {
   width: 800px;
   height: 90%;
   display: flex;
   flex-direction: column;
   border-radius: 24px;
   background: var(--base-white, #fff);
   position: relative;
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      .img-icon {
         width: 32px;
         height: 32px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg) brightness(92%)
               contrast(90%);
         }
      }
      > span {
         flex: 1;
         white-space: nowrap;
         font-size: 20px;
         font-weight: 700;
      }
      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .tabs {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 32px 32px;
   }
   .contentC {
      width: 100%;
      height: 84%;
      overflow-y: auto;
      display: flex;
      padding: 2px 32px 80px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .sourceType {
         width: 100%;
         > div {
            padding: 0;
         }
      }
      .headingAndBtn {
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         > span {
            font-size: 18px;
            font-weight: 700;
            flex: 1;
         }
         .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: var(--info-500, #295bff);
            font-size: 14px;
            .img-icon {
               display: flex;
               align-items: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(40%) sepia(98%) saturate(6167%) hue-rotate(227deg)
                     brightness(108%) contrast(101%);
               }
            }
         }
      }
      .sourceContactGroupC {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 16px;
         .sourceContactInfoC {
            width: 100%;
            display: flex;
            padding: 16px;
            align-items: flex-start;
            gap: 12px;
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
            > div {
               width: 215px;
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: flex-start;
               gap: 8px;
            }
            .img-icon {
               cursor: pointer;
               width: 24px;
               height: 24px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(27%) sepia(73%) saturate(2973%) hue-rotate(337deg)
                     brightness(125%) contrast(90%);
               }
            }
         }
         .loading {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
         }
         .noContact {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--neutral-500, #8e8f8f);
            border-radius: 8px;
            background: var(--neutral-50, #f7f8f8);
         }
      }

      .divide {
         width: 100%;
      }
      .noProperty {
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         color: var(--neutral-500, #8e8f8f);
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
      }
      .propertyInforC {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
         border-radius: 8px;
         background: var(--neutral-50, #f7f8f8);
         .titleAndRemoveBtn {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            > span {
               flex-grow: 1;
               font-size: 18px;
               font-weight: 700;
            }
            .img-icon {
               cursor: pointer;
               width: 24px;
               height: 24px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(27%) sepia(73%) saturate(2973%) hue-rotate(337deg)
                     brightness(125%) contrast(90%);
               }
            }
         }
         .propertyAddress {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            > div {
               width: 100%;
               > span {
                  font-size: 16px;
               }
            }
         }
         .askingPrice {
            width: 100%;
            > label {
               display: flex;
               flex-direction: column;
               gap: 8px;
               > div {
                  width: 100%;
                  display: flex;
                  height: 48px;
                  padding: 12px;
                  align-items: center;
                  gap: 12px;
                  border-radius: 12px;
                  border: 1px solid var(--neutral-500, #8c8f9a);
                  background: var(--base-white, #fff);
                  > input {
                     width: 100%;
                  }
               }
            }
         }

         .propertyContactC {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .propertyContactInfoC {
               width: 100%;
               display: flex;
               padding: 16px;
               align-items: flex-start;
               gap: 12px;
               border-radius: 8px;
               border: 1px solid var(--neutral-500, #8e8f8f);
               background: var(--neutral-50, #f7f8f8);
               > div {
                  width: 215px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 8px;
               }
               .img-icon {
                  cursor: pointer;
                  width: 24px;
                  height: 24px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(27%) sepia(73%) saturate(2973%) hue-rotate(337deg)
                        brightness(125%) contrast(90%);
                  }
               }
            }
         }
         .unitGroupC {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .unitInfoC {
               width: 100%;
               display: flex;
               padding: 16px;
               flex-direction: column;
               align-items: flex-start;
               gap: 16px;
               border-radius: 12px;
               border: 1px solid var(--neutral-500, #8e8f8f);
               background: var(--base-white, #fff);
               .headingAndBtn {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  > span {
                     font-size: 18px;
                     font-weight: 700;
                     flex: 1;
                  }
                  .img-icon {
                     cursor: pointer;
                     width: 24px;
                     height: 24px;
                     > img {
                        width: 100%;
                        height: 100%;
                        filter: brightness(0) saturate(100%) invert(27%) sepia(73%) saturate(2973%) hue-rotate(337deg)
                           brightness(125%) contrast(90%);
                     }
                  }
               }
               .bedAndBathC {
                  width: 100%;
                  display: flex;
                  align-items: flex-start;
                  gap: 24px;
               }
            }
         }
      }
   }
   .footerBtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      background-color: white;
      .btn {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: var(--info-500, #295bff);
         border: solid 1px var(--info-500, #295bff);
         font-size: 14px;
         .img-icon {
            display: flex;
            align-items: center;
            width: 16px;
            height: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(40%) sepia(98%) saturate(6167%) hue-rotate(227deg)
                  brightness(108%) contrast(101%);
            }
         }
      }
   }
   .btnSave {
      align-items: center;
      border-radius: 0 0 24px 24px;
      .btn {
         display: flex;
         padding: 12px 48px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: var(--base-white, #fff);
      }
   }
}

.bulkC {
   width: 100%;
   height: 84%;
   overflow-y: auto;
   display: flex;
   padding: 2px 32px 32px 32px;
   flex-direction: column;
   align-items: flex-start;
   gap: 16px;
   .sourceType {
      width: 100%;
      > div {
         padding: 0;
      }
   }
   .reading-file-button {
      width: 100%;
      height: 56px;
   }
   .btnSave {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;
      .btn {
         display: flex;
         padding: 12px 48px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: var(--base-white, #fff);
      }
   }
}

.drag-and-drop-file {
   width: 100%;
   .file-uploader {
      width: 100%;
      height: 100%;
      .drag-and-drop-file-inner {
         width: 100%;
         height: 100%;
         display: flex;
         padding: 24px;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 24px;
         align-self: stretch;
         border-radius: 16px;
         border: 3px dashed var(--neutral-600, #68696a);
         background: var(--base-white, #fff);
         .drag-and-drop-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .img-icon {
               width: 80px;
               height: 80px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(34%) sepia(86%) saturate(5894%) hue-rotate(226deg)
                     brightness(105%) contrast(101%);
               }
            }
            > span {
               font-size: 20px;
            }
         }
         > span {
            font-size: 20px;
         }
      }
   }
}

.drag-and-drop-file-name {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 12px;
   width: 100%;
   height: 80px;
   font-size: 20px;
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
}

.drag-and-drop-file-status {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 12px;
   align-self: stretch;
   width: 100%;
   border-radius: 12px;
   background: var(--negative-50, #feecee);
   padding: 48px;
   .img-icon {
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(32%) sepia(40%) saturate(3286%) hue-rotate(329deg) brightness(104%)
            contrast(90%);
      }
   }
   > span {
      font-size: 20px;
      color: var(--negative-500, #f2415a);
   }
}
