.missingDataC {
   display: flex;
   min-width: 520px;
   height: 100%;
   overflow-y: auto;
   padding: 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
   .dataPointC {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      > div {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 8px;
         > span {
            color: var(--neutral-700, #424445);
            flex-grow: 1;
         }
         > div {
            display: flex;
            align-items: center;
            gap: 8px;
            .datapoint {
               width: 200px;
            }
            > button {
               width: 20px;
               height: 20px;
               img {
                  width: 20px;
                  height: 20px;
                  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(992%) hue-rotate(143deg)
                     brightness(88%) contrast(88%);
               }
               &.verified {
                  > img {
                     filter: brightness(0) saturate(100%) invert(57%) sepia(15%) saturate(2181%) hue-rotate(93deg)
                        brightness(106%) contrast(80%);
                  }
               }
            }
         }
      }
      > ul {
         display: flex;
         flex-direction: column;
         gap: 2px;
         .warningC {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            > img {
               width: 20px;
               height: 20px;
            }
            > span {
               padding-top: 3px;
               font-size: 12px;
               font-weight: 400;
               color: var(--neutral-600, #6b6c6f);
            }
         }
      }
   }
}

.headingC {
   width: 100%;
   display: flex;
   align-items: center;
   gap: 8px;
   > span {
      flex-grow: 1;
      font-size: 18px;
      font-weight: 700;
   }
}
.btn {
   display: flex;
   align-items: center;
   gap: 8px;
   color: var(--info-500, #295bff);
   .img-icon {
      width: 18px;
      height: 18px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(28%) sepia(91%) saturate(2600%) hue-rotate(223deg) brightness(101%)
            contrast(122%);
      }
   }
}
