.contentC {
   display: flex;
   flex-direction: column;
   gap: 4px;
   justify-content: space-between;
   -webkit-user-select: none;
   user-select: none;
   .titleC {
      display: flex;
      align-items: center;
      padding-left: 8px;
      gap: 4px;
      > button {
         // background-color: red;
         height: 20px;
         font-style: italic;
         font-size: 12px;
         color: var(--info-500, #295bff);
         &:hover {
            text-decoration: underline;
         }
      }
   }
   .valueC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      padding: 3px 8px;
      height: 24px;
      .icon {
         height: 20px;
         width: 20px;
      }
      .value {
         width: 100%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         .overridden {
            text-decoration: line-through;
            white-space: nowrap;
         }
      }
      .edit-icon {
         width: 20px;
         cursor: pointer;
         height: 20px;
         opacity: 0;
         > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(31%) sepia(73%) saturate(5027%) hue-rotate(224deg)
               brightness(100%) contrast(103%);
         }
      }
      // .input_select {
      //    // width: 200px;
      // }
      &:hover {
         background: var(--info-50, #ebefff);
         .edit-icon {
            opacity: 1;
         }
      }
      .spinner {
         width: 20px;
         height: 20px;
      }
      .img-icon {
         width: 20px;
         height: 20px;
         opacity: 1;
         &.enabled {
            filter: brightness(0) saturate(100%) invert(29%) sepia(85%) saturate(4699%) hue-rotate(225deg)
               brightness(103%) contrast(101%);
         }
         > img {
            width: 100%;
            height: 100%;
         }
      }
   }
   .no-padding {
      padding: 0;
   }
}
.isMocked {
   background-color: rgba(255, 0, 0, 0.1);
}
.type-bold-title {
   .titleC {
      font-weight: 700;
   }
}
.type-bold-value {
   .valueC {
      font-size: 20px;
      font-weight: 700;
   }
}
.type-bold-value-table,
.type-only-value-table {
   .valueC {
      font-size: 20px;
      font-weight: 700;
      > div {
         > span {
            color: var(--neutral-700, #424445);
            font-size: 16px;
            font-weight: 700;
         }
      }
   }
}
.type-only-value {
   .titleC {
      display: none;
   }
}
.type-bold-only-value {
   .titleC {
      display: none;
   }
   .valueC {
      font-size: 20px;
      font-weight: 700;
   }
}

.input_form {
   width: 100%;
   padding: 3px 8px;
   display: flex;
   align-items: center;
   gap: 4px;
   border-radius: 8px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   > input {
      width: 100%;
      font-size: 20px;
      font-weight: 700;
   }
}

.img-icon {
   width: 20px;
   height: 20px;
   > img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(26%) sepia(7%) saturate(184%) hue-rotate(155deg) brightness(87%)
         contrast(89%);
   }
}
