.pdOverview {
   display: flex;
   flex-direction: column;
   gap: 24px;
   padding-bottom: 20px;
   flex-grow: 1;
   .pdoWarnings {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
   }
   .pdoLargeDP {
      display: flex;
      gap: 48px;
      flex-wrap: wrap;
   }
   .pdoSmallDP {
      padding: 16px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      > div {
         min-width: 200px;
      }
      // justify-content: space-around;
   }
   .pdoUnits {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      > button {
         padding: 8px 48px;
      }
   }
}

.largeDataPoint {
   padding: 8px;
   width: 240px;
}
.smallDataPoint {
   width: 160px;
}
.unitData {
   display: flex;
   flex-wrap: wrap;
   gap: 24px;
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
   padding: 16px;
}
.detailSourcesC {
   width: 100%;
   display: flex;
   padding: 16px;
   align-items: center;
   gap: 16px;
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
   .sourceInforC {
      display: flex;
      align-items: center;
      gap: 8px;
   }
}

.fileUpload {
   .uploadFileBtn {
      width: 100%;
      border-radius: 8px;
      height: 40px;
      padding: 0px 32px;
      color: var(--info-500, #295bff);
      border-radius: 8px;
      background: var(--info-100, #d6e0ff) !important;
   }
}

.inspectionC {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   .title {
      font-size: 18px;
   }
}

.SOWC {
   width: 432px;
   display: flex;
   height: 240px;
   padding: 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   border-radius: 8px;
   background: var(--neutral-50, #f7f8f8);
   .uploadFileBtn {
      width: 100%;
      border-radius: 8px;
      height: 40px;
      padding: 0px 32px;
      color: var(--info-500, #295bff);
      border-radius: 8px;
      background: var(--info-100, #d6e0ff) !important;
   }
   .linkC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
      .linkUploadC {
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 12px;
         background-color: rgba(255, 0, 0, 0.184);
         .img-icon {
            display: flex;
            align-items: center;
            img {
               filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                  brightness(92%) contrast(96%);
            }
         }
         .linkIconsC {
            display: flex;
            align-items: center;
            gap: 4px;
            .img-icon {
               display: flex;
               align-items: center;
               &.clear {
                  > img {
                     filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(3368%) hue-rotate(328deg)
                        brightness(97%) contrast(95%);
                  }
               }
               > img {
                  filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                     brightness(92%) contrast(96%);
               }
            }
         }
         > span {
            max-width: 310px;
         }
      }
   }
}
