.container {
   flex-grow: 1;
   display: flex;
   padding: 16px 16px 80px 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
   .zipCodeSelectionC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .zipDropdownC {
         padding: 12px;
         gap: 12px;
         border-radius: 50%;
         border: 1px solid var(--neutral-500, #8c8f9a);
         background: var(--base-white, #fff);
         border-radius: 12px;
         width: 100%;
      }
   }
   .askingPriceC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      > span {
         font-size: 18px;
      }
      .bottom {
         width: 100%;
         display: flex;
         align-items: center;
         gap: 12px;
         .priceC {
            width: 100%;
            border-radius: 12px;
            border: 1px solid var(--neutral-500, #8c8f9a);
            background: var(--base-white, #fff);
            height: 48px;
            padding: 12px;
            > label {
               width: 100%;
               > div {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  > div {
                     display: flex;
                     align-items: center;
                  }
                  > input {
                     width: 100%;
                     height: 100%;
                     padding-left: 12px;
                  }
               }
            }
         }
      }
   }
}
.wrapper {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   > span {
      font-size: 18px;
   }
   .options {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      flex-wrap: wrap;
   }
}
