.offerContentC {
   width: 100%;
   height: 100%;
   display: flex;
   padding: 24px;
   align-items: flex-start;
   gap: 12px;
   border-radius: 16px;
   background: var(--base-white, #fff);
   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
}
.detailsC {
   width: 100%;
   overflow-x: auto;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 16px;
   flex: 1 0 0;
}

.quickInfoContainer {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 12px;
   flex-wrap: wrap;
   .statusC {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px;
   }
}
.quickInfoWrapper {
   width: 100%;
   height: 40px;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: 16px;
   .infoC {
      display: flex;
      align-items: center;
      gap: 8px;
      &.fs-18 {
         font-size: 18px;
      }
      .nextActions {
         min-width: 100px;
         > div {
            > div {
               gap: 0px;
            }
         }
      }
   }
}

.termsC {
   width: 100%;
   display: flex;
   padding: 16px;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
   .termsHeaderC {
      display: flex;
      gap: 8px;
      > b {
         flex: 1 0 0;
         font-size: 18px;
         font-weight: 700;
      }
   }
   .termsGroupC {
      width: 100%;
      display: flex;
      padding: 8px 0px;
      gap: 24px;
      flex-wrap: wrap;
      > div {
         display: flex;
         width: 220px;
         height: 68px;
         padding: 8px;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 4px;
         border-radius: 8px;
         background: var(--base-white, #fff);
      }
   }
}

.noTerm {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 50px;
   color: var(--neutral-500, #8c8f9a);
   border-radius: 12px;
   background: var(--neutral-50, #f7f8f8);
}

.customTermsC {
   width: 100%;
   border-top: 1px solid #aeafb0;
   display: flex;
   gap: 12px;
   flex-direction: column;
   padding-top: 12px;
   > span {
      flex: 1 0 0;
      font-size: 18px;
      font-weight: 700;
   }
   ul {
      list-style: disc inside;
   }
}

.additionalC {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 12px;
   > span {
      padding: 8px;
      border-radius: 8px;
      background: var(--warning-100, #fff3d1);
   }
}

.propertiesC {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   .title {
      font-size: 18px;
      font-weight: 700;
   }
   .listDocsC {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 48px;
      .docs {
         display: flex;
         align-items: flex-start;
         gap: 12px;
         flex-direction: column;
      }
      .filesC {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         align-items: flex-start;
         gap: 12px;
         border-radius: 12px;
         background: var(--neutral-50, #f7f8f8);
         > span {
            font-size: 18px;
            font-weight: 700;
         }
         .input_search {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 12px;
         }
      }
   }
}

.buttonsC {
   width: 170px;
   height: 100%;
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   .createEmail {
      color: var(--info-500, #295bff);
   }
   .sendOffer {
      background: var(--positive-500, #27be69);
   }
   > div {
      width: 100%;
   }
   .btnRight {
      color: white;
      width: 100%;
      font-size: 12px;
      > button {
         width: 100%;
         height: 35px;
      }
   }
   .previewC {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px;
      flex-direction: column;
      gap: 8px;
      border-radius: 8px;
      background: var(--neutral-50, #f7f8f8);
      .headingC {
         width: 100%;
         display: flex;
         justify-content: space-between;
         gap: 16px;
         > span {
            flex: 1;
         }
         .img-icon {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(23%) sepia(7%) saturate(201%) hue-rotate(155deg)
                  brightness(99%) contrast(87%);
            }
         }
      }
      .imgOfferC {
         width: 136px;
         height: 192px;
         border-radius: 4px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
   }
}

.fileC {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 12px;
   background-color: white;
   .fileNameC {
      display: flex;
      align-items: center;
      gap: 12px;
   }
   .img-icon {
      display: flex;
      align-items: center;
      img {
         filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg) brightness(92%)
            contrast(96%);
      }
   }
   > span {
      max-width: 310px;
   }
}
