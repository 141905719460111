.container {
   display: flex;
   padding: 8px 0px;
   gap: 24px;
   flex-wrap: wrap;
   > div {
      display: flex;
      width: 320px;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      // background: var(--base-white, #fff);
      > div:last-child {
         > div {
            width: 100%;
         }
      }
   }
}
