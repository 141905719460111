.topC {
   height: 50px;
   display: flex;
   padding: 8px 24px;
   align-items: center;
   gap: 8px;
   border-bottom: 1px solid var(--neutral-400, #aeafb0);
   background: var(--base-white, #fff);
   position: relative;
   .title {
      font-size: 18px;
      font-weight: 700;
   }
   .btn {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--info-500, #295bff);
      font-size: 14px;
      font-weight: 700;
      .img-icon {
         width: 16px;
         height: 16px;
         > img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(29%) sepia(85%) saturate(4699%) hue-rotate(225deg)
               brightness(103%) contrast(101%);
         }
      }
   }
}

.container {
   position: absolute;
   top: 0;
   left: 0;
   height: calc(100vh - 64px); //!
   width: 100%;
   display: flex;
   flex-direction: column;
   // background-color: red;
   opacity: 0;
   &.expanded {
      z-index: 1;
      animation: expandTitleCAnimation 0.3s ease-in forwards;
   }

   &.collapsed {
      animation: collapseTitleCAnimation 0.3s ease-out forwards;
   }
   .inputSearch {
      width: 470px;
      > label {
         gap: 0;
      }
   }
   .sliderPropertyListC {
      position: absolute;
      width: 100%;
      height: 350px;
      left: 0;
      top: 0;
      display: flex;
      padding: 5px 24px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      background: var(--base-white, #fff);
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
      .titleC {
         display: flex;
         align-items: center;
         gap: 8px;
      }
   }

   .background {
      flex-grow: 1;
      background-color: #7b7c7c7c;
   }
}
.noData {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
   font-size: 20px;
   font-weight: 700;
   color: var(--neutral-500, #7b7c7c);
}

@keyframes expandTitleCAnimation {
   0% {
      opacity: 0;
      transform: translateZ(-10px);
   }
   100% {
      opacity: 1;
      transform: translateZ(0);
   }
}

@keyframes collapseTitleCAnimation {
   0% {
      opacity: 1;
      transform: translateZ(0);
   }
   100% {
      opacity: 0;
      transform: translateZ(-10px);
   }
}
