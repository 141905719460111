.contentC {
   background-color: #fff;
   height: 90%;
   width: 90%;
   padding: 32px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   position: relative;

   .headingC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      .addressC {
         display: flex;
         align-items: center;
         gap: 8px;
         flex: 1 0 0;
         > span {
            color: var(--primary-500, #1a2d3d);
            font-weight: 700;
            font-size: 18px;
         }
         .img-icon {
            height: 16px;
            width: 16px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(44%) sepia(6%) saturate(105%) hue-rotate(169deg)
                  brightness(90%) contrast(87%);
            }
         }
      }
      .close-btn {
         width: 32px;
         height: 32px;
         cursor: pointer;
         > img {
            height: 100%;
            width: 100%;
         }
      }
   }
   .bodyC {
      width: 100%;
      display: flex;
      gap: 32px;
      overflow-y: auto;
      .bodyLeft {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 24px;
         flex-grow: 1;
         position: relative;

         .tabDetail {
            width: 100%;
            height: 100%;
         }
      }
      .bodyRight {
         height: 100%;
         display: flex;
         flex-direction: column;
         min-width: 520px;
         gap: 24px;
         .eventC {
            padding: 8px 16px;
            display: flex;
            flex-direction: column;
            min-height: 320px;
            gap: 16px;
            border-radius: 12px;
            border: 1px solid var(--neutral-500, #8e8f8f);
            .eventHeading {
               display: flex;
               align-items: center;
               justify-content: center;
               gap: 8px;
               .img-icon {
                  width: 20px;
                  height: 20px;
                  > img {
                     width: 100%;
                     height: 100%;
                     filter: brightness(0) saturate(100%) invert(44%) sepia(6%) saturate(105%) hue-rotate(169deg)
                        brightness(90%) contrast(87%);
                  }
               }
            }
            .eventList {
               display: flex;
               flex-direction: column;
               gap: 8px;
               flex: 1 0 0;
               height: 100%;
               overflow-y: auto;
               .event {
                  display: flex;
                  padding-right: 12px;
                  gap: 10px;
                  border-radius: 8px;
                  .eventColor {
                     width: 6px;
                     border-radius: 8px 0 0 8px;
                  }
               }
               .eventDetail {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  flex: 1 0 0;
                  padding: 12px 0;
                  .eventName {
                     font-weight: 700;
                     flex: 1 0 0;
                  }
               }
            }
         }
      }
   }
}
.loading {
   position: absolute;
   top: 400px;
   left: 50%;
   transform: translate(-50%);
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 8px;
}
.tab {
   width: 100%;
   height: 80px;
   overflow-x: auto;
   overflow-y: hidden;
   > ul {
      gap: 24px;
   }
}
