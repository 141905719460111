.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   .inspectionC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .headingAndBtn {
         display: flex;
         align-items: center;
         gap: 24px;
         .title {
            font-weight: 700;
         }
         .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #295bff;
            font-size: 14px;
            .img-icon {
               display: flex;
               align-items: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                     brightness(101%) contrast(102%);
               }
            }
         }
      }
      .inspectionDetailC {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
         border-radius: 12px;
         background: var(--neutral-50, #f7f8f8);
         .inspectionStatusC {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 24px;
            .asset {
               > div {
                  width: 288px;
                  display: flex;
                  padding: 8px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 4px;
               }
               &.whiteBg {
                  > div {
                     border-radius: 8px;
                     background: var(--base-white, #fff);
                  }
               }
            }
         }
         .notesC {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 24px;
            .photosC {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               .titleAndBtn {
                  width: 100%;
                  display: flex;
                  height: 32px;
                  align-items: center;
                  gap: 4px;
                  .title {
                     flex-grow: 1;
                  }
                  .addPhotosBtn {
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     gap: 8px;
                     color: #295bff;
                     font-size: 14px;
                     .img-icon {
                        display: flex;
                        align-items: center;
                        width: 16px;
                        height: 16px;
                        > img {
                           width: 100%;
                           height: 100%;
                           filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%)
                              hue-rotate(223deg) brightness(101%) contrast(102%);
                        }
                     }
                  }
               }
               .sliderPhotosC {
                  width: 100%;
                  height: 80px;
               }
               > textarea {
                  width: 100%;
                  height: 104px;
               }
            }
            .SOWC {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               .uploadFileBtn {
                  width: 100%;
                  border-radius: 8px;
                  height: 40px;
                  padding: 0px 32px;
                  color: var(--info-500, #295bff);
                  border-radius: 8px;
                  background: var(--info-100, #d6e0ff) !important;
               }

               .uploadBtn {
                  width: 100%;
                  border-radius: 8px;
                  color: #295bff;
               }
               .linkC {
                  width: 100%;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  gap: 8px;
                  .linkUploadC {
                     width: 100%;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     gap: 12px;
                     background-color: rgba(255, 0, 0, 0.184);
                     .img-icon {
                        display: flex;
                        align-items: center;
                        img {
                           filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                              brightness(92%) contrast(96%);
                        }
                     }
                     .linkIconsC {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        .img-icon {
                           display: flex;
                           align-items: center;
                           &.clear {
                              > img {
                                 filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(3368%)
                                    hue-rotate(328deg) brightness(97%) contrast(95%);
                              }
                           }
                           > img {
                              filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%)
                                 hue-rotate(156deg) brightness(92%) contrast(96%);
                           }
                        }
                     }
                     > span {
                        max-width: 310px;
                     }
                  }
               }
            }
            .noteC {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 8px;
               > textarea {
                  width: 100%;
                  height: 128px;
               }
            }
            .divider-container {
               display: flex;
               align-items: center;
               height: 100%;
               &.note {
                  .divider {
                     border: none;
                     border-left: 1px solid #c0c0c0;
                     height: 158px;
                     transform: rotate(180deg);
                     margin: 0;
                  }
               }
               .divider {
                  border: none;
                  border-left: 1px solid #c0c0c0;
                  height: 240px;
                  transform: rotate(180deg);
                  margin: 0;
               }
            }
         }
      }
   }
   .walkThroughC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .headingAndBtn {
         display: flex;
         align-items: center;
         gap: 24px;
         .title {
            font-weight: 700;
         }
         .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #295bff;
            font-size: 14px;
            .img-icon {
               display: flex;
               align-items: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                     brightness(101%) contrast(102%);
               }
            }
         }
      }
      .inspectionDetailC {
         width: 100%;
         display: flex;
         padding: 16px;
         flex-direction: column;
         align-items: flex-start;
         gap: 24px;
         border-radius: 12px;
         background: var(--neutral-50, #f7f8f8);
         .inspectionStatusC {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 24px;
            .asset {
               > div {
                  width: 288px;
                  display: flex;
                  padding: 8px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 4px;
               }
               &.whiteBg {
                  > div {
                     border-radius: 8px;
                     background: var(--base-white, #fff);
                  }
               }
            }
         }
         .notesC {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 24px;
            .photosC {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               .titleAndBtn {
                  width: 100%;
                  display: flex;
                  height: 32px;
                  align-items: center;
                  gap: 4px;
                  .title {
                     flex-grow: 1;
                  }
                  .addPhotosBtn {
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     gap: 8px;
                     color: #295bff;
                     font-size: 14px;
                     .img-icon {
                        display: flex;
                        align-items: center;
                        width: 16px;
                        height: 16px;
                        > img {
                           width: 100%;
                           height: 100%;
                           filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%)
                              hue-rotate(223deg) brightness(101%) contrast(102%);
                        }
                     }
                  }
               }
               .sliderPhotosC {
                  width: 100%;
                  height: 80px;
               }
               > textarea {
                  width: 100%;
                  height: 104px;
               }
            }
            .SOWC {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 12px;
               .uploadFileBtn {
                  width: 100%;
                  border-radius: 8px;
                  height: 40px;
                  padding: 0px 32px;
                  color: var(--info-500, #295bff);
                  border-radius: 8px;
                  background: var(--info-100, #d6e0ff) !important;
               }
               .linkC {
                  width: 100%;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  gap: 8px;
                  .linkUploadC {
                     width: 100%;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     gap: 12px;
                     background-color: rgba(255, 0, 0, 0.184);
                     .img-icon {
                        display: flex;
                        align-items: center;
                        img {
                           filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%) hue-rotate(156deg)
                              brightness(92%) contrast(96%);
                        }
                     }
                     .linkIconsC {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        .img-icon {
                           display: flex;
                           align-items: center;
                           &.clear {
                              > img {
                                 filter: brightness(0) saturate(100%) invert(41%) sepia(90%) saturate(3368%)
                                    hue-rotate(328deg) brightness(97%) contrast(95%);
                              }
                           }
                           > img {
                              filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(124%)
                                 hue-rotate(156deg) brightness(92%) contrast(96%);
                           }
                        }
                     }
                     > span {
                        max-width: 310px;
                     }
                  }
               }
            }

            .divider-container {
               display: flex;
               align-items: center;
               height: 100%;
               &.note {
                  .divider {
                     border: none;
                     border-left: 1px solid #c0c0c0;
                     height: 158px;
                     transform: rotate(180deg);
                     margin: 0;
                  }
               }
               .divider {
                  border: none;
                  border-left: 1px solid #c0c0c0;
                  height: 240px;
                  transform: rotate(180deg);
                  margin: 0;
               }
            }
         }
         .noteC {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            > textarea {
               width: 100%;
               height: 128px;
            }
         }
      }
   }
}
.divide {
   width: 100%;
}
