.container {
   position: relative;
   display: flex;
   width: 800px;
   height: 80%;
   flex-direction: column;
   align-items: center;
   border-radius: 24px;
   background: var(--base-white, #fff);
   .headingC {
      width: 100%;
      display: flex;
      padding: 24px 32px;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      .left {
         display: flex;
         align-items: center;
         gap: 12px;
         .img-icon {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(26%) sepia(6%) saturate(192%) hue-rotate(155deg)
                  brightness(92%) contrast(90%);
            }
         }
         > span {
            white-space: nowrap;
            font-size: 20px;
            font-weight: 700;
         }
      }
      .right {
         display: flex;
         align-items: center;
         gap: 12px;
         .resetBtn {
            display: flex;
            height: 40px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background: var(--negative-50, #feecee);
            color: var(--negative-500, #f2415a);
            font-weight: 700;
         }
         .closeBtn {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(26%) sepia(10%) saturate(129%) hue-rotate(156deg)
                  brightness(91%) contrast(90%);
            }
         }
      }

      .closeBtn {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         > button {
            width: 32px;
            height: 32px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(25%) sepia(5%) saturate(243%) hue-rotate(155deg)
                  brightness(92%) contrast(89%);
            }
         }
      }
   }
   .bodyC {
      width: 100%;
      display: flex;
      gap: 24px;
      padding: 2px 32px 0 32px;
      flex-direction: column;
      align-items: flex-start;
      height: 85%;
      overflow-y: auto;
      .searchTemplateC {
         width: 100%;
         display: flex;
         padding-bottom: 24px;
         align-items: center;
         gap: 24px;
         position: relative;
         > span {
            font-size: 18px;
         }
         .searchTemplate {
            display: flex;
            flex-grow: 1;
            background: var(--base-white, #fff);
         }
         .error {
            position: absolute;
            bottom: -5px;
            color: var(--negative-500, #f2415a);
            font-size: 14px;
         }
      }
      .filterSortC {
         width: 100%;
         display: flex;
         align-items: flex-start;
         gap: 32px;
         .menuC {
            min-width: 160px;
         }
      }
   }
   .footerBtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      padding: 24px;
      background-color: white;
      border-radius: 0 0 24px 24px;
      .btn {
         width: 189px;
         height: 56px;
      }
      .isOverrideButton {
         background: var(--negative-50, #feecee);
         color: var(--negative-500, #f2415a);
      }
      .btnSave {
         align-items: center;
         .btn {
            width: 189px;
            height: 56px;
         }
      }
   }
}
