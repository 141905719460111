.contentC {
   // position: absolute;
   // bottom: 50%;
   // right: 10rem;
}
.container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   border-radius: 16px;
   background: var(--base-white, #fff);
}

.notificationC {
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   .topC {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      padding-bottom: 12px;
      .iconC {
         width: 40px;
         height: 40px;
         > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
      .textC {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 4px;
         flex: 1 0 0;
      }
      .expandIconC {
         width: 24px;
         height: 24px;
         cursor: pointer;
         > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
   }
   .expanded {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .inputC {
         padding: 12px 0px;
         > label {
            width: 100%;
         }
      }
      .switchC {
         padding: 12px 0px;
      }
   }
}
.dropdownIndicator {
   width: 24px;
   height: 24px;
   filter: brightness(0) saturate(100%) invert(10%) sepia(68%) saturate(542%) hue-rotate(166deg) brightness(96%)
      contrast(87%);
}
