.appLibraryC {
   width: 100%;
   // height: 100%;
   padding: 24px 56px 0;
   .container {
      display: flex;
      padding-bottom: 56px;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      width: 100%;
      .heading_search {
         display: flex;
         align-items: center;
         gap: 48px;
         width: 100%;
         .heading {
            color: var(--primary-500, #1a2d3d);
            font-size: 24px;
            font-weight: 700;
         }
         .input_search {
            display: flex;
            width: 644px;
            height: 48px;
            padding: 12px 12px 12px 16px;
            align-items: center;
            gap: 12px;
            border-radius: 12px;
            background: var(--base-white, #fff);
            label {
               gap: 0;
               > div {
                  border: none;
                  padding: 0;
               }
            }

            img {
               width: 32px;
               height: 32px;
            }
         }
      }
      .body_context {
         width: 100%;
         display: flex;
         gap: 48px;
         .categories {
            display: flex;
            width: 224px;
            padding-top: 59px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            span {
               color: var(--Primary-Midnight-Blue, #1a2d3d);
               font-size: 18px;
               font-weight: 700;
            }
            .categories_menu {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               gap: 24px;
               > ul {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 24px;
                  > li {
                     > button {
                        display: flex;
                        padding: 0px 12px;
                        align-items: center;
                        gap: 8px;
                        > span {
                           color: var(--Primary-Midnight-Blue, #1a2d3d);
                           font-size: 18px;
                           font-weight: 400;
                        }
                        > img {
                           width: 20px;
                           height: 20px;
                           filter: invert(15%) sepia(22%) saturate(1032%) hue-rotate(166deg) brightness(97%)
                              contrast(96%);
                        }
                     }
                     .active {
                        span {
                           font-weight: 700;
                        }
                        img {
                           font-weight: bolder;
                        }
                     }
                  }
               }
            }
         }
         > div:nth-child(2) {
            flex-grow: 1;
         }
      }
   }
}
.tab {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   flex: 1 0 0;
   .cardC {
      width: 100%;
      gap: 16px;
      display: flex;
      flex-wrap: wrap;
      .noApp {
         position: absolute;
         top: 50%;
         left: 50%;
         width: 100%;
         font-size: 32px;
         color: var(--neutral-500, #8e8f8f);
      }
   }
   .ul-tab.inline,
   .li-item {
      display: inline-block;
      padding: 10px;
      cursor: pointer;
      font-size: 18px;
      position: relative;
   }
   .ul-tab.inline,
   .li-item.selected {
      color: var(--Primary-Midnight-Blue, #1a2d3d);
      font-weight: 700;
      &::after {
         position: absolute;
         bottom: 0;
         left: 10px;
         content: "";
         width: 56px;
         height: 4px;
         border-radius: 4px;
         background: var(--neutral-base, #8e8f8f);
      }
   }
}
