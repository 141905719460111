.housingAuthorityC {
   width: 100%;
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   border-radius: 12px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   background: var(--base-white, #fff);
   .sectionC {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .headingAndRefreshBtn {
         display: flex;
         align-items: center;
         gap: 12px;
      }
      .rtmValuesC {
         display: flex;
         flex-wrap: wrap;
         gap: 48px;
         .value {
            width: 250px;
            &.grayBg {
               > div {
                  background: var(--neutral-50, #f7f8f8);
               }
            }
            > div {
               width: 100%;
               padding: 8px;
            }
         }
      }
   }

   .divide {
      width: 100%;
   }

   .tableExpensesC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;

      .addExpensesC {
         width: 100%;
         .btn {
            display: flex;
            height: 32px;
            padding: 0px 16px 0px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            color: var(--info-500, #295bff);
            border: 1px solid var(--info-500, #295bff);
            .img-icon {
               width: 18px;
               height: 18px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(26%) sepia(81%) saturate(3303%) hue-rotate(224deg)
                     brightness(101%) contrast(103%);
               }
            }
         }
      }
   }
}

.title {
   font-size: 20px;
   font-weight: 700;
}

.resetBtn {
   display: flex;
   height: 32px;
   padding: 0px 16px 0px 12px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   font-size: 14px;
   color: var(--info-500, #295bff);
   .img-icon {
      width: 18px;
      height: 18px;
      img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(26%) sepia(81%) saturate(3303%) hue-rotate(224deg) brightness(101%)
            contrast(103%);
      }
   }
}
.tableContainer {
   width: 100%;
   border-radius: 12px;
   border: 1px solid var(--neutral-500, #8e8f8f);
   overflow: hidden;
}
.unitContainer {
   width: 100%;
   display: flex;
   padding: 12px;
   flex-direction: column;
   align-items: flex-start;
   gap: 12px;
   padding-top: 24px;
   border-top: 1px solid var(--neutral-300, #c0c1c1);
   .optionC {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;
      .divider-container {
         display: flex;
         align-items: center;
         height: 100%;
         .divider {
            border: none;
            border-left: 1px solid #c0c0c0;
            height: 50px;
            transform: rotate(180deg);
            margin: 0;
         }
      }
      .valuesC {
         width: 200px;
         height: 50px;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 4px;
         > div {
            width: 100%;
         }
      }
      .unitDeduction {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 4px;
      }
   }
   .utilityC {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      .nameDocC {
         display: flex;
         height: 32px;
         align-items: center;
         gap: 8px;
         flex: 1 0 0;
         > span {
            overflow: hidden;
            color: var(--info-500, #295bff);
            text-overflow: ellipsis;
         }
      }
      .btn {
         display: flex;
         height: 40px;
         padding: 0px 32px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         color: var(--info-500, #295bff);
         text-align: center;
         font-size: 16px;
         font-weight: 700;
         border: 1px solid var(--info-500, #295bff);
      }
   }
}
.divider {
   height: 100%;
   border-left: 1px solid #c0c0c0;
   width: 100%;
}
