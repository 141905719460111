.main-layout-container {
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   .bodyC {
      display: flex;
      overflow: hidden;
      height: 100%;
      .content {
         width: 100%;
         overflow: auto;
         background-color: #f1f2f3;
      }
   }
}
.version-marker {
   position: fixed;
   bottom: 0px;
   right: 6px;
   font-size: 10px;
   opacity: 0.5;
   z-index: 1000;
   color: gray;
   display: flex;
   gap: 2px;
}
