.contentC {
   display: flex;
   height: 100%;
   height: 64px;
   padding: 0 16px;
   justify-content: space-between;
   align-items: center;
   position: relative;
   background: var(--primary-500, #1a2d3d);
}
.leftC {
   display: flex;
   align-items: center;
   gap: 24px;
}
.burger {
   display: none;
   cursor: pointer;
   width: 32px;
   height: 32px;
   > img {
      width: 100%;
      height: 100%;
      filter: brightness(0) saturate(100%) invert(100%) sepia(96%) saturate(15%) hue-rotate(212deg) brightness(104%)
         contrast(104%);
   }
}
.logo {
   height: 64px;
   padding: 0 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 8px;
   > span {
      color: var(--Neutral-Snow, #fff);
      font-size: 20px;
      font-weight: 700;
   }
}
.notification {
   display: flex;
   align-items: center;
   gap: 24px;
   .bellAndNumber {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      .new-messages {
         position: absolute;
         top: 0px;
         right: 1px;
         color: var(--negative-600, #e5102e);
         font-size: 12px;
         font-weight: 700;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         transition: all 0.4s ease;
         opacity: 1;
         background-color: white;
         width: 15px;
         height: 15px;
      }

      .img-icon {
         cursor: pointer;
         width: 24px;
         height: 24px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
   }
   .img-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
      }
   }
}

.menu-item {
   position: absolute;
   top: 100%;
   right: 0;
   width: 256px;
   margin-right: 16px;
   display: flex;
   flex-direction: column;
   border-radius: 12px;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
   max-height: 0;
   opacity: 0;
   overflow: hidden;
   z-index: 2;
   transition: max-height 0.5s ease, opacity 0.5s ease;
   &.open {
      opacity: 1;
      background-color: #ffffff;
      z-index: 2;
      max-height: 224px;
   }
   > li {
      &:hover {
         background: var(--neutral-100, #e6e6e6);
      }
      width: 100%;
      cursor: pointer;
      display: flex;
      height: 48px;
      padding: 0 16px;
      align-items: center;
      gap: 12px;
      > span {
         color: var(--Primary-Midnight-Blue, var(--primary-500, #1a2d3d));
         font-size: 16px;
         font-weight: 400;
         min-width: 156px;
      }
      &:first-child {
         height: 64px;
      }
      &:last-child {
         .img-icon {
            > img {
               filter: brightness(0) saturate(100%) invert(51%) sepia(32%) saturate(4291%) hue-rotate(319deg)
                  brightness(87%) contrast(121%);
            }
         }
         > span {
            color: var(--negative-600, #e5102e);
         }
      }
      .messages {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 4px;
         .time {
            color: var(--info-500, #295bff);
            font-size: 12px;
         }
      }
      .user-infor {
         height: 100%;
         display: flex;
         gap: 8px;
         align-items: center;
         border-bottom: 1px solid var(--neutral-100, #e6e6e6);
         .img-icon-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 214px;
            overflow: hidden;
            // background-color: #404557;
            > img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
         .infor {
            font-size: 14px;
            flex: 1 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 2px;
            width: 176px;
            > span {
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               width: 100%;
               display: block;
            }
         }
      }
   }

   .img-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
      }
   }
}

.menu-item-notication {
   position: absolute;
   justify-content: flex-start;
   padding: 20px 0;
   top: 100%;
   right: 0;
   margin-right: 16px;
   display: flex;
   flex-direction: column;
   border-radius: 16px;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
   height: 0;
   opacity: 0;
   overflow: hidden;
   z-index: 0;
   transition: height 0.5s ease, opacity 0.5s ease;
   width: 600px;
   gap: 24px;
   &.open {
      width: 600px;
      height: calc(100vh - 200px);
      opacity: 1;
      background-color: white;
      z-index: 1;
      gap: 24px;
   }

   .topC {
      display: flex;
      padding: 8px 24px;
      align-items: center;
      gap: 12px;
      .heading {
         flex: 1 0 0;
         // font-size: 20px;
         font-weight: 700;
      }
      .mark {
         cursor: pointer;
         color: var(--info-500, #295bff);
         text-align: justify;
         font-size: 14px;
         display: flex;
         padding: 0px 12px;
         justify-content: center;
         align-items: center;
         gap: 8px;
      }
      .img-icon {
         width: 20px;
         height: 20px;
         > img {
            width: 100%;
            height: 100%;
         }
      }
   }
   .tabs {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0 16px;
   }
   .listItemsC {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .itemC {
         position: relative;
         padding: 12px 24px;
         cursor: pointer;
         width: 100%;
         cursor: pointer;
         display: flex;
         align-items: start;
         gap: 16px;
         border-bottom: 1px solid var(--neutral-100, #e6e6e6);
         &.unread {
            border: none;
            background: var(--info-50, #ebefff);
         }
         .starIcon {
            cursor: pointer;
            width: 30px;
            height: 30px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(44%) sepia(5%) saturate(91%) hue-rotate(169deg)
                  brightness(90%) contrast(88%);
            }
            &:hover {
               border-radius: 50%;
               background-color: var(--neutral-100, #e6e6e6);
            }
         }
         .starredIcon {
            cursor: pointer;
            width: 30px;
            height: 30px;
            > img {
               width: 100%;
               height: 100%;
            }
         }
         .messages {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex: 1 0 0;
            .messageDetail {
               display: -webkit-box;
               -webkit-line-clamp: 3;
               line-clamp: 3;
               -webkit-box-orient: vertical;
               overflow: hidden;
               text-overflow: ellipsis;
               &:hover {
                  text-decoration: underline;
               }
               .bold {
                  font-weight: 700;
               }
            }
            .time {
               color: var(--info-500, #295bff);
               font-size: 12px;
            }
         }
         .img-icon-avatar {
            cursor: pointer;
            min-width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            > img {
               width: 100%;
               height: 100%;
               object-fit: cover;
            }
         }
         .img-icon-dots {
            cursor: pointer;
            width: 24px;
            height: 24px;
            > img {
               width: 100%;
               height: 100%;
               filter: brightness(0) saturate(100%) invert(44%) sepia(5%) saturate(91%) hue-rotate(169deg)
                  brightness(90%) contrast(88%);
            }
         }
         .dropdownC {
            position: absolute;
            right: 3rem;
            top: 0;
            border: 1px solid var(--primary-500, #1a2d3d);
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            display: none;
            transition: opacity 0.3s ease, transform 0.3s ease;
            &.dropdownVisible {
               display: block;
            }
            .dropdown-menu {
               width: 100%;
               display: flex;
               flex-direction: column;
               gap: 4px;
               .dropdown-item {
                  width: 210px;
                  display: flex;
                  gap: 4px;
                  font-size: 16px;
                  align-items: center;
                  justify-content: flex-start;
                  padding: 8px;
                  background-color: white;
                  .loading {
                     width: 100%;
                     display: flex;
                     justify-content: center;
                  }
                  > span {
                     color: var(--primary-500, #1a2d3d);
                     font-weight: normal;
                  }
                  > img {
                     filter: brightness(0) saturate(100%) invert(14%) sepia(41%) saturate(641%) hue-rotate(166deg)
                        brightness(92%) contrast(93%);
                  }
                  &:hover {
                     background-color: var(--primary-500, #1a2d3d);
                     > span {
                        color: white;
                     }
                     > img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(24%) saturate(142%) hue-rotate(348deg)
                           brightness(102%) contrast(101%);
                     }
                  }
               }
            }
         }
      }
      .loadBtn {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
         padding: 8px 16px;
         cursor: pointer;
         .load-more {
            width: 150px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            padding: 8px 16px;
         }
         .load-more.hidden {
            display: none;
         }
         &.hidden {
            display: none;
         }
      }
   }

   .img-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
      }
   }
}

.avt-notification {
   display: flex;
   align-items: center;
   gap: 4px;
   cursor: pointer;
   .img-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
      }
   }
   > div {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      > img {
         height: 100%;
         width: 100%;
         object-fit: cover;
      }
   }
}
.loading {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
   > span {
      margin-left: 10px;
   }
}
.noData {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
   color: var(--neutral-700, #404557);
}
.divider {
   background: var(--neutral-300, #c0c1c1);
}
@media screen and (max-width: 800px) {
   .burger {
      display: block;
   }
}
