.chipC {
   cursor: pointer;
   display: inline-flex;
   padding: 12px 24px;
   justify-content: center;
   align-items: center;
   border-radius: 80px;
   border: 1px solid var(--neutral-300, #c0c1c1);
   background: var(--base-white, #fff);
   &:hover {
      > span {
         color: white;
      }
      background: var(--primary-400, #345a7a);
   }
   &.isSelected {
      display: inline-flex;
      padding: 12px 16px 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      > span {
         color: white;
      }
      background: var(--primary-500, #1a2d3d);
   }
   .clearIcon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(220deg) brightness(114%)
            contrast(101%);
      }
   }
}
