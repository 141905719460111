.container {
   width: 644px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   border-radius: 16px;
   background: var(--base-white, #fff);
   .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 24px;
      gap: 12px;
      > button {
         font-size: 16px;
         width: 176px;
      }
      > div {
         > button {
            font-size: 16px;
            width: 176px;
         }
      }
   }
   > hr {
      width: 100%;
      border: 1px solid var(--neutral-300, #c0c1c1);
   }
}
.notificationC {
   width: 100%;
   display: flex;
   padding: 24px;
   flex-direction: column;
   align-items: flex-start;
   .topC {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      padding-bottom: 12px;
      .iconC {
         width: 40px;
         height: 40px;
         > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
      .textC {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 4px;
         flex: 1 0 0;
      }
      .expandIconC {
         width: 24px;
         height: 24px;
         cursor: pointer;
         > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
   }
   .expanded {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .inputC {
         padding: 12px 0px;
         > label {
            width: 100%;
         }
      }
      .switchC {
         padding: 12px 0px;
      }
   }
}
