.AppC {
   height: 100%;
}

#simply-widget {
   //injected widget
   height: 100%;
   > section {
      height: 100%;
   }
}
