.contentC {
   box-sizing: border-box;
   border-radius: 12px;
   .control {
      cursor: pointer;
      border-radius: 12px;
      border: none;
      background: transparent;
      padding: 7px;
      display: flex;
      align-items: center;
      box-shadow: none;
      gap: 4px;
      * {
         padding: 0;
         margin: 0;
      }
   }

   .valueContainer {
      padding: 0;
      height: 24px;
      > div {
         color: var(--primary-500, #1a2d3d);
      }
   }
   .indicatorsContainer {
      height: 24px;
      > div {
         height: 24px;
         padding: 0;
         > svg {
            height: 24px;
            width: 24px;
            filter: brightness(0) saturate(100%) invert(13%) sepia(12%) saturate(2378%) hue-rotate(165deg)
               brightness(93%) contrast(89%);
         }
      }
   }
   .input {
      height: 24px;
      margin: 0;
      padding: 0;
   }
   .menu {
      border-radius: 12px;
      overflow: hidden;
      min-width: 300px;
   }
   .options {
      min-width: 200px;
      background-color: white;
      display: flex;
      gap: 8px;
      padding: 8px 16px;
      margin: 8px 0px;
      .labelSubC {
         width: 100%;
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
         .sub {
            font-size: 14px;
         }
      }
   }
}

.input_search {
   display: flex;
   width: 90%;
   height: 40px;
   padding: 12px 12px 12px 16px;
   margin: 8px 16px;
   align-items: center;
   gap: 12px;
   border-radius: 12px;
   border: 1px solid var(--neutral-200, #d4d5d9);
   background: var(--Primary-White, #fff);
   input {
      width: 100%;
      &::placeholder {
         color: var(--neutral-500, #8e8f8f);
         font-size: 16px;
      }
   }
   & .image_icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
         width: 32px;
         height: 32px;
         filter: brightness(0) saturate(100%);
      }
   }

   &.hidden {
      display: none;
   }
}
