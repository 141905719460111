.container {
   width: 100%;
   display: flex;
   align-items: flex-start;
   gap: 24px;
   .sourcesC {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-top: 12px;
      padding-right: 24px;
      .loading {
         width: 100%;
         height: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         gap: 8px;
      }
      > span {
         color: var(--primary-500, #1a2d3d);
         font-size: 18px;
         font-weight: 700;
      }
      .noContact {
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         color: var(--neutral-500, #8e8f8f);
      }
   }
   .additionContactC {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-left: 1px solid #aeafb0;
      padding-left: 24px;
      .titleC {
         display: flex;
         align-items: center;
         gap: 24px;
         > span {
            color: var(--primary-500, #1a2d3d);
            font-size: 18px;
            font-weight: 700;
         }
         .btn {
            width: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #295bff;
            font-size: 14px;
            .img-icon {
               display: flex;
               align-items: center;
               width: 16px;
               height: 16px;
               > img {
                  width: 100%;
                  height: 100%;
                  filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(2287%) hue-rotate(223deg)
                     brightness(101%) contrast(102%);
               }
            }
         }
      }

      .loading {
         width: 100%;
         height: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         gap: 8px;
      }
      .noContact {
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         color: var(--neutral-500, #8e8f8f);
      }
   }
}
.sourceC {
   width: 100%;
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 12px;
   background: var(--base-white, #fff);
   border-radius: 8px;
   padding: 0 10px;
   > div {
      width: 200px;
      display: flex;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      > div {
         > div {
            width: 100%;
         }
      }
   }
}
.propertiesC {
   width: 100%;
   display: flex;
   align-items: center;
   gap: 12px;
   background: var(--base-white, #fff);
   border-radius: 8px;
   padding: 0 10px;
   .dataC {
      display: flex;
      gap: 12px;
      flex-grow: 1;
      flex-wrap: wrap;
      > div {
         width: 200px;
         display: flex;
         padding: 8px;
         flex-direction: column;
         justify-content: center;
         align-items: flex-start;
         gap: 8px;
         > div {
            > div {
               width: 100%;
            }
         }
      }
   }
   .img-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      > img {
         width: 100%;
         height: 100%;
         filter: brightness(0) saturate(100%) invert(27%) sepia(73%) saturate(2973%) hue-rotate(337deg) brightness(125%)
            contrast(90%);
      }
   }
}
