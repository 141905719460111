.loading {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
}

.viewC {
   display: flex;
   padding: 24px;
   align-items: flex-start;
   align-content: flex-start;
   gap: 24px;
   flex-wrap: wrap;
   justify-content: center;
}
.footer {
   width: 100%;
   bottom: 16px;
   display: flex;
   justify-content: center;
   align-items: center;

   .load-more {
      cursor: pointer;
   }

   .load-more.hidden {
      display: none;
   }

   &.hidden {
      display: none;
   }
}
