.rightBodyC {
   display: flex;
   width: 100%;
   height: calc(100% - 50px);
   background: #e6e6e6;
   position: relative;
   .noLeadC {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--neutral-500, #8c8f9a);
      font-size: 24px;
   }
}

.rightBodyLeftC {
   width: 100%;
   display: flex;
   flex-grow: 1;
   background: #e6e6e6;
   padding: 24px 48px;
   flex-direction: column;
   align-items: flex-start;
   gap: 24px;
   overflow-y: auto;
}

.rightBodyRightC {
   position: absolute;
   height: 100%;
   right: 0;
   top: 0;
   transition: all 0.3s ease;
   background: var(--base-white, #fff);
   border-left: 1px solid #aeafb0;
   padding-top: 12px;
   &.hidden {
      transform: translateX(100%);
   }
}
